// prettier-ignore
const DESTINATIONS = [
    { country_code: "DK", city_code: "AAL", en: "Aalborg", nl: "Aalborg", fr: "Aalborg" },
    { country_code: "US", city_code: "ABE", en: "Allentown", nl: "Allentown", fr: "Allentown" },
    { country_code: "CI", city_code: "ABJ", en: "Abidjan", nl: "Abidjan", fr: "Abidjan" },
    { country_code: "US", city_code: "ABQ", en: "Albuquerque", nl: "Albuquerque", fr: "Albuquerque" },
    { country_code: "NG", city_code: "ABV", en: "Abuja", nl: "Abuja", fr: "Abuja" },
    { country_code: "US", city_code: "ABY", en: "Albany, GA", nl: "Albany, GA", fr: "Albany, GA" },
    { country_code: "GB", city_code: "ABZ", en: "Aberdeen", nl: "Aberdeen", fr: "Aberdeen" },
    { country_code: "MX", city_code: "ACA", en: "Acapulco", nl: "Acapulco", fr: "Acapulco" },
    { country_code: "GH", city_code: "ACC", en: "Accra", nl: "Accra", fr: "Accra" },
    { country_code: "ES", city_code: "ACE", en: "Lanzarote", nl: "Lanzarote", fr: "Lanzarote" },
    { country_code: "ET", city_code: "ADD", en: "Addis Ababa", nl: "Addis Ababa", fr: "Addis-Abeba" },
    { country_code: "AU", city_code: "ADL", en: "Adelaide", nl: "Adelaide", fr: "Adélaïde" },
    { country_code: "CO", city_code: "ADZ", en: "San Andres Island", nl: "San Andres Island", fr: "San Andres Island" },
    { country_code: "RU", city_code: "AER", en: "Sochi Intl.", nl: "Sochi Intl.", fr: "Sotchi Int." },
    { country_code: "NO", city_code: "AES", en: "Alesund", nl: "Alesund", fr: "Alesund" },
    { country_code: "US", city_code: "AEX", en: "Alexandria", nl: "Alexandria", fr: "Alexandrie" },
    { country_code: "MA", city_code: "AGA", en: "Agadir", nl: "Agadir", fr: "Agadir" },
    { country_code: "FR", city_code: "AGF", en: "Agen", nl: "Agen", fr: "Agen" },
    { country_code: "ES", city_code: "AGP", en: "Malaga", nl: "Malaga", fr: "Malaga" },
    { country_code: "US", city_code: "AGS", en: "Augusta", nl: "Augusta", fr: "Augusta" },
    { country_code: "IT", city_code: "AHO", en: "Alghero", nl: "Alghero", fr: "Alghero" },
    { country_code: "FR", city_code: "AJA", en: "Ajaccio", nl: "Ajaccio", fr: "Ajaccio" },
    { country_code: "SE", city_code: "AJR", en: "Arvidsjaur", nl: "Arvidsjaur", fr: "Arvidsjaur" },
    { country_code: "BR", city_code: "AJU", en: "Aracaju", nl: "Aracaju", fr: "Aracaju" },
    { country_code: "JP", city_code: "AKJ", en: "Asahikawa", nl: "Asahikawa", fr: "Asahikawa" },
    { country_code: "NZ", city_code: "AKL", en: "Auckland", nl: "Auckland", fr: "Auckland" },
    { country_code: "KZ", city_code: "ALA", en: "Almaty", nl: "Alma-Ata", fr: "Almaty" },
    { country_code: "US", city_code: "ALB", en: "Albany, NY", nl: "Albany, NY", fr: "Albany, NY" },
    { country_code: "ES", city_code: "ALC", en: "Alicante", nl: "Alicante", fr: "Alicante" },
    { country_code: "DZ", city_code: "ALG", en: "Algiers", nl: "Algiers", fr: "Alger" },
    { country_code: "US", city_code: "ALW", en: "Walla Walla", nl: "Walla Walla", fr: "Walla Walla" },
    { country_code: "US", city_code: "AMA", en: "Amarillo", nl: "Amarillo", fr: "Amarillo" },
    { country_code: "IN", city_code: "AMD", en: "Ahmedabad", nl: "Ahmedabad", fr: "Ahmedabad" },
    { country_code: "JO", city_code: "AMM", en: "Amman", nl: "Amman", fr: "Amman" },
    { country_code: "NL", city_code: "AMS", en: "Amsterdam", nl: "Amsterdam", fr: "Amsterdam" },
    { country_code: "US", city_code: "ANC", en: "Anchorage", nl: "Anchorage", fr: "Anchorage" },
    { country_code: "FR", city_code: "ANE", en: "Angers", nl: "Angers", fr: "Angers" },
    { country_code: "CL", city_code: "ANF", en: "Antofagasta", nl: "Antofagasta", fr: "Antofagasta" },
    { country_code: "TR", city_code: "ANK", en: "Ankara", nl: "Ankara", fr: "Ankara" },
    { country_code: "BE", city_code: "ANR", en: "Antwerp", nl: "Antwerpen", fr: "Anvers" },
    { country_code: "IT", city_code: "AOI", en: "Ancona", nl: "Ancona", fr: "Ancône" },
    { country_code: "JP", city_code: "AOJ", en: "Aomori", nl: "Aomori", fr: "Aomori" },
    { country_code: "MY", city_code: "AOR", en: "Alor Setar", nl: "Alor Setar", fr: "Alor Setar" },
    { country_code: "MZ", city_code: "APL", en: "Nampula", nl: "Nampula", fr: "Nampula" },
    { country_code: "PE", city_code: "AQP", en: "Arequipa", nl: "Arequipa", fr: "Arequipa" },
    { country_code: "RU", city_code: "ARH", en: "Arkhangelsk", nl: "Arkhangelsk", fr: "Arkhangelsk" },
    { country_code: "CL", city_code: "ARI", en: "Arica", nl: "Arica", fr: "Arica" },
    { country_code: "TR", city_code: "ASR", en: "Kayseri", nl: "Kayseri", fr: "Kayseri" },
    { country_code: "PY", city_code: "ASU", en: "Asunción", nl: "Asunción", fr: "Asunción" },
    { country_code: "GR", city_code: "ATH", en: "Athens", nl: "Athene", fr: "Athènes" },
    { country_code: "US", city_code: "ATL", en: "Atlanta", nl: "Atlanta", fr: "Atlanta" },
    { country_code: "IN", city_code: "ATQ", en: "Amritsar", nl: "Amritsar", fr: "Amritsar" },
    { country_code: "US", city_code: "ATW", en: "Appleton", nl: "Appleton", fr: "Appleton" },
    { country_code: "AW", city_code: "AUA", en: "Aruba", nl: "Aruba", fr: "Aruba" },
    { country_code: "AE", city_code: "AUH", en: "Abu Dhabi", nl: "Abu Dhabi", fr: "Abu Dhabi" },
    { country_code: "FR", city_code: "AUR", en: "Aurillac", nl: "Aurillac", fr: "Aurillac" },
    { country_code: "US", city_code: "AUS", en: "Austin", nl: "Austin", fr: "Austin" },
    { country_code: "US", city_code: "AUW", en: "Wausau", nl: "Wausau", fr: "Wausau" },
    { country_code: "US", city_code: "AVL", en: "Asheville", nl: "Asheville", fr: "Asheville" },
    { country_code: "FR", city_code: "AVN", en: "Avignon", nl: "Avignon", fr: "Avignon" },
    { country_code: "US", city_code: "AVP", en: "Scranton", nl: "Scranton", fr: "Scranton" },
    { country_code: "GR", city_code: "AXD", en: "Alexandroupolis", nl: "Alexandroupolis", fr: "Alexandroupolis" },
    { country_code: "CO", city_code: "AXM", en: "Armenia", nl: "Armenia", fr: "Armenia" },
    { country_code: "JP", city_code: "AXT", en: "Akita", nl: "Akita", fr: "Akita" },
    { country_code: "TR", city_code: "AYT", en: "Antalya", nl: "Antalya", fr: "Antalya" },
    { country_code: "US", city_code: "AZO", en: "Kalamazoo", nl: "Kalamazoo", fr: "Kalamazoo" },
    { country_code: "BH", city_code: "BAH", en: "Bahrain", nl: "Bahrein", fr: "Bahreïn" },
    { country_code: "AZ", city_code: "BAK", en: "Baku", nl: "Baku", fr: "Bakou" },
    { country_code: "TR", city_code: "BAL", en: "Batman", nl: "Batman", fr: "Batman" },
    { country_code: "CO", city_code: "BAQ", en: "Barranquilla", nl: "Barranquilla", fr: "Barranquilla" },
    { country_code: "RU", city_code: "BAX", en: "Barnaul", nl: "Barnaul", fr: "Barnaul" },
    { country_code: "IN", city_code: "BBI", en: "Bhubaneswar", nl: "Bhubaneswar", fr: "Bhubaneswar" },
    { country_code: "ES", city_code: "BCN", en: "Barcelona", nl: "Barcelona", fr: "Barcelone" },
    { country_code: "BM", city_code: "BDA", en: "Bermuda", nl: "Bermuda", fr: "Bermuda" },
    { country_code: "ID", city_code: "BDJ", en: "Banjarmasin", nl: "Banjarmasin", fr: "Banjarmasin" },
    { country_code: "IN", city_code: "BDQ", en: "Vadodara", nl: "Vadodara", fr: "Vadodara" },
    { country_code: "IT", city_code: "BDS", en: "Brindisi", nl: "Brindisi", fr: "Brindisi" },
    { country_code: "RS", city_code: "BEG", en: "Belgrade", nl: "Belgrado", fr: "Belgrade" },
    { country_code: "BR", city_code: "BEL", en: "Belem", nl: "Belem", fr: "Belem" },
    { country_code: "DE", city_code: "BER", en: "Berlin", nl: "Berlijn", fr: "Berlin" },
    { country_code: "FR", city_code: "BES", en: "Brest", nl: "Brest", fr: "Brest" },
    { country_code: "LB", city_code: "BEY", en: "Beirut", nl: "Beiroet", fr: "Beyrouth" },
    { country_code: "ZA", city_code: "BFN", en: "Bloemfontein", nl: "Bloemfontein", fr: "Bloemfontein" },
    { country_code: "GB", city_code: "BFS", en: "Belfast", nl: "Belfast", fr: "Belfast" },
    { country_code: "CO", city_code: "BGA", en: "Bucaramanga", nl: "Bucaramanga", fr: "Bucaramanga" },
    { country_code: "CF", city_code: "BGF", en: "Bangui", nl: "Bangui", fr: "Bangui" },
    { country_code: "US", city_code: "BGM", en: "Binghamton", nl: "Binghamton", fr: "Binghamton" },
    { country_code: "NO", city_code: "BGO", en: "Bergen", nl: "Bergen", fr: "Bergen" },
    { country_code: "US", city_code: "BGR", en: "Bangor", nl: "Bangor", fr: "Bangor" },
    { country_code: "NZ", city_code: "BHE", en: "Blenheim", nl: "Blenheim", fr: "Blenheim" },
    { country_code: "US", city_code: "BHM", en: "Birmingham", nl: "Birmingham, AL", fr: "Birmingham, AL" },
    { country_code: "IN", city_code: "BHO", en: "Bhopal", nl: "Bhopal", fr: "Bhopal" },
    { country_code: "GB", city_code: "BHX", en: "Birmingham", nl: "Birmingham", fr: "Birmingham" },
    { country_code: "BR", city_code: "BHZ", en: "Belo Horizonte", nl: "Belo Horizonte", fr: "Belo Horizonte" },
    { country_code: "FR", city_code: "BIA", en: "Bastia", nl: "Bastia", fr: "Bastia" },
    { country_code: "US", city_code: "BIL", en: "Billings", nl: "Billings", fr: "Billings" },
    { country_code: "ES", city_code: "BIO", en: "Bilbao", nl: "Bilbao", fr: "Bilbao" },
    { country_code: "FR", city_code: "BIQ", en: "Biarritz", nl: "Biarritz", fr: "Biarritz" },
    { country_code: "US", city_code: "BIS", en: "Bismarck", nl: "Bismarck", fr: "Bismarck" },
    { country_code: "US", city_code: "BJI", en: "Bemidji", nl: "Bemidji", fr: "Bemidji" },
    { country_code: "BI", city_code: "BJM", en: "Bujumbura", nl: "Bujumbura", fr: "Bujumbura" },
    { country_code: "CN", city_code: "BJS", en: "Beijing", nl: "Beijing", fr: "Pékin" },
    { country_code: "MX", city_code: "BJX", en: "Leon-Guanajuato", nl: "Leon-Guanajuato", fr: "León-Guanajuato" },
    { country_code: "MY", city_code: "BKI", en: "Kota Kinabalu", nl: "Kota Kinabalu", fr: "Kota Kinabalu" },
    { country_code: "TH", city_code: "BKK", en: "Bangkok", nl: "Bangkok", fr: "Bangkok" },
    { country_code: "ML", city_code: "BKO", en: "Bamako", nl: "Bamako", fr: "Bamako" },
    { country_code: "US", city_code: "BLI", en: "Bellingham", nl: "Bellingham", fr: "Bellingham" },
    { country_code: "DK", city_code: "BLL", en: "Billund", nl: "Billund", fr: "Billund" },
    { country_code: "IT", city_code: "BLQ", en: "Bologna", nl: "Bologna", fr: "Bologne" },
    { country_code: "IN", city_code: "BLR", en: "Bangalore", nl: "Bangalore", fr: "Bangalore" },
    { country_code: "MW", city_code: "BLZ", en: "Blantyre", nl: "Blantyre", fr: "Blantyre" },
    { country_code: "US", city_code: "BMI", en: "Bloomington", nl: "Bloomington", fr: "Bloomington" },
    { country_code: "US", city_code: "BNA", en: "Nashville", nl: "Nashville", fr: "Nashville" },
    { country_code: "AU", city_code: "BNE", en: "Brisbane", nl: "Brisbane", fr: "Brisbane" },
    { country_code: "NO", city_code: "BNN", en: "Bronnoysund", nl: "Bronnoysund", fr: "Bronnoysund" },
    { country_code: "FR", city_code: "BOD", en: "Bordeaux", nl: "Bordeaux", fr: "Bordeaux" },
    { country_code: "CO", city_code: "BOG", en: "Bogota", nl: "Bogota", fr: "Bogota" },
    { country_code: "US", city_code: "BOI", en: "Boise", nl: "Boise", fr: "Boise" },
    { country_code: "IN", city_code: "BOM", en: "Mumbai/Bombay", nl: "Mumbai/Bombay", fr: "Mumbai" },
    { country_code: "BQ", city_code: "BON", en: "Bonaire", nl: "Bonaire", fr: "Bonaire" },
    { country_code: "NO", city_code: "BOO", en: "Bodo", nl: "Bodo", fr: "Bodø" },
    { country_code: "US", city_code: "BOS", en: "Boston", nl: "Boston", fr: "Boston" },
    { country_code: "ID", city_code: "BPN", en: "Balikpapan", nl: "Balikpapan", fr: "Balikpapan" },
    { country_code: "BR", city_code: "BPS", en: "Porto Seguro", nl: "Porto Seguro", fr: "Porto Seguro" },
    { country_code: "AR", city_code: "BRC", en: "Bariloche", nl: "Bariloche", fr: "Bariloche" },
    { country_code: "US", city_code: "BRD", en: "Brainerd", nl: "Brainerd", fr: "Brainerd" },
    { country_code: "DE", city_code: "BRE", en: "Bremen", nl: "Bremen", fr: "Brême" },
    { country_code: "IT", city_code: "BRI", en: "Bari", nl: "Bari", fr: "Bari" },
    { country_code: "CH", city_code: "BRN", en: "Bern", nl: "Bern", fr: "Berne" },
    { country_code: "GB", city_code: "BRS", en: "Bristol", nl: "Bristol", fr: "Bristol" },
    { country_code: "BE", city_code: "BRU", en: "Brussels", nl: "Brussel", fr: "Bruxelles" },
    { country_code: "BR", city_code: "BSB", en: "Brasilia", nl: "Brasilia", fr: "Brasilia" },
    { country_code: "GQ", city_code: "BSG", en: "Bata", nl: "Bata", fr: "Bata" },
    { country_code: "ID", city_code: "BTH", en: "Batam (Batu Besar)", nl: "Batam (Batu Besar)", fr: "Batam (Batu Besar)" },
    { country_code: "US", city_code: "BTR", en: "Baton Rouge", nl: "Baton Rouge", fr: "Baton Rouge" },
    { country_code: "MY", city_code: "BTU", en: "Bintulu", nl: "Bintulu", fr: "Bintulu" },
    { country_code: "US", city_code: "BTV", en: "Burlington", nl: "Burlington", fr: "Burlington" },
    { country_code: "HU", city_code: "BUD", en: "Budapest", nl: "Boedapest", fr: "Budapest" },
    { country_code: "AR", city_code: "BUE", en: "Buenos Aires", nl: "Buenos Aires", fr: "Buenos Aires" },
    { country_code: "US", city_code: "BUF", en: "Buffalo", nl: "Buffalo", fr: "Buffalo" },
    { country_code: "RO", city_code: "BUH", en: "Bucharest", nl: "Boekarest", fr: "Bucarest" },
    { country_code: "ZW", city_code: "BUQ", en: "Bulawayo", nl: "Bulawayo", fr: "Bulawayo" },
    { country_code: "US", city_code: "BUR", en: "Burbank", nl: "Burbank", fr: "Burbank" },
    { country_code: "BR", city_code: "BVB", en: "Boa Vista", nl: "Boa Vista", fr: "Boa Vista" },
    { country_code: "FR", city_code: "BVE", en: "Brive la Gaillarde", nl: "Brive la Gaillarde", fr: "Brive la Gaillarde" },
    { country_code: "BN", city_code: "BWN", en: "Bandar Seri Begawan", nl: "Bandar Seri Begawan", fr: "Bandar Seri Begawan" },
    { country_code: "TR", city_code: "BXN", en: "Bodrum", nl: "Bodrum", fr: "Bodrum" },
    { country_code: "BZ", city_code: "BZE", en: "Belize City", nl: "Belize City", fr: "Belize City" },
    { country_code: "US", city_code: "BZN", en: "Bozeman", nl: "Bozeman", fr: "Bozeman" },
    { country_code: "CG", city_code: "BZV", en: "Brazzaville", nl: "Brazzaville", fr: "Brazzaville" },
    { country_code: "US", city_code: "CAE", en: "Columbia, SC", nl: "Columbia, SC", fr: "Columbia, SC" },
    { country_code: "IT", city_code: "CAG", en: "Cagliari", nl: "Cagliari", fr: "Cagliari" },
    { country_code: "EG", city_code: "CAI", en: "Cairo", nl: "Caïro", fr: "Le Caire" },
    { country_code: "US", city_code: "CAK", en: "Akron", nl: "Akron", fr: "Akron" },
    { country_code: "CN", city_code: "CAN", en: "Guangzhou", nl: "Guangzhou", fr: "Canton" },
    { country_code: "MA", city_code: "CAS", en: "Casablanca", nl: "Casablanca", fr: "Casablanca" },
    { country_code: "GF", city_code: "CAY", en: "Cayenne", nl: "Cayenne", fr: "Cayenne" },
    { country_code: "IN", city_code: "CCJ", en: "Kozhikode", nl: "Kozhikode", fr: "Kozhikode" },
    { country_code: "VE", city_code: "CCS", en: "Caracas", nl: "Caracas", fr: "Caracas" },
    { country_code: "IN", city_code: "CCU", en: "Kolkata", nl: "Kolkata", fr: "Kolkata/Calcutta" },
    { country_code: "PH", city_code: "CEB", en: "Cebu", nl: "Cebu", fr: "Cebu" },
    { country_code: "TH", city_code: "CEI", en: "Chiang Rai", nl: "Chiang Rai", fr: "Chiang Rai" },
    { country_code: "RU", city_code: "CEK", en: "Chelyabinsk", nl: "Chelyabinsk", fr: "Chelyabinsk" },
    { country_code: "MX", city_code: "CEN", en: "Ciudad Obregon", nl: "Ciudad Obregon", fr: "Ciudad Obregon" },
    { country_code: "FR", city_code: "CFE", en: "Clermont-Ferrand", nl: "Clermont-Ferrand", fr: "Clermont-Ferrand" },
    { country_code: "FR", city_code: "CFR", en: "Caen", nl: "Caen", fr: "Caen" },
    { country_code: "GR", city_code: "CFU", en: "Kerkyra Corfu", nl: "Kerkyra Corfu", fr: "Kerkyra Corfu" },
    { country_code: "BR", city_code: "CGB", en: "Cuiaba", nl: "Cuiaba", fr: "Cuiabá" },
    { country_code: "CN", city_code: "CGO", en: "Zhengzhou", nl: "Zhengzhou", fr: "Zhengzhou" },
    { country_code: "CN", city_code: "CGQ", en: "Changchun", nl: "Changchun", fr: "Changchun" },
    { country_code: "BR", city_code: "CGR", en: "Campo Grande", nl: "Campo Grande", fr: "Campo Grande" },
    { country_code: "US", city_code: "CHA", en: "Chattanooga", nl: "Chattanooga", fr: "Chattanooga" },
    { country_code: "NZ", city_code: "CHC", en: "Christchurch", nl: "Christchurch", fr: "Christchurch" },
    { country_code: "US", city_code: "CHI", en: "Chicago", nl: "Chicago", fr: "Chicago" },
    { country_code: "US", city_code: "CHO", en: "Charlottesville", nl: "Charlottesville", fr: "Charlottesville" },
    { country_code: "GR", city_code: "CHQ", en: "Chania", nl: "Chania", fr: "Chania" },
    { country_code: "US", city_code: "CHS", en: "Charleston, SC", nl: "Charleston, SC", fr: "Charleston, SC" },
    { country_code: "US", city_code: "CID", en: "Cedar Rapids", nl: "Cedar Rapids", fr: "Cedar Rapids" },
    { country_code: "KZ", city_code: "CIT", en: "Shimkent", nl: "Shimkent", fr: "Shimkent" },
    { country_code: "IN", city_code: "CJB", en: "Coimbatore", nl: "Coimbatore", fr: "Coimbatore" },
    { country_code: "CL", city_code: "CJC", en: "Calama", nl: "Calama", fr: "Calama" },
    { country_code: "MX", city_code: "CJS", en: "Ciudad Juarez", nl: "Ciudad Juarez", fr: "Ciudad de Juárez" },
    { country_code: "KR", city_code: "CJU", en: "Jeju", nl: "Jeju", fr: "Jeju" },
    { country_code: "CN", city_code: "CKG", en: "Chongqing", nl: "Chongqing", fr: "Chongqing" },
    { country_code: "GN", city_code: "CKY", en: "Conakry", nl: "Conakry", fr: "Conakry" },
    { country_code: "US", city_code: "CLE", en: "Cleveland", nl: "Cleveland", fr: "Cleveland" },
    { country_code: "RO", city_code: "CLJ", en: "Cluj", nl: "Cluj", fr: "Cluj" },
    { country_code: "CO", city_code: "CLO", en: "Cali", nl: "Cali", fr: "Cali" },
    { country_code: "US", city_code: "CLT", en: "Charlotte", nl: "Charlotte", fr: "Charlotte" },
    { country_code: "FR", city_code: "CLY", en: "Calvi", nl: "Calvi", fr: "Calvi" },
    { country_code: "LK", city_code: "CMB", en: "Colombo", nl: "Colombo", fr: "Colombo" },
    { country_code: "MX", city_code: "CME", en: "Ciudad Del Carmen", nl: "Ciudad Del Carmen", fr: "Ciudad Del Carmen" },
    { country_code: "US", city_code: "CMH", en: "Columbus, OH", nl: "Columbus, OH", fr: "Columbus, OH" },
    { country_code: "US", city_code: "CMI", en: "Champaign", nl: "Champaign", fr: "Champaign" },
    { country_code: "AU", city_code: "CNS", en: "Cairns", nl: "Cairns", fr: "Cairns" },
    { country_code: "TH", city_code: "CNX", en: "Chiang Mai", nl: "Chiang Mai", fr: "Chiang Mai" },
    { country_code: "IN", city_code: "COK", en: "Kochi", nl: "Kochi", fr: "Kochi" },
    { country_code: "BJ", city_code: "COO", en: "Cotonou", nl: "Cotonou", fr: "Cotonou" },
    { country_code: "AR", city_code: "COR", en: "Cordoba", nl: "Cordoba", fr: "Cordoba" },
    { country_code: "US", city_code: "COS", en: "Colorado Springs", nl: "Colorado Springs", fr: "Colorado Springs" },
    { country_code: "DK", city_code: "CPH", en: "Copenhagen", nl: "Kopenhagen", fr: "Copenhague" },
    { country_code: "CL", city_code: "CPO", en: "Copiapo", nl: "Copiapo", fr: "Copiapo" },
    { country_code: "BR", city_code: "CPQ", en: "Campinas", nl: "Campinas", fr: "Campinas" },
    { country_code: "ZA", city_code: "CPT", en: "Cape Town", nl: "Kaapstad", fr: "Le Cap" },
    { country_code: "US", city_code: "CRW", en: "Charleston, WV", nl: "Charleston, WV", fr: "Charleston, WV" },
    { country_code: "US", city_code: "CSG", en: "Columbus, GA", nl: "Columbus, GA", fr: "Columbus, GA" },
    { country_code: "CN", city_code: "CSX", en: "Changsha", nl: "Changsha", fr: "Changsha" },
    { country_code: "IT", city_code: "CTA", en: "Catania", nl: "Catania", fr: "Catane" },
    { country_code: "CO", city_code: "CTG", en: "Cartagena", nl: "Cartagena", fr: "Carthagène" },
    { country_code: "CN", city_code: "CTU", en: "Chengdu", nl: "Chengdu", fr: "Chengdu" },
    { country_code: "CO", city_code: "CUC", en: "Cucuta", nl: "Cucuta", fr: "Cucuta" },
    { country_code: "MX", city_code: "CUL", en: "Culiacan", nl: "Culiacan", fr: "Culiacan" },
    { country_code: "MX", city_code: "CUN", en: "Cancun", nl: "Cancun", fr: "Cancún" },
    { country_code: "CW", city_code: "CUR", en: "Curacao", nl: "Curaçao", fr: "Curaçao" },
    { country_code: "MX", city_code: "CUU", en: "Chihuahua", nl: "Chihuahua", fr: "Chihuahua" },
    { country_code: "PE", city_code: "CUZ", en: "Cuzco", nl: "Cuzco", fr: "Cuzco" },
    { country_code: "US", city_code: "CVG", en: "Cincinnati", nl: "Cincinnati", fr: "Cincinnati" },
    { country_code: "BR", city_code: "CWB", en: "Curitiba", nl: "Curitiba", fr: "Curitiba" },
    { country_code: "GB", city_code: "CWL", en: "Cardiff", nl: "Cardiff", fr: "Cardiff" },
    { country_code: "MX", city_code: "CZM", en: "Cozumel", nl: "Cozumel", fr: "Cozumel" },
    { country_code: "CN", city_code: "CZX", en: "Changzhou", nl: "Changzhou", fr: "Changzhou" },
    { country_code: "US", city_code: "DAB", en: "Daytona Beach", nl: "Daytona Beach", fr: "Daytona Beach" },
    { country_code: "BD", city_code: "DAC", en: "Dhaka", nl: "Dhaka", fr: "Dhaka" },
    { country_code: "VN", city_code: "DAD", en: "Da Nang", nl: "Da Nang", fr: "Da Nang" },
    { country_code: "TZ", city_code: "DAR", en: "Dar es Salaam", nl: "Dar es Salaam", fr: "Dar es Salam" },
    { country_code: "PA", city_code: "DAV", en: "David", nl: "David", fr: "David" },
    { country_code: "US", city_code: "DAY", en: "Dayton", nl: "Dayton", fr: "Dayton" },
    { country_code: "HR", city_code: "DBV", en: "Dubrovnik", nl: "Dubrovnik", fr: "Dubrovnik" },
    { country_code: "FR", city_code: "DCM", en: "Castres", nl: "Castres", fr: "Castres" },
    { country_code: "IN", city_code: "DEL", en: "Delhi", nl: "Delhi", fr: "Delhi" },
    { country_code: "US", city_code: "DEN", en: "Denver", nl: "Denver", fr: "Denver" },
    { country_code: "US", city_code: "DFW", en: "Dallas", nl: "Dallas", fr: "Dallas" },
    { country_code: "MX", city_code: "DGO", en: "Durango", nl: "Durango", fr: "Durango" },
    { country_code: "US", city_code: "DHN", en: "Dothan", nl: "Dothan", fr: "Dothan" },
    { country_code: "US", city_code: "DIK", en: "Dickinson", nl: "Dickinson", fr: "Dickinson" },
    { country_code: "TR", city_code: "DIY", en: "Diyarbakir", nl: "Diyarbakir", fr: "Diyarbakir" },
    { country_code: "SN", city_code: "DKR", en: "Dakar", nl: "Dakar", fr: "Dakar" },
    { country_code: "CM", city_code: "DLA", en: "Douala", nl: "Douala", fr: "Douala" },
    { country_code: "CN", city_code: "DLC", en: "Dalian", nl: "Dalian", fr: "Dalian" },
    { country_code: "US", city_code: "DLH", en: "Duluth", nl: "Duluth", fr: "Duluth" },
    { country_code: "TR", city_code: "DLM", en: "Dalaman", nl: "Dalaman", fr: "Dalaman" },
    { country_code: "SA", city_code: "DMM", en: "Dammam / Dhahran", nl: "Dammam / Dhahran", fr: "Dammam/Dhahran" },
    { country_code: "UA", city_code: "DNK", en: "Dnipropetrovsk", nl: "Dnipropetrovsk", fr: "Dnipropetrovsk" },
    { country_code: "TR", city_code: "DNZ", en: "Denizli", nl: "Denizli", fr: "Denizli" },
    { country_code: "QA", city_code: "DOH", en: "Doha", nl: "Doha", fr: "Doha" },
    { country_code: "DM", city_code: "DOM", en: "Dominica", nl: "Dominica", fr: "Dominica" },
    { country_code: "ID", city_code: "DPS", en: "Bali", nl: "Bali", fr: "Denpasar-Bali" },
    { country_code: "DE", city_code: "DRS", en: "Dresden", nl: "Dresden", fr: "Dresden" },
    { country_code: "AU", city_code: "DRW", en: "Darwin", nl: "Darwin", fr: "Darwin" },
    { country_code: "US", city_code: "DSM", en: "Des Moines", nl: "Des Moines", fr: "Des Moines" },
    { country_code: "US", city_code: "DTT", en: "Detroit", nl: "Detroit", fr: "Detroit" },
    { country_code: "IE", city_code: "DUB", en: "Dublin", nl: "Dublin", fr: "Dublin" },
    { country_code: "NZ", city_code: "DUD", en: "Dunedin", nl: "Dunedin", fr: "Dunedin" },
    { country_code: "ZA", city_code: "DUR", en: "Durban", nl: "Durban", fr: "Durban" },
    { country_code: "DE", city_code: "DUS", en: "Dusseldorf", nl: "Dusseldorf", fr: "Düsseldorf" },
    { country_code: "AE", city_code: "DXB", en: "Dubai", nl: "Dubai", fr: "Dubaï" },
    { country_code: "CN", city_code: "DYG", en: "Zhangjiajie", nl: "Zhangjiajie", fr: "Zhangjiajie" },
    { country_code: "YT", city_code: "DZA", en: "Dzaoudzi", nl: "Dzaoudzi", fr: "Dzaoudzi" },
    { country_code: "FR", city_code: "EAP", en: "Mulhouse Basel", nl: "Mulhouse Basel", fr: "Mulhouse Basel" },
    { country_code: "US", city_code: "EAT", en: "Wenatchee", nl: "Wenatchee", fr: "Wenatchee" },
    { country_code: "UG", city_code: "EBB", en: "Entebbe", nl: "Entebbe", fr: "Entebbe" },
    { country_code: "US", city_code: "ECP", en: "Panama City Florida", nl: "Panama City Florida", fr: "Panama City Florida" },
    { country_code: "GB", city_code: "EDI", en: "Edinburgh", nl: "Edinburgh", fr: "Edimbourg" },
    { country_code: "KE", city_code: "EDL", en: "Eldoret", nl: "Eldoret", fr: "Eldoret" },
    { country_code: "NL", city_code: "EIN", en: "Eindhoven", nl: "Eindhoven", fr: "Eindhoven" },
    { country_code: "CO", city_code: "EJA", en: "Barrancabermeja", nl: "Barrancabermeja", fr: "Barrancabermeja" },
    { country_code: "US", city_code: "ELM", en: "Elmira", nl: "Elmira", fr: "Elmira" },
    { country_code: "US", city_code: "ELP", en: "El Paso", nl: "El Paso", fr: "El Paso" },
    { country_code: "ZA", city_code: "ELS", en: "East London", nl: "Oost-Londen", fr: "Londres Est" },
    { country_code: "TR", city_code: "ERC", en: "Erzincan", nl: "Erzincan", fr: "Erzincan" },
    { country_code: "US", city_code: "ERI", en: "Erie", nl: "Erie", fr: "Érié" },
    { country_code: "TR", city_code: "ERZ", en: "Erzurum", nl: "Erzurum", fr: "Erzurum" },
    { country_code: "US", city_code: "ESC", en: "Escanaba", nl: "Escanaba", fr: "Escanaba" },
    { country_code: "FR", city_code: "ETZ", en: "Metz/Nancy", nl: "Metz/Nancy", fr: "Metz/Nancy" },
    { country_code: "US", city_code: "EUG", en: "Eugene", nl: "Eugene", fr: "Eugene" },
    { country_code: "BQ", city_code: "EUX", en: "St Eustatius", nl: "St Eustatius", fr: "St Eustatius" },
    { country_code: "NO", city_code: "EVE", en: "Harstad-Narvik", nl: "Harstad-Narvik", fr: "Harstad-Narvik" },
    { country_code: "AM", city_code: "EVN", en: "Yerevan", nl: "Yerevan", fr: "Erevan" },
    { country_code: "US", city_code: "EVV", en: "Evansville", nl: "Evansville", fr: "Evansville" },
    { country_code: "US", city_code: "EWN", en: "New Bern", nl: "New Bern", fr: "New Bern" },
    { country_code: "GB", city_code: "EXT", en: "Exeter", nl: "Exeter", fr: "Exeter" },
    { country_code: "US", city_code: "EYW", en: "Key West", nl: "Key West", fr: "Key West" },
    { country_code: "TR", city_code: "EZS", en: "Elazig", nl: "Elazig", fr: "Elazig" },
    { country_code: "US", city_code: "FAI", en: "Fairbanks", nl: "Fairbanks", fr: "Fairbanks" },
    { country_code: "PT", city_code: "FAO", en: "Faro", nl: "Faro", fr: "Faro" },
    { country_code: "US", city_code: "FAR", en: "Fargo", nl: "Fargo", fr: "Fargo" },
    { country_code: "US", city_code: "FAT", en: "Fresno", nl: "Fresno", fr: "Fresno" },
    { country_code: "US", city_code: "FAY", en: "Fayetteville, NC", nl: "Fayetteville, NC", fr: "Fayetteville, NC" },
    { country_code: "CD", city_code: "FBM", en: "Lubumbashi", nl: "Lubumbashi", fr: "Lubumbashi" },
    { country_code: "US", city_code: "FCA", en: "Kalispell", nl: "Kalispell", fr: "Kalispell" },
    { country_code: "MQ", city_code: "FDF", en: "Fort de France", nl: "Martinique (Fort de France)", fr: "Fort-de-France" },
    { country_code: "CD", city_code: "FIH", en: "Kinshasa", nl: "Kinshasa", fr: "Kinshasa" },
    { country_code: "US", city_code: "FLL", en: "Fort Lauderdale", nl: "Fort Lauderdale", fr: "Fort Lauderdale" },
    { country_code: "BR", city_code: "FLN", en: "Florianopolis", nl: "Florianopolis", fr: "Florianópolis" },
    { country_code: "IT", city_code: "FLR", en: "Florence", nl: "Florence", fr: "Florence" },
    { country_code: "US", city_code: "FMY", en: "Fort Myers", nl: "Fort Myers", fr: "Fort Myers" },
    { country_code: "SL", city_code: "FNA", en: "Freetown", nl: "Freetown", fr: "Freetown" },
    { country_code: "PT", city_code: "FNC", en: "Funchal", nl: "Funchal", fr: "Funchal" },
    { country_code: "FR", city_code: "FNI", en: "Nimes", nl: "Nimes", fr: "Nimes" },
    { country_code: "US", city_code: "FNT", en: "Flint", nl: "Flint", fr: "Flint" },
    { country_code: "CN", city_code: "FOC", en: "Fuzhou", nl: "Fuzhou", fr: "Fuzhou" },
    { country_code: "US", city_code: "FOD", en: "Fort Dodge", nl: "Fort Dodge", fr: "Fort Dodge" },
    { country_code: "BR", city_code: "FOR", en: "Fortaleza", nl: "Fortaleza", fr: "Fortaleza" },
    { country_code: "DE", city_code: "FRA", en: "Frankfurt/Main", nl: "Frankfurt/Main", fr: "Francfort-sur-le-Main" },
    { country_code: "KG", city_code: "FRU", en: "Bishkek", nl: "Bishkek", fr: "Bishkek" },
    { country_code: "FR", city_code: "FSC", en: "Figari", nl: "Figari", fr: "Figari" },
    { country_code: "US", city_code: "FSD", en: "Sioux Falls", nl: "Sioux Falls", fr: "Sioux Fall" },
    { country_code: "US", city_code: "FSM", en: "Fort Smith", nl: "Fort Smith", fr: "Fort Smith" },
    { country_code: "JP", city_code: "FSZ", en: "Shizuoka", nl: "Shizuoka", fr: "Shizuoka" },
    { country_code: "AR", city_code: "FTE", en: "El Calafate", nl: "El Calafate", fr: "El Calafate" },
    { country_code: "ES", city_code: "FUE", en: "Fuerteventura", nl: "Fuerteventura", fr: "Fuerteventura" },
    { country_code: "JP", city_code: "FUK", en: "Fukuoka", nl: "Fukuoka", fr: "Fukuoka" },
    { country_code: "US", city_code: "FWA", en: "Fort Wayne", nl: "Fort Wayne", fr: "Fort Wayne" },
    { country_code: "US", city_code: "FYV", en: "Fayetteville, AR", nl: "Fayetteville, AR", fr: "Fayetteville, AR" },
    { country_code: "CU", city_code: "GAO", en: "Guantanamo", nl: "Guantanamo", fr: "Guantanamo" },
    { country_code: "IN", city_code: "GAU", en: "Guwahati", nl: "Guwahati", fr: "Guwahati" },
    { country_code: "BW", city_code: "GBE", en: "Gaborone", nl: "Gaborone", fr: "Gaborone" },
    { country_code: "MX", city_code: "GDL", en: "Guadalajara", nl: "Guadalajara", fr: "Guadalajara" },
    { country_code: "PL", city_code: "GDN", en: "Gdansk", nl: "Gdansk", fr: "Gdansk" },
    { country_code: "US", city_code: "GEG", en: "Spokane", nl: "Spokane", fr: "Spokane" },
    { country_code: "SE", city_code: "GEV", en: "Gallivare", nl: "Gallivare", fr: "Gallivare" },
    { country_code: "US", city_code: "GFK", en: "Grand Forks", nl: "Grand Forks", fr: "Grand Forks" },
    { country_code: "NZ", city_code: "GIS", en: "Gisborne", nl: "Gisborne", fr: "Gisborne" },
    { country_code: "GB", city_code: "GLA", en: "Glasgow", nl: "Glasgow", fr: "Glasgow" },
    { country_code: "US", city_code: "GLH", en: "Greenville, MS", nl: "Greenville, MS", fr: "Greenville, MS" },
    { country_code: "US", city_code: "GNV", en: "Gainesville", nl: "Gainesville", fr: "Gainesville" },
    { country_code: "IT", city_code: "GOA", en: "Genoa", nl: "Genua", fr: "Gênes" },
    { country_code: "IN", city_code: "GOI", en: "Goa", nl: "Goa", fr: "Goa" },
    { country_code: "RU", city_code: "GOJ", en: "Nizhniy Novgorod", nl: "Nizhniy Novgorod", fr: "Nizhniy Novgorod" },
    { country_code: "SE", city_code: "GOT", en: "Gothenburg", nl: "Gothenburg", fr: "Göteborg" },
    { country_code: "US", city_code: "GPT", en: "Gulfport", nl: "Gulfport", fr: "Gulfport" },
    { country_code: "US", city_code: "GRB", en: "Green Bay", nl: "Green Bay", fr: "Green Bay" },
    { country_code: "ZA", city_code: "GRJ", en: "George", nl: "George", fr: "George" },
    { country_code: "ES", city_code: "GRO", en: "Girona", nl: "Girona", fr: "Girona" },
    { country_code: "US", city_code: "GRR", en: "Grand Rapids", nl: "Grand Rapids", fr: "Grand Rapids" },
    { country_code: "AT", city_code: "GRZ", en: "Graz", nl: "Graz", fr: "Graz" },
    { country_code: "US", city_code: "GSO", en: "Greensboro", nl: "Greensboro", fr: "Greensboro" },
    { country_code: "US", city_code: "GSP", en: "Greenville, SC", nl: "Greenville, SC", fr: "Greenville, SC" },
    { country_code: "US", city_code: "GTF", en: "Great Falls", nl: "Great Falls", fr: "Great Falls" },
    { country_code: "GT", city_code: "GUA", en: "Guatemala City", nl: "Guatemala-Stad", fr: "Guatemala" },
    { country_code: "GU", city_code: "GUM", en: "Guam", nl: "Guam", fr: "Guam" },
    { country_code: "KZ", city_code: "GUW", en: "Atyrau", nl: "Atyrau", fr: "Atyrau" },
    { country_code: "CH", city_code: "GVA", en: "Geneva", nl: "Genève", fr: "Genève" },
    { country_code: "EC", city_code: "GYE", en: "Guayaquil", nl: "Guayaquil", fr: "Guayaquil" },
    { country_code: "BR", city_code: "GYN", en: "Goiania", nl: "Goiania", fr: "Goiânia" },
    { country_code: "TR", city_code: "GZP", en: "Gazipasa", nl: "Gazipasa", fr: "Gazipasa" },
    { country_code: "TR", city_code: "GZT", en: "Gaziantep", nl: "Gaziantep", fr: "Gaziantep" },
    { country_code: "JP", city_code: "HAC", en: "Hachijo Jima", nl: "Hachijo Jima", fr: "Hachijo Jima" },
    { country_code: "SE", city_code: "HAD", en: "Halmstad", nl: "Halmstad", fr: "Halmstad" },
    { country_code: "DE", city_code: "HAJ", en: "Hannover", nl: "Hannover", fr: "Hanovre" },
    { country_code: "CN", city_code: "HAK", en: "Haikou", nl: "Haikou", fr: "Haikou" },
    { country_code: "DE", city_code: "HAM", en: "Hamburg", nl: "Hamburg", fr: "Hambourg" },
    { country_code: "VN", city_code: "HAN", en: "Hanoi", nl: "Hanoi", fr: "Hanoï" },
    { country_code: "US", city_code: "HAR", en: "Harrisburg", nl: "Harrisburg", fr: "Harrisburg" },
    { country_code: "NO", city_code: "HAU", en: "Haugesund", nl: "Haugesund", fr: "Haugesund" },
    { country_code: "CU", city_code: "HAV", en: "Havana", nl: "Havana", fr: "La Havane" },
    { country_code: "ZA", city_code: "HDS", en: "Hoedspruit", nl: "Hoedspruit", fr: "Hoedspruit" },
    { country_code: "FI", city_code: "HEL", en: "Helsinki", nl: "Helsinki", fr: "Helsinki" },
    { country_code: "GR", city_code: "HER", en: "Heraklion", nl: "Heraklion", fr: "Heraklion" },
    { country_code: "CN", city_code: "HET", en: "Hohhot", nl: "Hohhot", fr: "Hohhot" },
    { country_code: "US", city_code: "HFD", en: "Hartford/Springfield", nl: "Hartford/Springfield", fr: "Hartford/Springfield" },
    { country_code: "CN", city_code: "HFE", en: "Hefei", nl: "Hefei", fr: "Hefei" },
    { country_code: "CN", city_code: "HGH", en: "Hangzhou", nl: "Hangzhou", fr: "Hangzhou (nearby Shanghai)" },
    { country_code: "US", city_code: "HIB", en: "Chisholm", nl: "Chisholm", fr: "Chisholm" },
    { country_code: "JP", city_code: "HIJ", en: "Hiroshima", nl: "Hiroshima", fr: "Hiroshima" },
    { country_code: "KR", city_code: "HIN", en: "Jinju", nl: "Jinju", fr: "Jinju" },
    { country_code: "JP", city_code: "HKD", en: "Hakodate", nl: "Hakodate", fr: "Hakodate" },
    { country_code: "HK", city_code: "HKG", en: "Hong Kong", nl: "Hongkong", fr: "Hong Kong" },
    { country_code: "TH", city_code: "HKT", en: "Phuket", nl: "Phuket", fr: "Phuket" },
    { country_code: "US", city_code: "HLN", en: "Helena", nl: "Helena", fr: "Helena" },
    { country_code: "NZ", city_code: "HLZ", en: "Hamilton", nl: "Hamilton", fr: "Hamilton" },
    { country_code: "MX", city_code: "HMO", en: "Hermosillo", nl: "Hermosillo", fr: "Hermosillo" },
    { country_code: "SE", city_code: "HMV", en: "Hemavan Tärnaby", nl: "Hemavan Tärnaby", fr: "Hemavan Tärnaby" },
    { country_code: "US", city_code: "HNL", en: "Honolulu", nl: "Honolulu", fr: "Honolulu" },
    { country_code: "CU", city_code: "HOG", en: "Holguin", nl: "Holguin", fr: "Holguin" },
    { country_code: "US", city_code: "HOU", en: "Houston", nl: "Houston", fr: "Houston" },
    { country_code: "VN", city_code: "HPH", en: "Haiphong", nl: "Haiphong", fr: "Haiphong" },
    { country_code: "US", city_code: "HPN", en: "White Plains", nl: "White Plains", fr: "White Plains" },
    { country_code: "CN", city_code: "HRB", en: "Harbin", nl: "Harbin", fr: "Harbin" },
    { country_code: "ZW", city_code: "HRE", en: "Harare", nl: "Harare", fr: "Harare" },
    { country_code: "UA", city_code: "HRK", en: "Kharkov", nl: "Charkov", fr: "Kharkov" },
    { country_code: "US", city_code: "HSV", en: "Huntsville", nl: "Huntsville", fr: "Huntsville" },
    { country_code: "US", city_code: "HTS", en: "Huntington", nl: "Huntington", fr: "Huntington" },
    { country_code: "TR", city_code: "HTY", en: "Hatay", nl: "Hatay", fr: "Hatay" },
    { country_code: "VN", city_code: "HUI", en: "Hue", nl: "Hue", fr: "Hue" },
    { country_code: "MX", city_code: "HUX", en: "Huatulco", nl: "Huatulco", fr: "Huatulco" },
    { country_code: "GB", city_code: "HUY", en: "Humberside", nl: "Humberside", fr: "Humberside" },
    { country_code: "IN", city_code: "HYD", en: "Hyderabad", nl: "Hyderabad", fr: "Hyderabad" },
    { country_code: "RO", city_code: "IAS", en: "Iasi", nl: "Iasi", fr: "Iași" },
    { country_code: "CO", city_code: "IBE", en: "Ibague", nl: "Ibague", fr: "Ibague" },
    { country_code: "ES", city_code: "IBZ", en: "Ibiza", nl: "Ibiza", fr: "Ibiza" },
    { country_code: "US", city_code: "ICT", en: "Wichita", nl: "Wichita", fr: "Wichita" },
    { country_code: "US", city_code: "IDA", en: "Idaho Falls", nl: "Idaho Falls", fr: "Idaho Falls" },
    { country_code: "IN", city_code: "IDR", en: "Indore", nl: "Indore", fr: "Indore" },
    { country_code: "UA", city_code: "IEV", en: "Kiev", nl: "Kiev", fr: "Kiev" },
    { country_code: "BR", city_code: "IGU", en: "Iguassu Falls", nl: "Iguassu Falls", fr: "Chutes d'Igazú" },
    { country_code: "RU", city_code: "IKT", en: "Irkutsk", nl: "Irkutsk", fr: "Irkutsk" },
    { country_code: "US", city_code: "ILE", en: "Killeen", nl: "Killeen", fr: "Killeen" },
    { country_code: "US", city_code: "ILM", en: "Wilmington", nl: "Wilmington", fr: "Wilmington" },
    { country_code: "US", city_code: "IMT", en: "Iron Mountain", nl: "Iron Mountain", fr: "Iron Mountain" },
    { country_code: "CN", city_code: "INC", en: "Yinchuan", nl: "Yinchuan", fr: "Yinchuan" },
    { country_code: "US", city_code: "IND", en: "Indianapolis", nl: "Indianapolis", fr: "Indianapolis" },
    { country_code: "US", city_code: "INL", en: "International Falls", nl: "International Falls", fr: "International Falls" },
    { country_code: "AT", city_code: "INN", en: "Innsbruck", nl: "Innsbruck", fr: "Innsbruck" },
    { country_code: "GB", city_code: "INV", en: "Inverness", nl: "Inverness", fr: "Inverness" },
    { country_code: "BR", city_code: "IOS", en: "Ilhéus", nl: "Ilhéus", fr: "Ilhéus" },
    { country_code: "CL", city_code: "IQQ", en: "Iquique", nl: "Iquique", fr: "Iquique" },
    { country_code: "PE", city_code: "IQT", en: "Iquitos", nl: "Iquitos", fr: "Iquitos" },
    { country_code: "PK", city_code: "ISB", en: "Islamabad", nl: "Islamabad", fr: "Islamabad" },
    { country_code: "US", city_code: "ISN", en: "Williston", nl: "Williston", fr: "Williston" },
    { country_code: "TR", city_code: "IST", en: "Istanbul", nl: "Istanbul", fr: "Istanbul" },
    { country_code: "US", city_code: "ITH", en: "Ithaca", nl: "Ithaca", fr: "Ithaca" },
    { country_code: "FI", city_code: "IVL", en: "Ivalo", nl: "Ivalo", fr: "Ivalo" },
    { country_code: "IN", city_code: "IXC", en: "Chandigarh", nl: "Chandigarh", fr: "Chandigarh" },
    { country_code: "JP", city_code: "IZO", en: "Izumo", nl: "Izumo", fr: "Izumo" },
    { country_code: "US", city_code: "JAC", en: "Jackson, WY", nl: "Jackson, WY", fr: "Jackson, WY" },
    { country_code: "IN", city_code: "JAI", en: "Jaipur", nl: "Jaipur", fr: "Jaipur" },
    { country_code: "US", city_code: "JAN", en: "Jackson, MS", nl: "Jackson, MS", fr: "Jackson, MS" },
    { country_code: "US", city_code: "JAX", en: "Jacksonville, FL", nl: "Jacksonville, FL", fr: "Jacksonville, FL" },
    { country_code: "SA", city_code: "JED", en: "Jeddah", nl: "Jeddah", fr: "Djeddah" },
    { country_code: "MY", city_code: "JHB", en: "Johor Bharu", nl: "Johor Bharu", fr: "Johor Bharu" },
    { country_code: "DJ", city_code: "JIB", en: "Djibouti", nl: "Djibouti", fr: "Djibouti" },
    { country_code: "CN", city_code: "JIU", en: "Jiujiang", nl: "Jiujiang", fr: "Jiujiang" },
    { country_code: "SE", city_code: "JKG", en: "Jonkoping", nl: "Jonkoping", fr: "Jonkoping" },
    { country_code: "GR", city_code: "JKH", en: "Chios", nl: "Chios", fr: "Chios" },
    { country_code: "ID", city_code: "JKT", en: "Jakarta", nl: "Jakarta", fr: "Jakarta" },
    { country_code: "ZA", city_code: "JNB", en: "Johannesburg", nl: "Johannesburg", fr: "Johannesbourg" },
    { country_code: "US", city_code: "JNU", en: "Juneau", nl: "Juneau", fr: "Juneau" },
    { country_code: "FI", city_code: "JOE", en: "Joensuu", nl: "Joensuu", fr: "Joensuu" },
    { country_code: "ID", city_code: "JOG", en: "Yogyakarta", nl: "Yogyakarta", fr: "Yogyakarta" },
    { country_code: "BR", city_code: "JPA", en: "Joao Pessoa", nl: "Joao Pessoa", fr: "Joao Pessoa" },
    { country_code: "TZ", city_code: "JRO", en: "Kilimanjaro", nl: "Kilimanjaro", fr: "Kilimandjaro" },
    { country_code: "GR", city_code: "JTR", en: "Thira", nl: "Thira", fr: "Thira" },
    { country_code: "SS", city_code: "JUB", en: "Juba", nl: "Juba", fr: "Djouba" },
    { country_code: "PE", city_code: "JUL", en: "Juliaca", nl: "Juliaca", fr: "Juliaca" },
    { country_code: "CN", city_code: "JZH", en: "Jiuzhaigou", nl: "Jiuzhaigou", fr: "Jiuzhaigou" },
    { country_code: "FI", city_code: "KAO", en: "Kuusamo", nl: "Kuusamo", fr: "Kuusamo" },
    { country_code: "MY", city_code: "KBR", en: "Kota Bharu", nl: "Kota Bharu", fr: "Kota Bharu" },
    { country_code: "TH", city_code: "KBV", en: "Krabi", nl: "Krabi", fr: "Krabi" },
    { country_code: "MY", city_code: "KCH", en: "Kuching", nl: "Kuching", fr: "Kuching" },
    { country_code: "JP", city_code: "KCZ", en: "Kochi", nl: "Kochi", fr: "Kochi" },
    { country_code: "RU", city_code: "KGD", en: "Kaliningrad", nl: "Kaliningrad", fr: "Kaliningrad" },
    { country_code: "RW", city_code: "KGL", en: "Kigali", nl: "Kigali", fr: "Kigali" },
    { country_code: "GR", city_code: "KGS", en: "Kos", nl: "Kos", fr: "Kos" },
    { country_code: "TW", city_code: "KHH", en: "Kaohsiung", nl: "Kaohsiung", fr: "Kaohsiung" },
    { country_code: "PK", city_code: "KHI", en: "Karachi", nl: "Karachi", fr: "Karachi" },
    { country_code: "CN", city_code: "KHN", en: "Nanchang", nl: "Nanchang", fr: "Nanchang" },
    { country_code: "RU", city_code: "KHV", en: "Khabarovsk", nl: "Khabarovsk", fr: "Khabarovsk" },
    { country_code: "JP", city_code: "KIJ", en: "Niigata", nl: "Niigata", fr: "Niigata" },
    { country_code: "JM", city_code: "KIN", en: "Kingston", nl: "Kingston", fr: "Kingston" },
    { country_code: "KE", city_code: "KIS", en: "Kisumu", nl: "Kisumu", fr: "Kisumu" },
    { country_code: "RU", city_code: "KJA", en: "Krasnoyarsk", nl: "Krasnoyarsk", fr: "Krasnoyarsk" },
    { country_code: "NZ", city_code: "KKE", en: "Bay of Islands", nl: "Bay of Islands", fr: "Bay of Islands" },
    { country_code: "JP", city_code: "KKJ", en: "Kita Kyushu", nl: "Kita Kyushu", fr: "Kita Kyushu" },
    { country_code: "NO", city_code: "KKN", en: "Kirkenes", nl: "Kirkenes", fr: "Kirkenes" },
    { country_code: "CN", city_code: "KMG", en: "Kunming", nl: "Kunming", fr: "Kunming" },
    { country_code: "JP", city_code: "KMI", en: "Miyazaki", nl: "Miyazaki", fr: "Miyazaki" },
    { country_code: "JP", city_code: "KMJ", en: "Kumamoto", nl: "Kumamoto", fr: "Kumamoto" },
    { country_code: "JP", city_code: "KMQ", en: "Komatsu", nl: "Komatsu", fr: "Komatsu" },
    { country_code: "US", city_code: "KOA", en: "Kona", nl: "Kona", fr: "Kona" },
    { country_code: "JP", city_code: "KOJ", en: "Kagoshima", nl: "Kagoshima", fr: "Kagoshima" },
    { country_code: "FI", city_code: "KOK", en: "Kokkola Pietarsaa", nl: "Kokkola Pietarsaa", fr: "Kokkola Pietarsaa" },
    { country_code: "KR", city_code: "KPO", en: "Pohang", nl: "Pohang", fr: "Pohang" },
    { country_code: "SE", city_code: "KRF", en: "Kramfors", nl: "Kramfors", fr: "Kramfors" },
    { country_code: "PL", city_code: "KRK", en: "Krakow", nl: "Krakau", fr: "Cracovie" },
    { country_code: "RU", city_code: "KRR", en: "Krasnodar", nl: "Krasnodar", fr: "Krasnodar" },
    { country_code: "NO", city_code: "KRS", en: "Kristiansand", nl: "Kristiansand", fr: "Kristiansand" },
    { country_code: "SD", city_code: "KRT", en: "Khartoum", nl: "Khartoum", fr: "Khartoum" },
    { country_code: "SK", city_code: "KSC", en: "Kosice", nl: "Kosice", fr: "Košice" },
    { country_code: "SE", city_code: "KSD", en: "Karlstad", nl: "Karlstad", fr: "Karlstad" },
    { country_code: "NO", city_code: "KSU", en: "Kristiansund", nl: "Kristiansund", fr: "Kristiansund" },
    { country_code: "NP", city_code: "KTM", en: "Kathmandu", nl: "Kathmandu", fr: "Kathmandu" },
    { country_code: "US", city_code: "KTN", en: "Ketchikan", nl: "Ketchikan", fr: "Ketchikan" },
    { country_code: "FI", city_code: "KTT", en: "Kittila", nl: "Kittila", fr: "Kittila" },
    { country_code: "MY", city_code: "KUA", en: "Kuantan", nl: "Kuantan", fr: "Kuantan" },
    { country_code: "RU", city_code: "KUF", en: "Samara", nl: "Samara", fr: "Samara" },
    { country_code: "JP", city_code: "KUH", en: "Kushiro", nl: "Kushiro", fr: "Kushiro" },
    { country_code: "MY", city_code: "KUL", en: "Kuala Lumpur", nl: "Kuala Lumpur", fr: "Kuala Lumpur" },
    { country_code: "LT", city_code: "KUN", en: "Kaunas", nl: "Kaunas", fr: "Kaunas" },
    { country_code: "FI", city_code: "KUO", en: "Kuopio", nl: "Kuopio", fr: "Kuopio" },
    { country_code: "CN", city_code: "KWE", en: "Guiyang", nl: "Guiyang", fr: "Guiyang" },
    { country_code: "KW", city_code: "KWI", en: "Kuwait", nl: "Koeweit", fr: "Koweït" },
    { country_code: "KR", city_code: "KWJ", en: "Gwangju", nl: "Gwangju", fr: "Gwangju" },
    { country_code: "CN", city_code: "KWL", en: "Guilin", nl: "Guilin", fr: "Guilin" },
    { country_code: "TR", city_code: "KYA", en: "Konya", nl: "Konya", fr: "Konya" },
    { country_code: "RU", city_code: "KZN", en: "Kazan", nl: "Kazan", fr: "Kazan" },
    { country_code: "KZ", city_code: "KZO", en: "Kyzylorda", nl: "Kyzylorda", fr: "Kyzylorda" },
    { country_code: "TR", city_code: "KZR", en: "Kutahya", nl: "Kutahya", fr: "Kutahya" },
    { country_code: "AO", city_code: "LAD", en: "Luanda", nl: "Luanda", fr: "Luanda" },
    { country_code: "FR", city_code: "LAI", en: "Lannion", nl: "Lannion", fr: "Lannion" },
    { country_code: "US", city_code: "LAN", en: "Lansing", nl: "Lansing", fr: "Lansing" },
    { country_code: "MX", city_code: "LAP", en: "La Paz", nl: "La Paz", fr: "La Paz" },
    { country_code: "US", city_code: "LAS", en: "Las Vegas", nl: "Las Vegas", fr: "Las Vegas" },
    { country_code: "US", city_code: "LAX", en: "Los Angeles", nl: "Los Angeles", fr: "Los Angeles" },
    { country_code: "GB", city_code: "LBA", en: "Leeds", nl: "Leeds", fr: "Leeds" },
    { country_code: "MY", city_code: "LBU", en: "Labuan", nl: "Labuan", fr: "Labuan" },
    { country_code: "GA", city_code: "LBV", en: "Libreville", nl: "Libreville", fr: "Libreville" },
    { country_code: "CY", city_code: "LCA", en: "Larnaca", nl: "Larnaca", fr: "Larnaca" },
    { country_code: "ES", city_code: "LCG", en: "A Coruna", nl: "A Coruna", fr: "A Coruna" },
    { country_code: "PL", city_code: "LCJ", en: "Lodz", nl: "Lodz", fr: "Lodz" },
    { country_code: "BR", city_code: "LDB", en: "Londrina", nl: "Londrina", fr: "Londrina" },
    { country_code: "FR", city_code: "LDE", en: "Lourdes", nl: "Lourdes", fr: "Lourdes" },
    { country_code: "RU", city_code: "LED", en: "St Petersburg", nl: "St Petersburg", fr: "Saint-Pétersbourg" },
    { country_code: "US", city_code: "LEX", en: "Lexington", nl: "Lexington", fr: "Lexington" },
    { country_code: "US", city_code: "LFT", en: "Lafayette", nl: "Lafayette", fr: "Lafayette" },
    { country_code: "TG", city_code: "LFW", en: "Lome", nl: "Lome", fr: "Lome" },
    { country_code: "US", city_code: "LGB", en: "Long Beach", nl: "Long Beach", fr: "Long Beach" },
    { country_code: "MY", city_code: "LGK", en: "Langkawi", nl: "Langkawi", fr: "Langkawi" },
    { country_code: "PK", city_code: "LHE", en: "Lahore", nl: "Lahore", fr: "Lahore" },
    { country_code: "CN", city_code: "LHW", en: "Lanzhou", nl: "Lanzhou", fr: "Lanzhou" },
    { country_code: "FR", city_code: "LIG", en: "Limoges", nl: "Limoges", fr: "Limoges" },
    { country_code: "US", city_code: "LIH", en: "Kauai Island", nl: "Kauai Island", fr: "Kauai Island" },
    { country_code: "FR", city_code: "LIL", en: "Lille", nl: "Lille", fr: "Lille" },
    { country_code: "PE", city_code: "LIM", en: "Lima", nl: "Lima", fr: "Lima" },
    { country_code: "CR", city_code: "LIR", en: "Liberia", nl: "Liberia", fr: "Liberia" },
    { country_code: "PT", city_code: "LIS", en: "Lisbon", nl: "Lissabon", fr: "Lisbonne" },
    { country_code: "US", city_code: "LIT", en: "Little Rock", nl: "Little Rock", fr: "Little Rock" },
    { country_code: "CN", city_code: "LJG", en: "Lijiang", nl: "Lijiang", fr: "Lijiang" },
    { country_code: "SI", city_code: "LJU", en: "Ljubljana", nl: "Ljubljana", fr: "Ljubljana" },
    { country_code: "IN", city_code: "LKO", en: "Lucknow", nl: "Lucknow", fr: "Lucknow" },
    { country_code: "MW", city_code: "LLW", en: "Lilongwe", nl: "Lilongwe", fr: "Lilongwe" },
    { country_code: "FR", city_code: "LME", en: "Le Mans", nl: "Le Mans", fr: "Le Mans" },
    { country_code: "MX", city_code: "LMM", en: "Los Mochis", nl: "Los Mochis", fr: "Los Mochis" },
    { country_code: "US", city_code: "LNK", en: "Lincoln", nl: "Lincoln", fr: "Lincoln" },
    { country_code: "GB", city_code: "LON", en: "London", nl: "Londen", fr: "Londres" },
    { country_code: "NG", city_code: "LOS", en: "Lagos", nl: "Lagos", fr: "Lagos" },
    { country_code: "ES", city_code: "LPA", en: "Las Palmas", nl: "Las Palmas", fr: "Las Palmas" },
    { country_code: "BO", city_code: "LPB", en: "La Paz", nl: "La Paz", fr: "La Paz" },
    { country_code: "SE", city_code: "LPI", en: "Linköping", nl: "Linköping", fr: "Linköping" },
    { country_code: "LA", city_code: "LPQ", en: "Luang Prabang", nl: "Luang Prabang", fr: "Luang Prabang" },
    { country_code: "FR", city_code: "LRT", en: "Lorient", nl: "Lorient", fr: "Lorient" },
    { country_code: "US", city_code: "LSE", en: "La Crosse", nl: "La Crosse", fr: "La Crosse" },
    { country_code: "FR", city_code: "LTT", en: "St Tropez", nl: "St Tropez", fr: "St Tropez" },
    { country_code: "ZM", city_code: "LUN", en: "Lusaka", nl: "Lusaka", fr: "Lusaka" },
    { country_code: "LU", city_code: "LUX", en: "Luxembourg", nl: "Luxemburg", fr: "Luxembourg" },
    { country_code: "ZM", city_code: "LVI", en: "Livingstone", nl: "Livingstone", fr: "Livingstone" },
    { country_code: "UA", city_code: "LWO", en: "Lviv", nl: "Lviv", fr: "Lviv" },
    { country_code: "US", city_code: "LWS", en: "Lewiston", nl: "Lewiston", fr: "Lewiston" },
    { country_code: "CN", city_code: "LXA", en: "Lhasa", nl: "Lhasa", fr: "Lhasa" },
    { country_code: "CN", city_code: "LYA", en: "Luoyang", nl: "Luoyang", fr: "Luoyang" },
    { country_code: "SE", city_code: "LYC", en: "Lycksele", nl: "Lycksele", fr: "Lycksele" },
    { country_code: "CN", city_code: "LYG", en: "Lianyungang", nl: "Lianyungang", fr: "Lianyungang" },
    { country_code: "CN", city_code: "LYI", en: "Linyi", nl: "Linyi", fr: "Linyi" },
    { country_code: "FR", city_code: "LYS", en: "Lyon", nl: "Lyon", fr: "Lyon" },
    { country_code: "IN", city_code: "MAA", en: "Chennai", nl: "Chennai", fr: "Chennai/Madras" },
    { country_code: "ES", city_code: "MAD", en: "Madrid", nl: "Madrid", fr: "Madrid" },
    { country_code: "ES", city_code: "MAH", en: "Menorca", nl: "Menorca", fr: "Minorque" },
    { country_code: "GB", city_code: "MAN", en: "Manchester", nl: "Manchester", fr: "Manchester" },
    { country_code: "BR", city_code: "MAO", en: "Manaus", nl: "Manaus", fr: "Manaus" },
    { country_code: "VE", city_code: "MAR", en: "Maracaibo", nl: "Maracaibo", fr: "Maracaibo" },
    { country_code: "KE", city_code: "MBA", en: "Mombasa", nl: "Mombasa", fr: "Mombasa" },
    { country_code: "JP", city_code: "MBE", en: "Monbetsu", nl: "Monbetsu", fr: "Monbetsu" },
    { country_code: "JM", city_code: "MBJ", en: "Montego Bay", nl: "Montego Bay", fr: "Montego Bay" },
    { country_code: "US", city_code: "MBS", en: "Saginaw", nl: "Saginaw", fr: "Saginaw" },
    { country_code: "OM", city_code: "MCT", en: "Muscat", nl: "Muscat", fr: "Mascate" },
    { country_code: "US", city_code: "MCW", en: "Mason City", nl: "Mason City", fr: "Mason City" },
    { country_code: "BR", city_code: "MCZ", en: "Maceio", nl: "Maceio", fr: "Maceio" },
    { country_code: "CO", city_code: "MDE", en: "Medellin", nl: "Medellin", fr: "Medellin" },
    { country_code: "AR", city_code: "MDZ", en: "Mendoza", nl: "Mendoza", fr: "Mendoza" },
    { country_code: "US", city_code: "MEI", en: "Meridian", nl: "Meridian", fr: "Meridian" },
    { country_code: "AU", city_code: "MEL", en: "Melbourne", nl: "Melbourne", fr: "Melbourne" },
    { country_code: "US", city_code: "MEM", en: "Memphis", nl: "Memphis", fr: "Memphis" },
    { country_code: "ID", city_code: "MES", en: "Medan", nl: "Medan", fr: "Medan" },
    { country_code: "MX", city_code: "MEX", en: "Mexico City", nl: "Mexico-Stad", fr: "Mexico" },
    { country_code: "US", city_code: "MFR", en: "Medford", nl: "Medford", fr: "Medford" },
    { country_code: "NI", city_code: "MGA", en: "Managua", nl: "Managua", fr: "Managua" },
    { country_code: "BR", city_code: "MGF", en: "Maringa", nl: "Maringa", fr: "Maringa" },
    { country_code: "US", city_code: "MGM", en: "Montgomery", nl: "Montgomery", fr: "Montgomery" },
    { country_code: "FI", city_code: "MHQ", en: "Mariehamn", nl: "Mariehamn", fr: "Mariehamn" },
    { country_code: "US", city_code: "MHT", en: "Manchester, NH", nl: "Manchester, NH", fr: "Manchester, NH" },
    { country_code: "US", city_code: "MIA", en: "Miami", nl: "Miami", fr: "Miami" },
    { country_code: "MX", city_code: "MID", en: "Merida", nl: "Merida", fr: "Mérida" },
    { country_code: "IT", city_code: "MIL", en: "Milan", nl: "Milaan", fr: "Milan" },
    { country_code: "NO", city_code: "MJF", en: "Mosjoen", nl: "Mosjoen", fr: "Mosjoen" },
    { country_code: "GR", city_code: "MJT", en: "Mytilini", nl: "Mytilini", fr: "Mytilini" },
    { country_code: "US", city_code: "MKC", en: "Kansas City", nl: "Kansas City", fr: "Kansas City" },
    { country_code: "US", city_code: "MKE", en: "Milwaukee", nl: "Milwaukee", fr: "Milwaukee" },
    { country_code: "MT", city_code: "MLA", en: "Malta", nl: "Malta", fr: "Malta" },
    { country_code: "US", city_code: "MLB", en: "Melbourne", nl: "Melbourne", fr: "Melbourne" },
    { country_code: "MV", city_code: "MLE", en: "Male", nl: "Male", fr: "Male" },
    { country_code: "US", city_code: "MLI", en: "Moline", nl: "Moline", fr: "Moline" },
    { country_code: "US", city_code: "MLU", en: "Monroe", nl: "Monroe", fr: "Monroe" },
    { country_code: "LR", city_code: "MLW", en: "Monrovia", nl: "Monrovia", fr: "Monrovia" },
    { country_code: "TR", city_code: "MLX", en: "Malatya", nl: "Malatya", fr: "Malatya" },
    { country_code: "JP", city_code: "MMB", en: "Memanbetsu", nl: "Memanbetsu", fr: "Memanbetsu" },
    { country_code: "GB", city_code: "MME", en: "Durham", nl: "Durham", fr: "Durham" },
    { country_code: "RU", city_code: "MMK", en: "Murmansk", nl: "Murmansk", fr: "Murmansk" },
    { country_code: "PH", city_code: "MNL", en: "Manila", nl: "Manilla", fr: "Manille" },
    { country_code: "US", city_code: "MOB", en: "Mobile", nl: "Mobile", fr: "Mobile" },
    { country_code: "NO", city_code: "MOL", en: "Molde", nl: "Molde", fr: "Molde" },
    { country_code: "US", city_code: "MOT", en: "Minot", nl: "Minot", fr: "Minot" },
    { country_code: "RU", city_code: "MOW", en: "Moscow", nl: "Moskou", fr: "Moscou" },
    { country_code: "FR", city_code: "MPL", en: "Montpellier", nl: "Montpellier", fr: "Montpellier" },
    { country_code: "MZ", city_code: "MPM", en: "Maputo", nl: "Maputo", fr: "Maputo" },
    { country_code: "TR", city_code: "MQM", en: "Mardin", nl: "Mardin", fr: "Mardin" },
    { country_code: "NO", city_code: "MQN", en: "Mo i rana", nl: "Mo i rana", fr: "Mo i rana" },
    { country_code: "FR", city_code: "MRS", en: "Marseille", nl: "Marseille", fr: "Marseille" },
    { country_code: "MU", city_code: "MRU", en: "Mauritius", nl: "Mauritius", fr: "Maurice" },
    { country_code: "RU", city_code: "MRV", en: "Mineralnye Vody", nl: "Mineralnye Vody", fr: "Mineralnye Vody" },
    { country_code: "US", city_code: "MSN", en: "Madison", nl: "Madison", fr: "Madison" },
    { country_code: "US", city_code: "MSO", en: "Missoula", nl: "Missoula", fr: "Missoula" },
    { country_code: "US", city_code: "MSP", en: "Minneapolis", nl: "Minneapolis", fr: "Minneapolis" },
    { country_code: "BY", city_code: "MSQ", en: "Minsk", nl: "Minsk", fr: "Minsk" },
    { country_code: "TR", city_code: "MSR", en: "Mus", nl: "Mus", fr: "Mus" },
    { country_code: "NL", city_code: "MST", en: "Maastricht", nl: "Maastricht", fr: "Maastricht" },
    { country_code: "US", city_code: "MSY", en: "New Orleans", nl: "New Orleans", fr: "Nouvelle-Orléans" },
    { country_code: "CO", city_code: "MTR", en: "Montería", nl: "Montería", fr: "Montería" },
    { country_code: "MX", city_code: "MTY", en: "Monterrey", nl: "Monterrey", fr: "Monterrey" },
    { country_code: "DE", city_code: "MUC", en: "Munich", nl: "Munchen", fr: "Munich" },
    { country_code: "TZ", city_code: "MUZ", en: "Musoma", nl: "Musoma", fr: "Musoma" },
    { country_code: "UY", city_code: "MVD", en: "Montevideo", nl: "Montevideo", fr: "Montevideo" },
    { country_code: "TZ", city_code: "MWZ", en: "Mwanza", nl: "Mwanza", fr: "Mwanza" },
    { country_code: "MX", city_code: "MXL", en: "Mexicali", nl: "Mexicali", fr: "Mexicali" },
    { country_code: "KE", city_code: "MYD", en: "Malindi", nl: "Malindi", fr: "Malindi" },
    { country_code: "JP", city_code: "MYJ", en: "Matsuyama", nl: "Matsuyama", fr: "Matsuyama" },
    { country_code: "US", city_code: "MYR", en: "Myrtle Beach", nl: "Myrtle Beach", fr: "Myrtle Beach" },
    { country_code: "TZ", city_code: "MYW", en: "Mtwara", nl: "Mtwara", fr: "Mtwara" },
    { country_code: "MY", city_code: "MYY", en: "Miri", nl: "Miri", fr: "Miri" },
    { country_code: "TR", city_code: "MZH", en: "Amasya", nl: "Amasya", fr: "Amasya" },
    { country_code: "CO", city_code: "MZL", en: "Manizales", nl: "Manizales", fr: "Manizales" },
    { country_code: "MX", city_code: "MZT", en: "Mazatlan", nl: "Mazatlan", fr: "Mazatlan" },
    { country_code: "IT", city_code: "NAP", en: "Naples", nl: "Napels", fr: "Naples" },
    { country_code: "BS", city_code: "NAS", en: "Nassau", nl: "Nassau", fr: "Nassau" },
    { country_code: "BR", city_code: "NAT", en: "Natal", nl: "Natal", fr: "Natal" },
    { country_code: "TR", city_code: "NAV", en: "Nevsehir", nl: "Nevsehir", fr: "Nevsehir" },
    { country_code: "RU", city_code: "NBC", en: "Nizhnekamsk", nl: "Nizhnekamsk", fr: "Nizhnekamsk" },
    { country_code: "KE", city_code: "NBO", en: "Nairobi", nl: "Nairobi", fr: "Nairobi" },
    { country_code: "FR", city_code: "NCE", en: "Nice", nl: "Nice", fr: "Nice" },
    { country_code: "GB", city_code: "NCL", en: "Newcastle", nl: "Newcastle", fr: "Newcastle" },
    { country_code: "FR", city_code: "NCY", en: "Annecy", nl: "Annecy", fr: "Annecy" },
    { country_code: "TD", city_code: "NDJ", en: "Ndjamena", nl: "Ndjamena", fr: "Ndjamena" },
    { country_code: "KN", city_code: "NEV", en: "Nevis", nl: "Nevis", fr: "Nevis" },
    { country_code: "CN", city_code: "NGB", en: "Ningbo", nl: "Ningbo", fr: "Ningbo" },
    { country_code: "JP", city_code: "NGO", en: "Nagoya", nl: "Nagoya", fr: "Nagoya" },
    { country_code: "JP", city_code: "NGS", en: "Nagasaki", nl: "Nagasaki", fr: "Nagasaki" },
    { country_code: "VN", city_code: "NHA", en: "Nha Trang", nl: "Nha Trang", fr: "Nha Trang" },
    { country_code: "NE", city_code: "NIM", en: "Niamey", nl: "Niamey", fr: "Niamey" },
    { country_code: "RU", city_code: "NJC", en: "Nizhnevartovsk", nl: "Nizhnevartovsk", fr: "Nizhnevartovsk" },
    { country_code: "MR", city_code: "NKC", en: "Nouakchott", nl: "Nouakchott", fr: "Nouakchott" },
    { country_code: "CN", city_code: "NKG", en: "Nanjing", nl: "Nanjing", fr: "Nanjing" },
    { country_code: "ZM", city_code: "NLA", en: "Ndola", nl: "Ndola", fr: "Ndola" },
    { country_code: "MX", city_code: "NLD", en: "Nuevo Laredo", nl: "Nuevo Laredo", fr: "Nuevo Laredo" },
    { country_code: "CN", city_code: "NNG", en: "Nanning", nl: "Nanning", fr: "Nanning" },
    { country_code: "NC", city_code: "NOU", en: "Noumea", nl: "Noumea", fr: "Nouméa" },
    { country_code: "NZ", city_code: "NPE", en: "Napier", nl: "Napier", fr: "Napier" },
    { country_code: "NZ", city_code: "NPL", en: "New Plymouth", nl: "New Plymouth", fr: "New Plymouth" },
    { country_code: "NZ", city_code: "NSN", en: "Nelson", nl: "Nelson", fr: "Nelson" },
    { country_code: "FR", city_code: "NTE", en: "Nantes", nl: "Nantes", fr: "Nantes" },
    { country_code: "CN", city_code: "NTG", en: "Nantong", nl: "Nantong", fr: "Nantong" },
    { country_code: "DE", city_code: "NUE", en: "Nuremberg", nl: "Neurenberg", fr: "Nuremberg" },
    { country_code: "CO", city_code: "NVA", en: "Neiva", nl: "Neiva", fr: "Neiva" },
    { country_code: "BR", city_code: "NVT", en: "Navegantes", nl: "Navegantes", fr: "Navegantes" },
    { country_code: "GB", city_code: "NWI", en: "Norwich", nl: "Norwich", fr: "Norwich" },
    { country_code: "US", city_code: "NYC", en: "New York", nl: "New York", fr: "New York" },
    { country_code: "US", city_code: "OAJ", en: "Jacksonville, NC", nl: "Jacksonville, NC", fr: "Jacksonville, NC" },
    { country_code: "US", city_code: "OAK", en: "Oakland", nl: "Oakland", fr: "Oakland" },
    { country_code: "MX", city_code: "OAX", en: "Oaxaca", nl: "Oaxaca", fr: "Oaxaca" },
    { country_code: "UA", city_code: "ODS", en: "Odessa", nl: "Odessa", fr: "Odessa" },
    { country_code: "SE", city_code: "OER", en: "Ornskoldsvik", nl: "Ornskoldsvik", fr: "Ornskoldsvik" },
    { country_code: "US", city_code: "OGG", en: "Maui", nl: "Maui", fr: "Maui" },
    { country_code: "JP", city_code: "OIT", en: "Oita", nl: "Oita", fr: "Oita" },
    { country_code: "JP", city_code: "OKA", en: "Okinawa", nl: "Okinawa", fr: "Okinawa" },
    { country_code: "US", city_code: "OKC", en: "Oklahoma City", nl: "Oklahoma City", fr: "Oklahoma City" },
    { country_code: "JP", city_code: "OKJ", en: "Okayama", nl: "Okayama", fr: "Okayama" },
    { country_code: "IT", city_code: "OLB", en: "Olbia", nl: "Olbia", fr: "Olbia" },
    { country_code: "US", city_code: "OMA", en: "Omaha", nl: "Omaha", fr: "Omaha" },
    { country_code: "RU", city_code: "OMS", en: "Omsk", nl: "Omsk", fr: "Omsk" },
    { country_code: "US", city_code: "ONT", en: "Ontario", nl: "Ontario", fr: "Ontario" },
    { country_code: "AU", city_code: "OOL", en: "Gold Coast", nl: "Gold Coast", fr: "Gold Coast" },
    { country_code: "PT", city_code: "OPO", en: "Porto", nl: "Porto", fr: "Porto" },
    { country_code: "US", city_code: "ORF", en: "Norfolk", nl: "Norfolk", fr: "Norfolk" },
    { country_code: "IE", city_code: "ORK", en: "Cork", nl: "Cork", fr: "Cork" },
    { country_code: "US", city_code: "ORL", en: "Orlando", nl: "Orlando", fr: "Orlando" },
    { country_code: "DZ", city_code: "ORN", en: "Oran", nl: "Oran", fr: "Oran" },
    { country_code: "JP", city_code: "OSA", en: "Osaka", nl: "Osaka", fr: "Osaka" },
    { country_code: "NO", city_code: "OSL", en: "Oslo", nl: "Oslo", fr: "Oslo" },
    { country_code: "CZ", city_code: "OSR", en: "Ostrava", nl: "Ostrava", fr: "Ostrava" },
    { country_code: "BF", city_code: "OUA", en: "Uagadugú", nl: "Uagadugú", fr: "Ouagadougou" },
    { country_code: "FI", city_code: "OUL", en: "Oulu", nl: "Oulu", fr: "Oulu" },
    { country_code: "RU", city_code: "OVB", en: "Novosibirsk", nl: "Novosibirsk", fr: "Novosibirsk" },
    { country_code: "ES", city_code: "OVD", en: "Oviedo", nl: "Oviedo", fr: "Oviedo" },
    { country_code: "UA", city_code: "OZH", en: "Zaporizhzhia", nl: "Zaporizhzhia", fr: "Zaporizhzhia" },
    { country_code: "HT", city_code: "PAP", en: "Port-au-Prince", nl: "Port-au-Prince", fr: "Port-au-Prince" },
    { country_code: "FR", city_code: "PAR", en: "Paris", nl: "Parijs", fr: "Paris" },
    { country_code: "IN", city_code: "PAT", en: "Patna", nl: "Patna", fr: "Patna" },
    { country_code: "IN", city_code: "PBD", en: "Porbandar", nl: "Porbandar", fr: "Porbandar" },
    { country_code: "US", city_code: "PBI", en: "West Palm Beach", nl: "West Palm Beach", fr: "West Palm Beach" },
    { country_code: "SR", city_code: "PBM", en: "Paramaribo", nl: "Paramaribo", fr: "Paramaribo" },
    { country_code: "ID", city_code: "PDG", en: "Padang", nl: "Padang", fr: "Padang" },
    { country_code: "PT", city_code: "PDL", en: "Ponta Delgada", nl: "Ponta Delgada", fr: "Ponta Delgada" },
    { country_code: "US", city_code: "PDX", en: "Portland, OR", nl: "Portland, OR", fr: "Portland, OR" },
    { country_code: "RU", city_code: "PEE", en: "Perm", nl: "Perm", fr: "Perm" },
    { country_code: "CO", city_code: "PEI", en: "Pereira", nl: "Pereira", fr: "Pereira" },
    { country_code: "MY", city_code: "PEN", en: "Penang", nl: "Penang", fr: "Penang" },
    { country_code: "AU", city_code: "PER", en: "Perth", nl: "Perth", fr: "Perth" },
    { country_code: "CY", city_code: "PFO", en: "Paphos", nl: "Paphos", fr: "Paphos" },
    { country_code: "FR", city_code: "PGF", en: "Perpignan", nl: "Perpignan", fr: "Perpignan" },
    { country_code: "NG", city_code: "PHC", en: "Port Harcourt", nl: "Port Harcourt", fr: "Port Harcourt" },
    { country_code: "US", city_code: "PHL", en: "Philadelphia", nl: "Philadelphia", fr: "Philadelphie" },
    { country_code: "US", city_code: "PHX", en: "Phoenix", nl: "Phoenix", fr: "Phoenix" },
    { country_code: "US", city_code: "PIA", en: "Peoria", nl: "Peoria", fr: "Peoria" },
    { country_code: "US", city_code: "PIR", en: "Pierre", nl: "Pierre", fr: "Pierre" },
    { country_code: "FR", city_code: "PIS", en: "Poitiers", nl: "Poitiers", fr: "Poitiers" },
    { country_code: "US", city_code: "PIT", en: "Pittsburgh", nl: "Pittsburgh", fr: "Pittsburg" },
    { country_code: "ID", city_code: "PKU", en: "Pekanbaru", nl: "Pekanbaru", fr: "Pekanbaru" },
    { country_code: "ID", city_code: "PLM", en: "Palembang", nl: "Palembang", fr: "Palembang" },
    { country_code: "US", city_code: "PLN", en: "Pellston", nl: "Pellston", fr: "Pellston" },
    { country_code: "LT", city_code: "PLQ", en: "Palanga", nl: "Palanga", fr: "Palanga" },
    { country_code: "ZA", city_code: "PLZ", en: "Port Elizabeth", nl: "Port Elizabeth", fr: "Port Elizabeth" },
    { country_code: "CL", city_code: "PMC", en: "Puerto Montt", nl: "Puerto Montt", fr: "Puerto Montt" },
    { country_code: "ES", city_code: "PMI", en: "Palma de Mallorca", nl: "Palma Mallorca", fr: "Palma de Majorque" },
    { country_code: "IT", city_code: "PMO", en: "Palermo", nl: "Palermo", fr: "Palerme" },
    { country_code: "NZ", city_code: "PMR", en: "Palmerston North", nl: "Palmerston North", fr: "Palmerston North" },
    { country_code: "KH", city_code: "PNH", en: "Phnom Penh", nl: "Phnom Penh", fr: "Phnom Penh" },
    { country_code: "IN", city_code: "PNQ", en: "Pune", nl: "Pune", fr: "Pune" },
    { country_code: "CG", city_code: "PNR", en: "Pointe Noire", nl: "Pointe Noire", fr: "Pointe-Noire" },
    { country_code: "US", city_code: "PNS", en: "Pensacola", nl: "Pensacola", fr: "Pensacola" },
    { country_code: "BR", city_code: "POA", en: "Porto Alegre", nl: "Porto Alegre", fr: "Porto Alegre" },
    { country_code: "PG", city_code: "POM", en: "Port Moresby", nl: "Port Moresby", fr: "Port Moresby" },
    { country_code: "FI", city_code: "POR", en: "Pori", nl: "Pori", fr: "Pori" },
    { country_code: "TT", city_code: "POS", en: "Port of Spain", nl: "Port of Spain", fr: "Port of Spain" },
    { country_code: "CO", city_code: "PPN", en: "Popayan", nl: "Popayan", fr: "Popayan" },
    { country_code: "PF", city_code: "PPT", en: "Papeete", nl: "Papeete", fr: "Papeete" },
    { country_code: "CZ", city_code: "PRG", en: "Prague", nl: "Praag", fr: "Prague" },
    { country_code: "SC", city_code: "PRI", en: "Praslin Island", nl: "Praslin Island", fr: "Praslin Island" },
    { country_code: "IT", city_code: "PSA", en: "Pisa", nl: "Pisa", fr: "Pise" },
    { country_code: "US", city_code: "PSC", en: "Pasco", nl: "Pasco", fr: "Pasco" },
    { country_code: "US", city_code: "PSP", en: "Palm Springs", nl: "Palm Springs", fr: "Palm Springs" },
    { country_code: "GP", city_code: "PTP", en: "Pointe a Pitre", nl: "Pointe a Pitre", fr: "Point-à-Pitre" },
    { country_code: "PA", city_code: "PTY", en: "Panama City", nl: "Panama-Stad", fr: "Panama City" },
    { country_code: "FR", city_code: "PUF", en: "Pau", nl: "Pau", fr: "Pau" },
    { country_code: "DO", city_code: "PUJ", en: "Punta Cana", nl: "Punta Cana", fr: "Punta Cana" },
    { country_code: "CL", city_code: "PUQ", en: "Punta Arenas", nl: "Punta Arenas", fr: "Punta Arenas" },
    { country_code: "KR", city_code: "PUS", en: "Busan", nl: "Busan", fr: "Pusan" },
    { country_code: "US", city_code: "PUW", en: "Pullman", nl: "Pullman", fr: "Pullman" },
    { country_code: "US", city_code: "PVD", en: "Providence", nl: "Providence", fr: "Providence" },
    { country_code: "MX", city_code: "PVR", en: "Puerto Vallarta", nl: "Puerto Vallarta", fr: "Puerto Vallarta" },
    { country_code: "US", city_code: "PWM", en: "Portland, ME", nl: "Portland, ME", fr: "Portland, ME" },
    { country_code: "NL", city_code: "QNQ", en: "Nijmegen", nl: "Nijmegen", fr: "Nijmegen" },
    { country_code: "MA", city_code: "RAK", en: "Marrakech", nl: "Marrakech", fr: "Marrakech" },
    { country_code: "BR", city_code: "RAO", en: "Ribeirão Preto", nl: "Ribeirão Preto", fr: "Ribeirão Preto" },
    { country_code: "US", city_code: "RAP", en: "Rapid City", nl: "Rapid City", fr: "Rapid City" },
    { country_code: "MA", city_code: "RBA", en: "Rabat", nl: "Rabat", fr: "Rabat" },
    { country_code: "ZA", city_code: "RCB", en: "Richards Bay", nl: "Richards Bay", fr: "Richards Bay" },
    { country_code: "US", city_code: "RDM", en: "Redmond, OR", nl: "Redmond, OR", fr: "Redmond, OR" },
    { country_code: "US", city_code: "RDU", en: "Raleigh/Durham", nl: "Raleigh/Durham", fr: "Raleigh/Durham" },
    { country_code: "FR", city_code: "RDZ", en: "Rodez", nl: "Rodez", fr: "Rodez" },
    { country_code: "BR", city_code: "REC", en: "Recife", nl: "Recife", fr: "Recife" },
    { country_code: "IS", city_code: "REK", en: "Reykjavik", nl: "Reykjavik", fr: "Reikiavik" },
    { country_code: "KH", city_code: "REP", en: "Siem Reap", nl: "Siem Reap", fr: "Siem Reap" },
    { country_code: "MX", city_code: "REX", en: "Reynosa", nl: "Reynosa", fr: "Reynosa" },
    { country_code: "MM", city_code: "RGN", en: "Yangon", nl: "Yangon", fr: "Rangoon" },
    { country_code: "FR", city_code: "RHE", en: "Reims", nl: "Reims", fr: "Reims" },
    { country_code: "US", city_code: "RHI", en: "Rhinelander", nl: "Rhinelander", fr: "Rhinelander" },
    { country_code: "GR", city_code: "RHO", en: "Rhodes", nl: "Rhodes", fr: "Rhodes" },
    { country_code: "US", city_code: "RIC", en: "Richmond", nl: "Richmond", fr: "Richmond" },
    { country_code: "BR", city_code: "RIO", en: "Rio de Janeiro", nl: "Rio de Janeiro", fr: "Rio de Janeiro" },
    { country_code: "LV", city_code: "RIX", en: "Riga", nl: "Riga", fr: "Riga" },
    { country_code: "TW", city_code: "RMQ", en: "Taichung", nl: "Taichung", fr: "Taichung" },
    { country_code: "US", city_code: "RNO", en: "Reno", nl: "Reno", fr: "Reno" },
    { country_code: "FR", city_code: "RNS", en: "Rennes", nl: "Rennes", fr: "Rennes" },
    { country_code: "US", city_code: "ROA", en: "Roanoke", nl: "Roanoke", fr: "Roanoke" },
    { country_code: "US", city_code: "ROC", en: "Rochester, NY", nl: "Rochester, NY", fr: "Rochester, NY" },
    { country_code: "IT", city_code: "ROM", en: "Rome", nl: "Rome", fr: "Rome" },
    { country_code: "PW", city_code: "ROR", en: "Koror", nl: "Koror", fr: "Koror" },
    { country_code: "AR", city_code: "ROS", en: "Rosario", nl: "Rosario", fr: "Rosario" },
    { country_code: "NZ", city_code: "ROT", en: "Rotorua", nl: "Rotorua", fr: "Rotorua" },
    { country_code: "RU", city_code: "ROV", en: "Rostov", nl: "Rostov", fr: "Rostov" },
    { country_code: "IN", city_code: "RPR", en: "Raipur", nl: "Raipur", fr: "Raipur" },
    { country_code: "US", city_code: "RST", en: "Rochester, MN", nl: "Rochester, MN", fr: "Rochester, MN" },
    { country_code: "KR", city_code: "RSU", en: "Yeosu", nl: "Yeosu", fr: "Yeosu" },
    { country_code: "SA", city_code: "RUH", en: "Riyadh", nl: "Riad", fr: "Riyad" },
    { country_code: "RE", city_code: "RUN", en: "Saint-Denis (Réunion)", nl: "Saint-Denis (Réunion)", fr: "Saint-Denis (Réunion)" },
    { country_code: "FI", city_code: "RVN", en: "Rovaniemi", nl: "Rovaniemi", fr: "Rovaniemi" },
    { country_code: "BQ", city_code: "SAB", en: "Saba", nl: "Saba", fr: "Saba" },
    { country_code: "US", city_code: "SAC", en: "Sacramento", nl: "Sacramento", fr: "Sacramento" },
    { country_code: "YE", city_code: "SAH", en: "Sanaa", nl: "Sanaa", fr: "Sanaa" },
    { country_code: "SV", city_code: "SAL", en: "San Salvador", nl: "San Salvador", fr: "San Salvador" },
    { country_code: "US", city_code: "SAN", en: "San Diego", nl: "San Diego", fr: "San Diego" },
    { country_code: "BR", city_code: "SAO", en: "Sao Paulo", nl: "Sao Paulo", fr: "São Paulo" },
    { country_code: "HN", city_code: "SAP", en: "San Pedro Sula", nl: "San Pedro Sula", fr: "San Pedro Sula" },
    { country_code: "US", city_code: "SAT", en: "San Antonio", nl: "San Antonio", fr: "San Antonio" },
    { country_code: "US", city_code: "SAV", en: "Savannah", nl: "Savannah", fr: "Savannah" },
    { country_code: "GP", city_code: "SBH", en: "St. Barthelemy", nl: "St. Barthelemy", fr: "St. Barthelemy" },
    { country_code: "US", city_code: "SBN", en: "South Bend", nl: "South Bend", fr: "South Bend" },
    { country_code: "MY", city_code: "SBW", en: "Sibu", nl: "Sibu", fr: "Sibu" },
    { country_code: "US", city_code: "SCE", en: "State College", nl: "State College", fr: "State College" },
    { country_code: "CL", city_code: "SCL", en: "Santiago de Chile", nl: "Santiago de Chile", fr: "Santiago du Chili" },
    { country_code: "KZ", city_code: "SCO", en: "Aktau", nl: "Aktau", fr: "Aktau" },
    { country_code: "CU", city_code: "SCU", en: "Santiago de Cuba", nl: "Santiago de Cuba", fr: "Santiago de Cuba" },
    { country_code: "US", city_code: "SDF", en: "Louisville", nl: "Louisville", fr: "Louisville" },
    { country_code: "JP", city_code: "SDJ", en: "Sendai", nl: "Sendai", fr: "Sendai" },
    { country_code: "MY", city_code: "SDK", en: "Sandakan", nl: "Sandakan", fr: "Sandakan" },
    { country_code: "SE", city_code: "SDL", en: "Sundsvall", nl: "Sundsvall", fr: "Sundsvall" },
    { country_code: "DO", city_code: "SDQ", en: "Santo Domingo", nl: "Santo Domingo", fr: "Saint-Domingue" },
    { country_code: "US", city_code: "SEA", en: "Seattle", nl: "Seattle", fr: "Seattle" },
    { country_code: "KR", city_code: "SEL", en: "Seoul", nl: "Seoul", fr: "Séoul" },
    { country_code: "SC", city_code: "SEZ", en: "Seychelles", nl: "Seychellen", fr: "Seychelles" },
    { country_code: "US", city_code: "SFO", en: "San Francisco", nl: "San Francisco", fr: "San Francisco" },
    { country_code: "TR", city_code: "SFQ", en: "Sanliurfa", nl: "Sanliurfa", fr: "Sanliurfa" },
    { country_code: "DK", city_code: "SGD", en: "Sonderborg", nl: "Sonderborg", fr: "Sonderborg" },
    { country_code: "US", city_code: "SGF", en: "Springfield", nl: "Springfield", fr: "Springfield" },
    { country_code: "VN", city_code: "SGN", en: "Ho Chi Minh City", nl: "Ho Chi Minh-Stad", fr: "Hô-Chi-Minh-Ville" },
    { country_code: "CN", city_code: "SHA", en: "Shanghai", nl: "Shanghai", fr: "Shanghai" },
    { country_code: "JP", city_code: "SHB", en: "Nakashibetsu", nl: "Nakashibetsu", fr: "Nakashibetsu" },
    { country_code: "CN", city_code: "SHE", en: "Shenyang", nl: "Shenyang", fr: "Shenyang" },
    { country_code: "US", city_code: "SHV", en: "Shreveport", nl: "Shreveport", fr: "Shreveport" },
    { country_code: "TZ", city_code: "SHY", en: "Shinyanga", nl: "Shinyanga", fr: "Shinyanga" },
    { country_code: "CN", city_code: "SIA", en: "Xi An", nl: "Xi An", fr: "Xi'an" },
    { country_code: "SG", city_code: "SIN", en: "Singapore", nl: "Singapore", fr: "Singapour" },
    { country_code: "US", city_code: "SIT", en: "Sitka", nl: "Sitka", fr: "Sitka" },
    { country_code: "US", city_code: "SJC", en: "San Jose", nl: "San Jose", fr: "San José" },
    { country_code: "MX", city_code: "SJD", en: "San Jose Cabo", nl: "San Jose Cabo", fr: "San Jose Cabo" },
    { country_code: "BA", city_code: "SJJ", en: "Sarajevo", nl: "Sarajevo", fr: "Sarajevo" },
    { country_code: "CR", city_code: "SJO", en: "San Jose", nl: "San Jose", fr: "San José" },
    { country_code: "BR", city_code: "SJP", en: "Sao Jose Do Rio Preto", nl: "Sao Jose Do Rio Preto", fr: "Sao Jose Do Rio Preto" },
    { country_code: "PR", city_code: "SJU", en: "San Juan", nl: "San Juan", fr: "San Juan" },
    { country_code: "KN", city_code: "SKB", en: "Saint Kitts", nl: "Saint Kitts", fr: "Saint-Christophe-et-Niévès" },
    { country_code: "NO", city_code: "SKE", en: "Skien", nl: "Skien", fr: "Skien" },
    { country_code: "GR", city_code: "SKG", en: "Thessaloniki", nl: "Thessaloniki", fr: "Thessalonique" },
    { country_code: "MK", city_code: "SKP", en: "Skopje", nl: "Skopje", fr: "Skopje" },
    { country_code: "US", city_code: "SLC", en: "Salt Lake City", nl: "Salt Lake City", fr: "Salt Lake City" },
    { country_code: "MX", city_code: "SLP", en: "San Luis Potosi", nl: "San Luis Potosi", fr: "San Luis Potosi" },
    { country_code: "LC", city_code: "SLU", en: "Saint Lucia", nl: "Saint Lucia", fr: "Saint Lucia" },
    { country_code: "BR", city_code: "SLZ", en: "Sao Luiz", nl: "Sao Luiz", fr: "Sao Luiz" },
    { country_code: "GR", city_code: "SMI", en: "Samos", nl: "Samos", fr: "Samos" },
    { country_code: "CO", city_code: "SMR", en: "Santa Marta", nl: "Santa Marta", fr: "Santa Marta" },
    { country_code: "US", city_code: "SNA", en: "Orange County", nl: "Orange County", fr: "Comté d'Orange" },
    { country_code: "IE", city_code: "SNN", en: "Shannon", nl: "Shannon", fr: "Shannon" },
    { country_code: "ID", city_code: "SOC", en: "Solo", nl: "Solo", fr: "Surakarta" },
    { country_code: "BG", city_code: "SOF", en: "Sofia", nl: "Sofia", fr: "Sofia" },
    { country_code: "GB", city_code: "SOU", en: "Southampton", nl: "Southampton", fr: "Southampton" },
    { country_code: "ES", city_code: "SPC", en: "Santa Cruz De La Palma", nl: "Santa Cruz De La Palma", fr: "Santa Cruz De La Palma" },
    { country_code: "JP", city_code: "SPK", en: "Sapporo", nl: "Sapporo", fr: "Sapporo" },
    { country_code: "MP", city_code: "SPN", en: "Saipan", nl: "Saipan", fr: "Saipan" },
    { country_code: "HR", city_code: "SPU", en: "Split", nl: "Split", fr: "Split" },
    { country_code: "ID", city_code: "SRG", en: "Semarang", nl: "Semarang", fr: "Semarang" },
    { country_code: "US", city_code: "SRQ", en: "Sarasota", nl: "Sarasota", fr: "Sarasota" },
    { country_code: "BO", city_code: "SRZ", en: "Santa Cruz", nl: "Santa Cruz", fr: "Santa Cruz" },
    { country_code: "BR", city_code: "SSA", en: "Salvador", nl: "Salvador", fr: "Salvador" },
    { country_code: "GQ", city_code: "SSG", en: "Malabo", nl: "Malabo", fr: "Malabo" },
    { country_code: "US", city_code: "SSI", en: "Brunswick", nl: "Brunswick", fr: "Brunswick" },
    { country_code: "NO", city_code: "SSJ", en: "Sandnessjoen", nl: "Sandnessjoen", fr: "Sandnessjoen" },
    { country_code: "US", city_code: "SSM", en: "Sault Ste Marie", nl: "Sault Sainte Marie", fr: "Sault-Sainte-Marie" },
    { country_code: "US", city_code: "STL", en: "Saint Louis", nl: "Saint Louis", fr: "Saint-Louis" },
    { country_code: "BR", city_code: "STM", en: "Santarem", nl: "Santarem", fr: "Santarem" },
    { country_code: "SE", city_code: "STO", en: "Stockholm", nl: "Stockholm", fr: "Stockholm" },
    { country_code: "DE", city_code: "STR", en: "Stuttgart", nl: "Stuttgart", fr: "Stuttgart" },
    { country_code: "VI", city_code: "STT", en: "Saint Thomas Island", nl: "Saint Thomas Island", fr: "Saint Thomas Island" },
    { country_code: "VI", city_code: "STX", en: "Saint Croix Island", nl: "Saint Croix Island", fr: "Saint Croix Island" },
    { country_code: "ID", city_code: "SUB", en: "Surabaya", nl: "Surabaya", fr: "Surabaya" },
    { country_code: "IT", city_code: "SUF", en: "Lamezia-Terme", nl: "Lamezia-Terme", fr: "Lamezia Terme" },
    { country_code: "US", city_code: "SUX", en: "Sioux City", nl: "Sioux City", fr: "Sioux City" },
    { country_code: "NO", city_code: "SVG", en: "Stavanger", nl: "Stavanger", fr: "Stavanger" },
    { country_code: "ES", city_code: "SVQ", en: "Sevilla", nl: "Sevilla", fr: "Séville" },
    { country_code: "RU", city_code: "SVX", en: "Yekaterinburg", nl: "Yekaterinburg", fr: "Yekaterinburg" },
    { country_code: "CN", city_code: "SWA", en: "Shantou", nl: "Shantou", fr: "Shantou" },
    { country_code: "FR", city_code: "SXB", en: "Strasbourg", nl: "Straatsburg", fr: "Strasbourg" },
    { country_code: "SX", city_code: "SXM", en: "Saint Martin", nl: "Sint Maarten", fr: "Saint Martin" },
    { country_code: "AU", city_code: "SYD", en: "Sydney", nl: "Sydney", fr: "Sydney" },
    { country_code: "JP", city_code: "SYO", en: "Shonai", nl: "Shonai", fr: "Shonai" },
    { country_code: "US", city_code: "SYR", en: "Syracuse", nl: "Syracuse", fr: "Syracuse" },
    { country_code: "CN", city_code: "SYX", en: "Sanya", nl: "Sanya", fr: "Sanya" },
    { country_code: "TR", city_code: "SZF", en: "Samsun", nl: "Samsun", fr: "Samsun" },
    { country_code: "AT", city_code: "SZG", en: "Salzburg", nl: "Salzburg", fr: "Salzburg" },
    { country_code: "CN", city_code: "SZX", en: "Shenzhen", nl: "Shenzhen", fr: "Shenzhen" },
    { country_code: "KR", city_code: "TAE", en: "Daegu", nl: "Daegu", fr: "Daegu" },
    { country_code: "JP", city_code: "TAK", en: "Takamatsu", nl: "Takamatsu", fr: "Takamatsu" },
    { country_code: "MX", city_code: "TAM", en: "Tampico", nl: "Tampico", fr: "Tampico" },
    { country_code: "CN", city_code: "TAO", en: "Qingdao", nl: "Qingdao", fr: "Qingdao" },
    { country_code: "MX", city_code: "TAP", en: "Tapachula", nl: "Tapachula", fr: "Tapachula" },
    { country_code: "UZ", city_code: "TAS", en: "Tashkent", nl: "Tashkent", fr: "Tashkent" },
    { country_code: "TZ", city_code: "TBO", en: "Tabora", nl: "Tabora", fr: "Tabora" },
    { country_code: "GE", city_code: "TBS", en: "Tbilisi", nl: "Tbilisi", fr: "Tbilisi" },
    { country_code: "ES", city_code: "TCI", en: "Tenerife", nl: "Tenerife", fr: "Ténériffe" },
    { country_code: "TH", city_code: "TDX", en: "Trat", nl: "Trat", fr: "Trat" },
    { country_code: "PT", city_code: "TER", en: "Terceira Island", nl: "Terceira Island", fr: "Terceira Island" },
    { country_code: "ME", city_code: "TGD", en: "Podgorica", nl: "Podgorica", fr: "Podgorica" },
    { country_code: "MY", city_code: "TGG", en: "Kuala Terengganu", nl: "Kuala Terengganu", fr: "Kuala Terengganu" },
    { country_code: "HN", city_code: "TGU", en: "Tegucigalpa", nl: "Tegucigalpa", fr: "Tegucigalpa" },
    { country_code: "MX", city_code: "TGZ", en: "Tuxtla Gutierrez", nl: "Tuxtla Gutierrez", fr: "Tuxtla Gutierrez" },
    { country_code: "BR", city_code: "THE", en: "Teresina", nl: "Teresina", fr: "Teresina" },
    { country_code: "IR", city_code: "THR", en: "Tehran", nl: "Teheran", fr: "Téhéran" },
    { country_code: "TH", city_code: "THS", en: "Sukhothai", nl: "Sukhothai", fr: "Sukhothaï" },
    { country_code: "AL", city_code: "TIA", en: "Tirana", nl: "Tirana", fr: "Tirana" },
    { country_code: "MX", city_code: "TIJ", en: "Tijuana", nl: "Tijuana", fr: "Tijuana" },
    { country_code: "RU", city_code: "TJM", en: "Tyumen", nl: "Tyumen", fr: "Tyumen" },
    { country_code: "JP", city_code: "TKS", en: "Tokushima", nl: "Tokushima", fr: "Tokushima" },
    { country_code: "FI", city_code: "TKU", en: "Turku", nl: "Turku", fr: "Turku" },
    { country_code: "US", city_code: "TLH", en: "Tallahassee", nl: "Tallahassee", fr: "Tallahassee" },
    { country_code: "EE", city_code: "TLL", en: "Tallinn", nl: "Tallinn", fr: "Tallinn" },
    { country_code: "FR", city_code: "TLN", en: "Toulon", nl: "Toulon", fr: "Toulon" },
    { country_code: "FR", city_code: "TLS", en: "Toulouse", nl: "Toulouse", fr: "Toulouse" },
    { country_code: "IL", city_code: "TLV", en: "Tel Aviv", nl: "Tel Aviv", fr: "Tel Aviv-Jaffa" },
    { country_code: "FI", city_code: "TMP", en: "Tampere", nl: "Tampere", fr: "Tampere" },
    { country_code: "CN", city_code: "TNA", en: "Jinan", nl: "Jinan", fr: "Jinan" },
    { country_code: "MG", city_code: "TNR", en: "Antananarivo", nl: "Antananarivo", fr: "Antananarivo" },
    { country_code: "US", city_code: "TOL", en: "Toledo", nl: "Toledo", fr: "Tolède" },
    { country_code: "NO", city_code: "TOS", en: "Tromso", nl: "Tromso", fr: "Tromso" },
    { country_code: "VG", city_code: "TOV", en: "Tortola", nl: "Tortola", fr: "Tortola" },
    { country_code: "JP", city_code: "TOY", en: "Toyama", nl: "Toyama", fr: "Toyama" },
    { country_code: "US", city_code: "TPA", en: "Tampa", nl: "Tampa", fr: "Tampa" },
    { country_code: "TW", city_code: "TPE", en: "Taipei", nl: "Taipei", fr: "Taipei" },
    { country_code: "PE", city_code: "TPP", en: "Tarapoto", nl: "Tarapoto", fr: "Tarapoto" },
    { country_code: "MX", city_code: "TRC", en: "Torreon", nl: "Torreon", fr: "Torreón" },
    { country_code: "NO", city_code: "TRD", en: "Trondheim", nl: "Trondheim", fr: "Trondheim" },
    { country_code: "NZ", city_code: "TRG", en: "Tauranga", nl: "Tauranga", fr: "Tauranga" },
    { country_code: "US", city_code: "TRI", en: "Tri-Cities", nl: "Tri-Cities", fr: "Tri-Cities" },
    { country_code: "IT", city_code: "TRN", en: "Turin", nl: "Turijn", fr: "Turin" },
    { country_code: "IT", city_code: "TRS", en: "Trieste", nl: "Triëst", fr: "Trieste" },
    { country_code: "PE", city_code: "TRU", en: "Trujillo", nl: "Trujillo", fr: "Trujillo" },
    { country_code: "IN", city_code: "TRV", en: "Thiruvananthapuram", nl: "Thiruvananthapuram", fr: "Thiruvananthapuram" },
    { country_code: "KZ", city_code: "TSE", en: "Astana", nl: "Astana", fr: "Astana/Nur-Sultan" },
    { country_code: "CN", city_code: "TSN", en: "Tianjin", nl: "Tianjin", fr: "Tianjin" },
    { country_code: "RO", city_code: "TSR", en: "Timisoara", nl: "Timisoara", fr: "Timisoara" },
    { country_code: "FR", city_code: "TUF", en: "Tours", nl: "Tours", fr: "Tours" },
    { country_code: "US", city_code: "TUL", en: "Tulsa", nl: "Tulsa", fr: "Tulsa" },
    { country_code: "TN", city_code: "TUN", en: "Tunis", nl: "Tunis", fr: "Tunis" },
    { country_code: "NZ", city_code: "TUO", en: "Taupo", nl: "Taupo", fr: "Taupo" },
    { country_code: "US", city_code: "TUS", en: "Tucson", nl: "Tucson", fr: "Tucson" },
    { country_code: "US", city_code: "TVC", en: "Traverse City", nl: "Traverse City", fr: "Traverse City" },
    { country_code: "US", city_code: "TVF", en: "Thief River Falls", nl: "Thief River Falls", fr: "Thief River Falls" },
    { country_code: "MY", city_code: "TWU", en: "Tawau", nl: "Tawau", fr: "Tawau" },
    { country_code: "CN", city_code: "TXN", en: "Huangshan", nl: "Huangshan", fr: "Huangshan" },
    { country_code: "CN", city_code: "TYN", en: "Taiyuan", nl: "Taiyuan", fr: "Taiyuan" },
    { country_code: "JP", city_code: "TYO", en: "Tokyo", nl: "Tokio", fr: "Tokyo" },
    { country_code: "US", city_code: "TYS", en: "Knoxville", nl: "Knoxville", fr: "Knoxville" },
    { country_code: "TR", city_code: "TZX", en: "Trabzon", nl: "Trabzon", fr: "Trabzon" },
    { country_code: "JP", city_code: "UBJ", en: "Ube", nl: "Ube", fr: "Ube" },
    { country_code: "US", city_code: "UBS", en: "Columbus, MS", nl: "Columbus, MS", fr: "Columbus, MS" },
    { country_code: "IN", city_code: "UDR", en: "Udaipur", nl: "Udaipur", fr: "Udaipur" },
    { country_code: "RU", city_code: "UFA", en: "Ufa", nl: "Ufa", fr: "Ufa" },
    { country_code: "EC", city_code: "UIO", en: "Quito", nl: "Quito", fr: "Quito" },
    { country_code: "FR", city_code: "UIP", en: "Quimper", nl: "Quimper", fr: "Quimper" },
    { country_code: "ID", city_code: "UPG", en: "Ujung Pandang", nl: "Ujung Pandang", fr: "Makassar" },
    { country_code: "KZ", city_code: "URA", en: "Uralsk", nl: "Uralsk", fr: "Uralsk" },
    { country_code: "CN", city_code: "URC", en: "Urumqi", nl: "Urumqi", fr: "Ürümqi" },
    { country_code: "AR", city_code: "USH", en: "Ushuaia", nl: "Ushuaia", fr: "Ushuaïa" },
    { country_code: "TH", city_code: "USM", en: "Koh Samui", nl: "Koh Samui", fr: "Koh Samui" },
    { country_code: "KR", city_code: "USN", en: "Ulsan", nl: "Ulsan", fr: "Ulsan" },
    { country_code: "TH", city_code: "UTH", en: "Udon Thani", nl: "Udon Thani", fr: "Udon Thani" },
    { country_code: "RU", city_code: "UUS", en: "Yuzhno Sakhalinsk", nl: "Yuzhno Sakhalinsk", fr: "Yuzhno Sakhalinsk" },
    { country_code: "FI", city_code: "VAA", en: "Vaasa", nl: "Vaasa", fr: "Vaasa" },
    { country_code: "FR", city_code: "VAF", en: "Valence", nl: "Valence", fr: "Valence" },
    { country_code: "TR", city_code: "VAN", en: "Van", nl: "Van", fr: "Van" },
    { country_code: "TR", city_code: "VAS", en: "Sivas", nl: "Sivas", fr: "Sivas" },
    { country_code: "SE", city_code: "VBY", en: "Visby", nl: "Visby", fr: "Visby" },
    { country_code: "IT", city_code: "VCE", en: "Venice", nl: "Venetië", fr: "Venise" },
    { country_code: "MX", city_code: "VER", en: "Veracruz", nl: "Veracruz", fr: "Veracruz" },
    { country_code: "ZW", city_code: "VFA", en: "Victoria Falls", nl: "Victoria Falls", fr: "Victoria Falls" },
    { country_code: "ES", city_code: "VGO", en: "Vigo", nl: "Vigo", fr: "Vigo" },
    { country_code: "SE", city_code: "VHM", en: "Vilhelmina", nl: "Vilhelmina", fr: "Vilhelmina" },
    { country_code: "AT", city_code: "VIE", en: "Vienna", nl: "Wenen", fr: "Vienne" },
    { country_code: "BR", city_code: "VIX", en: "Vitoria", nl: "Vitoria", fr: "Vitória" },
    { country_code: "ES", city_code: "VLC", en: "Valencia", nl: "Valencia", fr: "Valence" },
    { country_code: "US", city_code: "VLD", en: "Valdosta", nl: "Valdosta", fr: "Valdosta" },
    { country_code: "VE", city_code: "VLN", en: "Valencia", nl: "Valencia", fr: "Valencia" },
    { country_code: "LT", city_code: "VNO", en: "Vilnius", nl: "Vilnius", fr: "Vilnius" },
    { country_code: "IN", city_code: "VNS", en: "Varanasi", nl: "Varanasi", fr: "Varanasi" },
    { country_code: "RU", city_code: "VOG", en: "Volgograd", nl: "Volgograd", fr: "Volgograd" },
    { country_code: "RU", city_code: "VOZ", en: "Voroznezh", nl: "Voroznezh", fr: "Voroznezh" },
    { country_code: "US", city_code: "VPS", en: "Valparaiso/Ft Walton", nl: "Valparaiso/Fort Walton", fr: "Valparaíso/Fort Walton" },
    { country_code: "IT", city_code: "VRN", en: "Verona", nl: "Verona", fr: "Vérone" },
    { country_code: "MX", city_code: "VSA", en: "Villahermosa", nl: "Villahermosa", fr: "Villahermosa" },
    { country_code: "LA", city_code: "VTE", en: "Vientiane", nl: "Vientiane", fr: "Vientiane" },
    { country_code: "RU", city_code: "VVO", en: "Vladivostok", nl: "Vladivostok", fr: "Vladivostok" },
    { country_code: "US", city_code: "WAS", en: "Washington", nl: "Washington", fr: "Washington" },
    { country_code: "PL", city_code: "WAW", en: "Warsaw", nl: "Warschau", fr: "Varsovie" },
    { country_code: "NA", city_code: "WDH", en: "Windhoek", nl: "Windhoek", fr: "Windhoek" },
    { country_code: "CN", city_code: "WEH", en: "Weihai", nl: "Weihai", fr: "Weihai" },
    { country_code: "NZ", city_code: "WLG", en: "Wellington", nl: "Wellington", fr: "Wellington" },
    { country_code: "WF", city_code: "WLS", en: "Wallis Island", nl: "Wallis Island", fr: "Wallis Island" },
    { country_code: "CN", city_code: "WNZ", en: "Wenzhou", nl: "Wenzhou", fr: "Wenzhou" },
    { country_code: "NZ", city_code: "WRE", en: "Whangarei", nl: "Whangarei", fr: "Whangarei" },
    { country_code: "CN", city_code: "WUH", en: "Wuhan", nl: "Wuhan", fr: "Wuhan" },
    { country_code: "CN", city_code: "WUS", en: "Wuyishan", nl: "Wuyishan", fr: "Wuyishan" },
    { country_code: "CN", city_code: "WUX", en: "Wuxi", nl: "Wuxi", fr: "Wuxi" },
    { country_code: "NA", city_code: "WVB", en: "Walvis Bay", nl: "Walvis Bay", fr: "Walvis Bay" },
    { country_code: "CN", city_code: "XMN", en: "Xiamen", nl: "Xiamen", fr: "Xiamen" },
    { country_code: "CN", city_code: "XNN", en: "Xining", nl: "Xining", fr: "Xining" },
    { country_code: "CA", city_code: "YAM", en: "Sault Ste Marie", nl: "Sault Ste Marie", fr: "Sault Ste Marie" },
    { country_code: "CM", city_code: "YAO", en: "Yaounde", nl: "Yaoundé", fr: "Yaoundé" },
    { country_code: "CA", city_code: "YBR", en: "Brandon", nl: "Brandon", fr: "Brandon" },
    { country_code: "CA", city_code: "YCD", en: "Nanaimo", nl: "Nanaimo", fr: "Nanaimo" },
    { country_code: "CN", city_code: "YDD", en: "Yingkou", nl: "Yingkou", fr: "Yingkou" },
    { country_code: "CA", city_code: "YDF", en: "Deer Lake", nl: "Deer Lake", fr: "Deer Lake" },
    { country_code: "CA", city_code: "YEA", en: "Edmonton", nl: "Edmonton", fr: "Edmonton" },
    { country_code: "CA", city_code: "YFC", en: "Fredericton", nl: "Fredericton", fr: "Fredericton" },
    { country_code: "JP", city_code: "YGJ", en: "Yonago", nl: "Yonago", fr: "Yonago" },
    { country_code: "CA", city_code: "YGK", en: "Kingston", nl: "Kingston", fr: "Kingston" },
    { country_code: "CA", city_code: "YHZ", en: "Halifax", nl: "Halifax", fr: "Halifax" },
    { country_code: "CN", city_code: "YIH", en: "Yichang", nl: "Yichang", fr: "Yichang" },
    { country_code: "CN", city_code: "YIW", en: "Yiwu", nl: "Yiwu", fr: "Yiwu" },
    { country_code: "CA", city_code: "YKA", en: "Kamloops", nl: "Kamloops", fr: "Kamloops" },
    { country_code: "US", city_code: "YKM", en: "Yakima", nl: "Yakima", fr: "Yakima" },
    { country_code: "CA", city_code: "YLW", en: "Kelowna", nl: "Kelowna", fr: "Kelowna" },
    { country_code: "CA", city_code: "YMM", en: "Fort Mc Murray", nl: "Fort Mc Murray", fr: "Fort Mc Murray" },
    { country_code: "CA", city_code: "YMQ", en: "Montreal", nl: "Montreal", fr: "Montréal" },
    { country_code: "CN", city_code: "YNJ", en: "Yanji", nl: "Yanji", fr: "Yanji" },
    { country_code: "CA", city_code: "YOW", en: "Ottawa", nl: "Ottawa", fr: "Ottawa" },
    { country_code: "CA", city_code: "YPR", en: "Prince Rupert", nl: "Prince Rupert", fr: "Prince Rupert" },
    { country_code: "CA", city_code: "YQB", en: "Quebec", nl: "Quebec", fr: "Québec" },
    { country_code: "CA", city_code: "YQG", en: "Windsor", nl: "Windsor", fr: "Windsor" },
    { country_code: "CA", city_code: "YQL", en: "Lethbridge", nl: "Lethbridge", fr: "Lethbridge" },
    { country_code: "CA", city_code: "YQM", en: "Moncton", nl: "Moncton", fr: "Moncton" },
    { country_code: "CA", city_code: "YQQ", en: "Comox", nl: "Comox", fr: "Comox" },
    { country_code: "CA", city_code: "YQR", en: "Regina", nl: "Regina", fr: "Regina" },
    { country_code: "CA", city_code: "YQT", en: "Thunder Bay", nl: "Thunder Bay", fr: "Thunder Bay" },
    { country_code: "CA", city_code: "YQU", en: "Grande Prairie", nl: "Grande Prairie", fr: "Grande Prairie" },
    { country_code: "CA", city_code: "YQY", en: "Sydney (Canada)", nl: "Sydney (Canada)", fr: "Sydney (Canada)" },
    { country_code: "CA", city_code: "YSB", en: "Sudbury", nl: "Sudbury", fr: "Sudbury" },
    { country_code: "CA", city_code: "YTO", en: "Toronto", nl: "Toronto", fr: "Toronto" },
    { country_code: "CA", city_code: "YTS", en: "Timmins", nl: "Timmins", fr: "Timmins" },
    { country_code: "CA", city_code: "YUY", en: "Rouyn", nl: "Rouyn", fr: "Rouyn" },
    { country_code: "KM", city_code: "YVA", en: "Moroni", nl: "Moroni", fr: "Moroni" },
    { country_code: "CA", city_code: "YVO", en: "Val D'or", nl: "Val D'or", fr: "Val D'or" },
    { country_code: "CA", city_code: "YVR", en: "Vancouver", nl: "Vancouver", fr: "Vancouver" },
    { country_code: "CA", city_code: "YWG", en: "Winnipeg", nl: "Winnipeg", fr: "Winnipeg" },
    { country_code: "CA", city_code: "YXC", en: "Cranbrook", nl: "Cranbrook", fr: "Cranbrook" },
    { country_code: "CA", city_code: "YXE", en: "Saskatoon", nl: "Saskatoon", fr: "Saskatoon" },
    { country_code: "CA", city_code: "YXJ", en: "Fort St John", nl: "Fort St John", fr: "Fort St John" },
    { country_code: "CA", city_code: "YXS", en: "Prince George", nl: "Prince George", fr: "Prince George" },
    { country_code: "CA", city_code: "YXT", en: "Terrace", nl: "Terrace", fr: "Terrace" },
    { country_code: "CA", city_code: "YXU", en: "London", nl: "London", fr: "Londres" },
    { country_code: "CA", city_code: "YXX", en: "Abbotsford", nl: "Abbotsford", fr: "Abbotsford" },
    { country_code: "CA", city_code: "YXY", en: "Whitehorse", nl: "Whitehorse", fr: "Whitehorse" },
    { country_code: "CA", city_code: "YYB", en: "North Bay", nl: "North Bay", fr: "North Bay" },
    { country_code: "CA", city_code: "YYC", en: "Calgary", nl: "Calgary", fr: "Calgary" },
    { country_code: "CA", city_code: "YYD", en: "Smithers", nl: "Smithers", fr: "Smithers" },
    { country_code: "CA", city_code: "YYF", en: "Penticton", nl: "Penticton", fr: "Penticton" },
    { country_code: "CA", city_code: "YYG", en: "Charlottetown", nl: "Charlottetown", fr: "Charlottetown" },
    { country_code: "CA", city_code: "YYJ", en: "Victoria", nl: "Victoria", fr: "Victoria" },
    { country_code: "CA", city_code: "YYT", en: "Saint Johns", nl: "Saint Johns", fr: "Saint Johns" },
    { country_code: "CA", city_code: "YZF", en: "Yellowknife", nl: "Yellowknife", fr: "Yellowknife" },
    { country_code: "CA", city_code: "YZR", en: "Sarnia", nl: "Sarnia", fr: "Sarnia" },
    { country_code: "CA", city_code: "YZV", en: "Sept Iles", nl: "Sept Iles", fr: "Sept Iles" },
    { country_code: "HR", city_code: "ZAD", en: "Zadar", nl: "Zadar", fr: "Zadar" },
    { country_code: "HR", city_code: "ZAG", en: "Zagreb", nl: "Zagreb", fr: "Zagreb" },
    { country_code: "CA", city_code: "ZBF", en: "Bathurst", nl: "Bathurst", fr: "Bathurst" },
    { country_code: "MX", city_code: "ZIH", en: "Ixtapa Zihuatanej", nl: "Ixtapa Zihuatanej", fr: "Ixtapa Zihuatanej" },
    { country_code: "TZ", city_code: "ZNZ", en: "Zanzibar", nl: "Zanzibar", fr: "Zanzibar" },
    { country_code: "NZ", city_code: "ZQN", en: "Queenstown", nl: "Queenstown", fr: "Queenstown" },
    { country_code: "CH", city_code: "ZRH", en: "Zurich", nl: "Zurich", fr: "Zurich" },
    { country_code: "CN", city_code: "ZUH", en: "Zhuhai", nl: "Zhuhai", fr: "Zhuhai" },
    { country_code: "NL", city_code: "ZYM", en: "Arnhem", nl: "Arnhem", fr: "Arnhem" },
];

// prettier-ignore
export const DESTINATIONS_OBJECT = {
  AAL: { en: "Aalborg", nl: "Aalborg", fr: "Aalborg" },
  ABE: { en: "Allentown", nl: "Allentown", fr: "Allentown" },
  ABJ: { en: "Abidjan", nl: "Abidjan", fr: "Abidjan" },
  ABQ: { en: "Albuquerque", nl: "Albuquerque", fr: "Albuquerque" },
  ABV: { en: "Abuja", nl: "Abuja", fr: "Abuja" },
  ABY: { en: "Albany, GA", nl: "Albany, GA", fr: "Albany, GA" },
  ABZ: { en: "Aberdeen", nl: "Aberdeen", fr: "Aberdeen" },
  ACA: { en: "Acapulco", nl: "Acapulco", fr: "Acapulco" },
  ACC: { en: "Accra", nl: "Accra", fr: "Accra" },
  ACE: { en: "Lanzarote", nl: "Lanzarote", fr: "Lanzarote" },
  ADD: { en: "Addis Ababa", nl: "Addis Ababa", fr: "Addis-Abeba" },
  ADL: { en: "Adelaide", nl: "Adelaide", fr: "Adélaïde" },
  ADZ: { en: "San Andres Island", nl: "San Andres Island", fr: "San Andres Island" },
  AER: { en: "Sochi Intl.", nl: "Sochi Intl.", fr: "Sotchi Int." },
  AES: { en: "Alesund", nl: "Alesund", fr: "Alesund" },
  AEX: { en: "Alexandria", nl: "Alexandria", fr: "Alexandrie" },
  AGA: { en: "Agadir", nl: "Agadir", fr: "Agadir" },
  AGF: { en: "Agen", nl: "Agen", fr: "Agen" },
  AGP: { en: "Malaga", nl: "Malaga", fr: "Malaga" },
  AGS: { en: "Augusta", nl: "Augusta", fr: "Augusta" },
  AHO: { en: "Alghero", nl: "Alghero", fr: "Alghero" },
  AJA: { en: "Ajaccio", nl: "Ajaccio", fr: "Ajaccio" },
  AJR: { en: "Arvidsjaur", nl: "Arvidsjaur", fr: "Arvidsjaur" },
  AJU: { en: "Aracaju", nl: "Aracaju", fr: "Aracaju" },
  AKJ: { en: "Asahikawa", nl: "Asahikawa", fr: "Asahikawa" },
  AKL: { en: "Auckland", nl: "Auckland", fr: "Auckland" },
  ALA: { en: "Almaty", nl: "Alma-Ata", fr: "Almaty" },
  ALB: { en: "Albany, NY", nl: "Albany, NY", fr: "Albany, NY" },
  ALC: { en: "Alicante", nl: "Alicante", fr: "Alicante" },
  ALG: { en: "Algiers", nl: "Algiers", fr: "Alger" },
  ALW: { en: "Walla Walla", nl: "Walla Walla", fr: "Walla Walla" },
  AMA: { en: "Amarillo", nl: "Amarillo", fr: "Amarillo" },
  AMD: { en: "Ahmedabad", nl: "Ahmedabad", fr: "Ahmedabad" },
  AMM: { en: "Amman", nl: "Amman", fr: "Amman" },
  AMS: { en: "Amsterdam", nl: "Amsterdam", fr: "Amsterdam" },
  ANC: { en: "Anchorage", nl: "Anchorage", fr: "Anchorage" },
  ANE: { en: "Angers", nl: "Angers", fr: "Angers" },
  ANF: { en: "Antofagasta", nl: "Antofagasta", fr: "Antofagasta" },
  ANK: { en: "Ankara", nl: "Ankara", fr: "Ankara" },
  ANR: { en: "Antwerp", nl: "Antwerpen", fr: "Anvers" },
  AOI: { en: "Ancona", nl: "Ancona", fr: "Ancône" },
  AOJ: { en: "Aomori", nl: "Aomori", fr: "Aomori" },
  AOR: { en: "Alor Setar", nl: "Alor Setar", fr: "Alor Setar" },
  APL: { en: "Nampula", nl: "Nampula", fr: "Nampula" },
  AQP: { en: "Arequipa", nl: "Arequipa", fr: "Arequipa" },
  ARH: { en: "Arkhangelsk", nl: "Arkhangelsk", fr: "Arkhangelsk" },
  ARI: { en: "Arica", nl: "Arica", fr: "Arica" },
  ASR: { en: "Kayseri", nl: "Kayseri", fr: "Kayseri" },
  ASU: { en: "Asunción", nl: "Asunción", fr: "Asunción" },
  ATH: { en: "Athens", nl: "Athene", fr: "Athènes" },
  ATL: { en: "Atlanta", nl: "Atlanta", fr: "Atlanta" },
  ATQ: { en: "Amritsar", nl: "Amritsar", fr: "Amritsar" },
  ATW: { en: "Appleton", nl: "Appleton", fr: "Appleton" },
  AUA: { en: "Aruba", nl: "Aruba", fr: "Aruba" },
  AUH: { en: "Abu Dhabi", nl: "Abu Dhabi", fr: "Abu Dhabi" },
  AUR: { en: "Aurillac", nl: "Aurillac", fr: "Aurillac" },
  AUS: { en: "Austin", nl: "Austin", fr: "Austin" },
  AUW: { en: "Wausau", nl: "Wausau", fr: "Wausau" },
  AVL: { en: "Asheville", nl: "Asheville", fr: "Asheville" },
  AVN: { en: "Avignon", nl: "Avignon", fr: "Avignon" },
  AVP: { en: "Scranton", nl: "Scranton", fr: "Scranton" },
  AXD: { en: "Alexandroupolis", nl: "Alexandroupolis", fr: "Alexandroupolis" },
  AXM: { en: "Armenia", nl: "Armenia", fr: "Armenia" },
  AXT: { en: "Akita", nl: "Akita", fr: "Akita" },
  AYT: { en: "Antalya", nl: "Antalya", fr: "Antalya" },
  AZO: { en: "Kalamazoo", nl: "Kalamazoo", fr: "Kalamazoo" },
  BAH: { en: "Bahrain", nl: "Bahrein", fr: "Bahreïn" },
  BAK: { en: "Baku", nl: "Baku", fr: "Bakou" },
  BAL: { en: "Batman", nl: "Batman", fr: "Batman" },
  BAQ: { en: "Barranquilla", nl: "Barranquilla", fr: "Barranquilla" },
  BAX: { en: "Barnaul", nl: "Barnaul", fr: "Barnaul" },
  BBI: { en: "Bhubaneswar", nl: "Bhubaneswar", fr: "Bhubaneswar" },
  BCN: { en: "Barcelona", nl: "Barcelona", fr: "Barcelone" },
  BDA: { en: "Bermuda", nl: "Bermuda", fr: "Bermuda" },
  BDJ: { en: "Banjarmasin", nl: "Banjarmasin", fr: "Banjarmasin" },
  BDQ: { en: "Vadodara", nl: "Vadodara", fr: "Vadodara" },
  BDS: { en: "Brindisi", nl: "Brindisi", fr: "Brindisi" },
  BEG: { en: "Belgrade", nl: "Belgrado", fr: "Belgrade" },
  BEL: { en: "Belem", nl: "Belem", fr: "Belem" },
  BER: { en: "Berlin", nl: "Berlijn", fr: "Berlin" },
  BES: { en: "Brest", nl: "Brest", fr: "Brest" },
  BEY: { en: "Beirut", nl: "Beiroet", fr: "Beyrouth" },
  BFN: { en: "Bloemfontein", nl: "Bloemfontein", fr: "Bloemfontein" },
  BFS: { en: "Belfast", nl: "Belfast", fr: "Belfast" },
  BGA: { en: "Bucaramanga", nl: "Bucaramanga", fr: "Bucaramanga" },
  BGF: { en: "Bangui", nl: "Bangui", fr: "Bangui" },
  BGM: { en: "Binghamton", nl: "Binghamton", fr: "Binghamton" },
  BGO: { en: "Bergen", nl: "Bergen", fr: "Bergen" },
  BGR: { en: "Bangor", nl: "Bangor", fr: "Bangor" },
  BHE: { en: "Blenheim", nl: "Blenheim", fr: "Blenheim" },
  BHM: { en: "Birmingham", nl: "Birmingham, AL", fr: "Birmingham, AL" },
  BHO: { en: "Bhopal", nl: "Bhopal", fr: "Bhopal" },
  BHX: { en: "Birmingham", nl: "Birmingham", fr: "Birmingham" },
  BHZ: { en: "Belo Horizonte", nl: "Belo Horizonte", fr: "Belo Horizonte" },
  BIA: { en: "Bastia", nl: "Bastia", fr: "Bastia" },
  BIL: { en: "Billings", nl: "Billings", fr: "Billings" },
  BIO: { en: "Bilbao", nl: "Bilbao", fr: "Bilbao" },
  BIQ: { en: "Biarritz", nl: "Biarritz", fr: "Biarritz" },
  BIS: { en: "Bismarck", nl: "Bismarck", fr: "Bismarck" },
  BJI: { en: "Bemidji", nl: "Bemidji", fr: "Bemidji" },
  BJM: { en: "Bujumbura", nl: "Bujumbura", fr: "Bujumbura" },
  BJS: { en: "Beijing", nl: "Beijing", fr: "Pékin" },
  BJX: { en: "Leon-Guanajuato", nl: "Leon-Guanajuato", fr: "León-Guanajuato" },
  BKI: { en: "Kota Kinabalu", nl: "Kota Kinabalu", fr: "Kota Kinabalu" },
  BKK: { en: "Bangkok", nl: "Bangkok", fr: "Bangkok" },
  BKO: { en: "Bamako", nl: "Bamako", fr: "Bamako" },
  BLI: { en: "Bellingham", nl: "Bellingham", fr: "Bellingham" },
  BLL: { en: "Billund", nl: "Billund", fr: "Billund" },
  BLQ: { en: "Bologna", nl: "Bologna", fr: "Bologne" },
  BLR: { en: "Bangalore", nl: "Bangalore", fr: "Bangalore" },
  BLZ: { en: "Blantyre", nl: "Blantyre", fr: "Blantyre" },
  BMI: { en: "Bloomington", nl: "Bloomington", fr: "Bloomington" },
  BNA: { en: "Nashville", nl: "Nashville", fr: "Nashville" },
  BNE: { en: "Brisbane", nl: "Brisbane", fr: "Brisbane" },
  BNN: { en: "Bronnoysund", nl: "Bronnoysund", fr: "Bronnoysund" },
  BOD: { en: "Bordeaux", nl: "Bordeaux", fr: "Bordeaux" },
  BOG: { en: "Bogota", nl: "Bogota", fr: "Bogota" },
  BOI: { en: "Boise", nl: "Boise", fr: "Boise" },
  BOM: { en: "Mumbai/Bombay", nl: "Mumbai/Bombay", fr: "Mumbai" },
  BON: { en: "Bonaire", nl: "Bonaire", fr: "Bonaire" },
  BOO: { en: "Bodo", nl: "Bodo", fr: "Bodø" },
  BOS: { en: "Boston", nl: "Boston", fr: "Boston" },
  BPN: { en: "Balikpapan", nl: "Balikpapan", fr: "Balikpapan" },
  BPS: { en: "Porto Seguro", nl: "Porto Seguro", fr: "Porto Seguro" },
  BRC: { en: "Bariloche", nl: "Bariloche", fr: "Bariloche" },
  BRD: { en: "Brainerd", nl: "Brainerd", fr: "Brainerd" },
  BRE: { en: "Bremen", nl: "Bremen", fr: "Brême" },
  BRI: { en: "Bari", nl: "Bari", fr: "Bari" },
  BRN: { en: "Bern", nl: "Bern", fr: "Berne" },
  BRS: { en: "Bristol", nl: "Bristol", fr: "Bristol" },
  BRU: { en: "Brussels", nl: "Brussel", fr: "Bruxelles" },
  BSB: { en: "Brasilia", nl: "Brasilia", fr: "Brasilia" },
  BSG: { en: "Bata", nl: "Bata", fr: "Bata" },
  BTH: { en: "Batam (Batu Besar)", nl: "Batam (Batu Besar)", fr: "Batam (Batu Besar)" },
  BTR: { en: "Baton Rouge", nl: "Baton Rouge", fr: "Baton Rouge" },
  BTU: { en: "Bintulu", nl: "Bintulu", fr: "Bintulu" },
  BTV: { en: "Burlington", nl: "Burlington", fr: "Burlington" },
  BUD: { en: "Budapest", nl: "Boedapest", fr: "Budapest" },
  BUE: { en: "Buenos Aires", nl: "Buenos Aires", fr: "Buenos Aires" },
  BUF: { en: "Buffalo", nl: "Buffalo", fr: "Buffalo" },
  BUH: { en: "Bucharest", nl: "Boekarest", fr: "Bucarest" },
  BUQ: { en: "Bulawayo", nl: "Bulawayo", fr: "Bulawayo" },
  BUR: { en: "Burbank", nl: "Burbank", fr: "Burbank" },
  BVB: { en: "Boa Vista", nl: "Boa Vista", fr: "Boa Vista" },
  BVE: { en: "Brive la Gaillarde", nl: "Brive la Gaillarde", fr: "Brive la Gaillarde" },
  BWN: { en: "Bandar Seri Begawan", nl: "Bandar Seri Begawan", fr: "Bandar Seri Begawan" },
  BXN: { en: "Bodrum", nl: "Bodrum", fr: "Bodrum" },
  BZE: { en: "Belize City", nl: "Belize City", fr: "Belize City" },
  BZN: { en: "Bozeman", nl: "Bozeman", fr: "Bozeman" },
  BZV: { en: "Brazzaville", nl: "Brazzaville", fr: "Brazzaville" },
  CAE: { en: "Columbia, SC", nl: "Columbia, SC", fr: "Columbia, SC" },
  CAG: { en: "Cagliari", nl: "Cagliari", fr: "Cagliari" },
  CAI: { en: "Cairo", nl: "Caïro", fr: "Le Caire" },
  CAK: { en: "Akron", nl: "Akron", fr: "Akron" },
  CAN: { en: "Guangzhou", nl: "Guangzhou", fr: "Canton" },
  CAS: { en: "Casablanca", nl: "Casablanca", fr: "Casablanca" },
  CAY: { en: "Cayenne", nl: "Cayenne", fr: "Cayenne" },
  CCJ: { en: "Kozhikode", nl: "Kozhikode", fr: "Kozhikode" },
  CCS: { en: "Caracas", nl: "Caracas", fr: "Caracas" },
  CCU: { en: "Kolkata", nl: "Kolkata", fr: "Kolkata/Calcutta" },
  CEB: { en: "Cebu", nl: "Cebu", fr: "Cebu" },
  CEI: { en: "Chiang Rai", nl: "Chiang Rai", fr: "Chiang Rai" },
  CEK: { en: "Chelyabinsk", nl: "Chelyabinsk", fr: "Chelyabinsk" },
  CEN: { en: "Ciudad Obregon", nl: "Ciudad Obregon", fr: "Ciudad Obregon" },
  CFE: { en: "Clermont-Ferrand", nl: "Clermont-Ferrand", fr: "Clermont-Ferrand" },
  CFR: { en: "Caen", nl: "Caen", fr: "Caen" },
  CFU: { en: "Kerkyra Corfu", nl: "Kerkyra Corfu", fr: "Kerkyra Corfu" },
  CGB: { en: "Cuiaba", nl: "Cuiaba", fr: "Cuiabá" },
  CGO: { en: "Zhengzhou", nl: "Zhengzhou", fr: "Zhengzhou" },
  CGQ: { en: "Changchun", nl: "Changchun", fr: "Changchun" },
  CGR: { en: "Campo Grande", nl: "Campo Grande", fr: "Campo Grande" },
  CHA: { en: "Chattanooga", nl: "Chattanooga", fr: "Chattanooga" },
  CHC: { en: "Christchurch", nl: "Christchurch", fr: "Christchurch" },
  CHI: { en: "Chicago", nl: "Chicago", fr: "Chicago" },
  CHO: { en: "Charlottesville", nl: "Charlottesville", fr: "Charlottesville" },
  CHQ: { en: "Chania", nl: "Chania", fr: "Chania" },
  CHS: { en: "Charleston, SC", nl: "Charleston, SC", fr: "Charleston, SC" },
  CID: { en: "Cedar Rapids", nl: "Cedar Rapids", fr: "Cedar Rapids" },
  CIT: { en: "Shimkent", nl: "Shimkent", fr: "Shimkent" },
  CJB: { en: "Coimbatore", nl: "Coimbatore", fr: "Coimbatore" },
  CJC: { en: "Calama", nl: "Calama", fr: "Calama" },
  CJS: { en: "Ciudad Juarez", nl: "Ciudad Juarez", fr: "Ciudad de Juárez" },
  CJU: { en: "Jeju", nl: "Jeju", fr: "Jeju" },
  CKG: { en: "Chongqing", nl: "Chongqing", fr: "Chongqing" },
  CKY: { en: "Conakry", nl: "Conakry", fr: "Conakry" },
  CLE: { en: "Cleveland", nl: "Cleveland", fr: "Cleveland" },
  CLJ: { en: "Cluj", nl: "Cluj", fr: "Cluj" },
  CLO: { en: "Cali", nl: "Cali", fr: "Cali" },
  CLT: { en: "Charlotte", nl: "Charlotte", fr: "Charlotte" },
  CLY: { en: "Calvi", nl: "Calvi", fr: "Calvi" },
  CMB: { en: "Colombo", nl: "Colombo", fr: "Colombo" },
  CME: { en: "Ciudad Del Carmen", nl: "Ciudad Del Carmen", fr: "Ciudad Del Carmen" },
  CMH: { en: "Columbus, OH", nl: "Columbus, OH", fr: "Columbus, OH" },
  CMI: { en: "Champaign", nl: "Champaign", fr: "Champaign" },
  CNS: { en: "Cairns", nl: "Cairns", fr: "Cairns" },
  CNX: { en: "Chiang Mai", nl: "Chiang Mai", fr: "Chiang Mai" },
  COK: { en: "Kochi", nl: "Kochi", fr: "Kochi" },
  COO: { en: "Cotonou", nl: "Cotonou", fr: "Cotonou" },
  COR: { en: "Cordoba", nl: "Cordoba", fr: "Cordoba" },
  COS: { en: "Colorado Springs", nl: "Colorado Springs", fr: "Colorado Springs" },
  CPH: { en: "Copenhagen", nl: "Kopenhagen", fr: "Copenhague" },
  CPO: { en: "Copiapo", nl: "Copiapo", fr: "Copiapo" },
  CPQ: { en: "Campinas", nl: "Campinas", fr: "Campinas" },
  CPT: { en: "Cape Town", nl: "Kaapstad", fr: "Le Cap" },
  CRW: { en: "Charleston, WV", nl: "Charleston, WV", fr: "Charleston, WV" },
  CSG: { en: "Columbus, GA", nl: "Columbus, GA", fr: "Columbus, GA" },
  CSX: { en: "Changsha", nl: "Changsha", fr: "Changsha" },
  CTA: { en: "Catania", nl: "Catania", fr: "Catane" },
  CTG: { en: "Cartagena", nl: "Cartagena", fr: "Carthagène" },
  CTU: { en: "Chengdu", nl: "Chengdu", fr: "Chengdu" },
  CUC: { en: "Cucuta", nl: "Cucuta", fr: "Cucuta" },
  CUL: { en: "Culiacan", nl: "Culiacan", fr: "Culiacan" },
  CUN: { en: "Cancun", nl: "Cancun", fr: "Cancún" },
  CUR: { en: "Curacao", nl: "Curaçao", fr: "Curaçao" },
  CUU: { en: "Chihuahua", nl: "Chihuahua", fr: "Chihuahua" },
  CUZ: { en: "Cuzco", nl: "Cuzco", fr: "Cuzco" },
  CVG: { en: "Cincinnati", nl: "Cincinnati", fr: "Cincinnati" },
  CWB: { en: "Curitiba", nl: "Curitiba", fr: "Curitiba" },
  CWL: { en: "Cardiff", nl: "Cardiff", fr: "Cardiff" },
  CZM: { en: "Cozumel", nl: "Cozumel", fr: "Cozumel" },
  CZX: { en: "Changzhou", nl: "Changzhou", fr: "Changzhou" },
  DAB: { en: "Daytona Beach", nl: "Daytona Beach", fr: "Daytona Beach" },
  DAC: { en: "Dhaka", nl: "Dhaka", fr: "Dhaka" },
  DAD: { en: "Da Nang", nl: "Da Nang", fr: "Da Nang" },
  DAR: { en: "Dar es Salaam", nl: "Dar es Salaam", fr: "Dar es Salam" },
  DAV: { en: "David", nl: "David", fr: "David" },
  DAY: { en: "Dayton", nl: "Dayton", fr: "Dayton" },
  DBV: { en: "Dubrovnik", nl: "Dubrovnik", fr: "Dubrovnik" },
  DCM: { en: "Castres", nl: "Castres", fr: "Castres" },
  DEL: { en: "Delhi", nl: "Delhi", fr: "Delhi" },
  DEN: { en: "Denver", nl: "Denver", fr: "Denver" },
  DFW: { en: "Dallas", nl: "Dallas", fr: "Dallas" },
  DGO: { en: "Durango", nl: "Durango", fr: "Durango" },
  DHN: { en: "Dothan", nl: "Dothan", fr: "Dothan" },
  DIK: { en: "Dickinson", nl: "Dickinson", fr: "Dickinson" },
  DIY: { en: "Diyarbakir", nl: "Diyarbakir", fr: "Diyarbakir" },
  DKR: { en: "Dakar", nl: "Dakar", fr: "Dakar" },
  DLA: { en: "Douala", nl: "Douala", fr: "Douala" },
  DLC: { en: "Dalian", nl: "Dalian", fr: "Dalian" },
  DLH: { en: "Duluth", nl: "Duluth", fr: "Duluth" },
  DLM: { en: "Dalaman", nl: "Dalaman", fr: "Dalaman" },
  DMM: { en: "Dammam / Dhahran", nl: "Dammam / Dhahran", fr: "Dammam/Dhahran" },
  DNK: { en: "Dnipropetrovsk", nl: "Dnipropetrovsk", fr: "Dnipropetrovsk" },
  DNZ: { en: "Denizli", nl: "Denizli", fr: "Denizli" },
  DOH: { en: "Doha", nl: "Doha", fr: "Doha" },
  DOM: { en: "Dominica", nl: "Dominica", fr: "Dominica" },
  DPS: { en: "Bali", nl: "Bali", fr: "Denpasar-Bali" },
  DRS: { en: "Dresden", nl: "Dresden", fr: "Dresden" },
  DRW: { en: "Darwin", nl: "Darwin", fr: "Darwin" },
  DSM: { en: "Des Moines", nl: "Des Moines", fr: "Des Moines" },
  DTT: { en: "Detroit", nl: "Detroit", fr: "Detroit" },
  DUB: { en: "Dublin", nl: "Dublin", fr: "Dublin" },
  DUD: { en: "Dunedin", nl: "Dunedin", fr: "Dunedin" },
  DUR: { en: "Durban", nl: "Durban", fr: "Durban" },
  DUS: { en: "Dusseldorf", nl: "Dusseldorf", fr: "Düsseldorf" },
  DXB: { en: "Dubai", nl: "Dubai", fr: "Dubaï" },
  DYG: { en: "Zhangjiajie", nl: "Zhangjiajie", fr: "Zhangjiajie" },
  DZA: { en: "Dzaoudzi", nl: "Dzaoudzi", fr: "Dzaoudzi" },
  EAP: { en: "Mulhouse Basel", nl: "Mulhouse Basel", fr: "Mulhouse Basel" },
  EAT: { en: "Wenatchee", nl: "Wenatchee", fr: "Wenatchee" },
  EBB: { en: "Entebbe", nl: "Entebbe", fr: "Entebbe" },
  ECP: { en: "Panama City Florida", nl: "Panama City Florida", fr: "Panama City Florida" },
  EDI: { en: "Edinburgh", nl: "Edinburgh", fr: "Edimbourg" },
  EDL: { en: "Eldoret", nl: "Eldoret", fr: "Eldoret" },
  EIN: { en: "Eindhoven", nl: "Eindhoven", fr: "Eindhoven" },
  EJA: { en: "Barrancabermeja", nl: "Barrancabermeja", fr: "Barrancabermeja" },
  ELM: { en: "Elmira", nl: "Elmira", fr: "Elmira" },
  ELP: { en: "El Paso", nl: "El Paso", fr: "El Paso" },
  ELS: { en: "East London", nl: "Oost-Londen", fr: "Londres Est" },
  ERC: { en: "Erzincan", nl: "Erzincan", fr: "Erzincan" },
  ERI: { en: "Erie", nl: "Erie", fr: "Érié" },
  ERZ: { en: "Erzurum", nl: "Erzurum", fr: "Erzurum" },
  ESC: { en: "Escanaba", nl: "Escanaba", fr: "Escanaba" },
  ETZ: { en: "Metz/Nancy", nl: "Metz/Nancy", fr: "Metz/Nancy" },
  EUG: { en: "Eugene", nl: "Eugene", fr: "Eugene" },
  EUX: { en: "St Eustatius", nl: "St Eustatius", fr: "St Eustatius" },
  EVE: { en: "Harstad-Narvik", nl: "Harstad-Narvik", fr: "Harstad-Narvik" },
  EVN: { en: "Yerevan", nl: "Yerevan", fr: "Erevan" },
  EVV: { en: "Evansville", nl: "Evansville", fr: "Evansville" },
  EWN: { en: "New Bern", nl: "New Bern", fr: "New Bern" },
  EXT: { en: "Exeter", nl: "Exeter", fr: "Exeter" },
  EYW: { en: "Key West", nl: "Key West", fr: "Key West" },
  EZS: { en: "Elazig", nl: "Elazig", fr: "Elazig" },
  FAI: { en: "Fairbanks", nl: "Fairbanks", fr: "Fairbanks" },
  FAO: { en: "Faro", nl: "Faro", fr: "Faro" },
  FAR: { en: "Fargo", nl: "Fargo", fr: "Fargo" },
  FAT: { en: "Fresno", nl: "Fresno", fr: "Fresno" },
  FAY: { en: "Fayetteville, NC", nl: "Fayetteville, NC", fr: "Fayetteville, NC" },
  FBM: { en: "Lubumbashi", nl: "Lubumbashi", fr: "Lubumbashi" },
  FCA: { en: "Kalispell", nl: "Kalispell", fr: "Kalispell" },
  FDF: { en: "Fort de France", nl: "Martinique (Fort de France)", fr: "Fort-de-France" },
  FIH: { en: "Kinshasa", nl: "Kinshasa", fr: "Kinshasa" },
  FLL: { en: "Fort Lauderdale", nl: "Fort Lauderdale", fr: "Fort Lauderdale" },
  FLN: { en: "Florianopolis", nl: "Florianopolis", fr: "Florianópolis" },
  FLR: { en: "Florence", nl: "Florence", fr: "Florence" },
  FMY: { en: "Fort Myers", nl: "Fort Myers", fr: "Fort Myers" },
  FNA: { en: "Freetown", nl: "Freetown", fr: "Freetown" },
  FNC: { en: "Funchal", nl: "Funchal", fr: "Funchal" },
  FNI: { en: "Nimes", nl: "Nimes", fr: "Nimes" },
  FNT: { en: "Flint", nl: "Flint", fr: "Flint" },
  FOC: { en: "Fuzhou", nl: "Fuzhou", fr: "Fuzhou" },
  FOD: { en: "Fort Dodge", nl: "Fort Dodge", fr: "Fort Dodge" },
  FOR: { en: "Fortaleza", nl: "Fortaleza", fr: "Fortaleza" },
  FRA: { en: "Frankfurt/Main", nl: "Frankfurt/Main", fr: "Francfort-sur-le-Main" },
  FRU: { en: "Bishkek", nl: "Bishkek", fr: "Bishkek" },
  FSC: { en: "Figari", nl: "Figari", fr: "Figari" },
  FSD: { en: "Sioux Falls", nl: "Sioux Falls", fr: "Sioux Fall" },
  FSM: { en: "Fort Smith", nl: "Fort Smith", fr: "Fort Smith" },
  FSZ: { en: "Shizuoka", nl: "Shizuoka", fr: "Shizuoka" },
  FTE: { en: "El Calafate", nl: "El Calafate", fr: "El Calafate" },
  FUE: { en: "Fuerteventura", nl: "Fuerteventura", fr: "Fuerteventura" },
  FUK: { en: "Fukuoka", nl: "Fukuoka", fr: "Fukuoka" },
  FWA: { en: "Fort Wayne", nl: "Fort Wayne", fr: "Fort Wayne" },
  FYV: { en: "Fayetteville, AR", nl: "Fayetteville, AR", fr: "Fayetteville, AR" },
  GAO: { en: "Guantanamo", nl: "Guantanamo", fr: "Guantanamo" },
  GAU: { en: "Guwahati", nl: "Guwahati", fr: "Guwahati" },
  GBE: { en: "Gaborone", nl: "Gaborone", fr: "Gaborone" },
  GDL: { en: "Guadalajara", nl: "Guadalajara", fr: "Guadalajara" },
  GDN: { en: "Gdansk", nl: "Gdansk", fr: "Gdansk" },
  GEG: { en: "Spokane", nl: "Spokane", fr: "Spokane" },
  GEV: { en: "Gallivare", nl: "Gallivare", fr: "Gallivare" },
  GFK: { en: "Grand Forks", nl: "Grand Forks", fr: "Grand Forks" },
  GIS: { en: "Gisborne", nl: "Gisborne", fr: "Gisborne" },
  GLA: { en: "Glasgow", nl: "Glasgow", fr: "Glasgow" },
  GLH: { en: "Greenville, MS", nl: "Greenville, MS", fr: "Greenville, MS" },
  GNV: { en: "Gainesville", nl: "Gainesville", fr: "Gainesville" },
  GOA: { en: "Genoa", nl: "Genua", fr: "Gênes" },
  GOI: { en: "Goa", nl: "Goa", fr: "Goa" },
  GOJ: { en: "Nizhniy Novgorod", nl: "Nizhniy Novgorod", fr: "Nizhniy Novgorod" },
  GOT: { en: "Gothenburg", nl: "Gothenburg", fr: "Göteborg" },
  GPT: { en: "Gulfport", nl: "Gulfport", fr: "Gulfport" },
  GRB: { en: "Green Bay", nl: "Green Bay", fr: "Green Bay" },
  GRJ: { en: "George", nl: "George", fr: "George" },
  GRO: { en: "Girona", nl: "Girona", fr: "Girona" },
  GRR: { en: "Grand Rapids", nl: "Grand Rapids", fr: "Grand Rapids" },
  GRZ: { en: "Graz", nl: "Graz", fr: "Graz" },
  GSO: { en: "Greensboro", nl: "Greensboro", fr: "Greensboro" },
  GSP: { en: "Greenville, SC", nl: "Greenville, SC", fr: "Greenville, SC" },
  GTF: { en: "Great Falls", nl: "Great Falls", fr: "Great Falls" },
  GUA: { en: "Guatemala City", nl: "Guatemala-Stad", fr: "Guatemala" },
  GUM: { en: "Guam", nl: "Guam", fr: "Guam" },
  GUW: { en: "Atyrau", nl: "Atyrau", fr: "Atyrau" },
  GVA: { en: "Geneva", nl: "Genève", fr: "Genève" },
  GYE: { en: "Guayaquil", nl: "Guayaquil", fr: "Guayaquil" },
  GYN: { en: "Goiania", nl: "Goiania", fr: "Goiânia" },
  GZP: { en: "Gazipasa", nl: "Gazipasa", fr: "Gazipasa" },
  GZT: { en: "Gaziantep", nl: "Gaziantep", fr: "Gaziantep" },
  HAC: { en: "Hachijo Jima", nl: "Hachijo Jima", fr: "Hachijo Jima" },
  HAD: { en: "Halmstad", nl: "Halmstad", fr: "Halmstad" },
  HAJ: { en: "Hannover", nl: "Hannover", fr: "Hanovre" },
  HAK: { en: "Haikou", nl: "Haikou", fr: "Haikou" },
  HAM: { en: "Hamburg", nl: "Hamburg", fr: "Hambourg" },
  HAN: { en: "Hanoi", nl: "Hanoi", fr: "Hanoï" },
  HAR: { en: "Harrisburg", nl: "Harrisburg", fr: "Harrisburg" },
  HAU: { en: "Haugesund", nl: "Haugesund", fr: "Haugesund" },
  HAV: { en: "Havana", nl: "Havana", fr: "La Havane" },
  HDS: { en: "Hoedspruit", nl: "Hoedspruit", fr: "Hoedspruit" },
  HEL: { en: "Helsinki", nl: "Helsinki", fr: "Helsinki" },
  HER: { en: "Heraklion", nl: "Heraklion", fr: "Heraklion" },
  HET: { en: "Hohhot", nl: "Hohhot", fr: "Hohhot" },
  HFD: { en: "Hartford/Springfield", nl: "Hartford/Springfield", fr: "Hartford/Springfield" },
  HFE: { en: "Hefei", nl: "Hefei", fr: "Hefei" },
  HGH: { en: "Hangzhou", nl: "Hangzhou", fr: "Hangzhou (nearby Shanghai)" },
  HIB: { en: "Chisholm", nl: "Chisholm", fr: "Chisholm" },
  HIJ: { en: "Hiroshima", nl: "Hiroshima", fr: "Hiroshima" },
  HIN: { en: "Jinju", nl: "Jinju", fr: "Jinju" },
  HKD: { en: "Hakodate", nl: "Hakodate", fr: "Hakodate" },
  HKG: { en: "Hong Kong", nl: "Hongkong", fr: "Hong Kong" },
  HKT: { en: "Phuket", nl: "Phuket", fr: "Phuket" },
  HLN: { en: "Helena", nl: "Helena", fr: "Helena" },
  HLZ: { en: "Hamilton", nl: "Hamilton", fr: "Hamilton" },
  HMO: { en: "Hermosillo", nl: "Hermosillo", fr: "Hermosillo" },
  HMV: { en: "Hemavan Tärnaby", nl: "Hemavan Tärnaby", fr: "Hemavan Tärnaby" },
  HNL: { en: "Honolulu", nl: "Honolulu", fr: "Honolulu" },
  HOG: { en: "Holguin", nl: "Holguin", fr: "Holguin" },
  HOU: { en: "Houston", nl: "Houston", fr: "Houston" },
  HPH: { en: "Haiphong", nl: "Haiphong", fr: "Haiphong" },
  HPN: { en: "White Plains", nl: "White Plains", fr: "White Plains" },
  HRB: { en: "Harbin", nl: "Harbin", fr: "Harbin" },
  HRE: { en: "Harare", nl: "Harare", fr: "Harare" },
  HRK: { en: "Kharkov", nl: "Charkov", fr: "Kharkov" },
  HSV: { en: "Huntsville", nl: "Huntsville", fr: "Huntsville" },
  HTS: { en: "Huntington", nl: "Huntington", fr: "Huntington" },
  HTY: { en: "Hatay", nl: "Hatay", fr: "Hatay" },
  HUI: { en: "Hue", nl: "Hue", fr: "Hue" },
  HUX: { en: "Huatulco", nl: "Huatulco", fr: "Huatulco" },
  HUY: { en: "Humberside", nl: "Humberside", fr: "Humberside" },
  HYD: { en: "Hyderabad", nl: "Hyderabad", fr: "Hyderabad" },
  IAS: { en: "Iasi", nl: "Iasi", fr: "Iași" },
  IBE: { en: "Ibague", nl: "Ibague", fr: "Ibague" },
  IBZ: { en: "Ibiza", nl: "Ibiza", fr: "Ibiza" },
  ICT: { en: "Wichita", nl: "Wichita", fr: "Wichita" },
  IDA: { en: "Idaho Falls", nl: "Idaho Falls", fr: "Idaho Falls" },
  IDR: { en: "Indore", nl: "Indore", fr: "Indore" },
  IEV: { en: "Kiev", nl: "Kiev", fr: "Kiev" },
  IGU: { en: "Iguassu Falls", nl: "Iguassu Falls", fr: "Chutes d'Igazú" },
  IKT: { en: "Irkutsk", nl: "Irkutsk", fr: "Irkutsk" },
  ILE: { en: "Killeen", nl: "Killeen", fr: "Killeen" },
  ILM: { en: "Wilmington", nl: "Wilmington", fr: "Wilmington" },
  IMT: { en: "Iron Mountain", nl: "Iron Mountain", fr: "Iron Mountain" },
  INC: { en: "Yinchuan", nl: "Yinchuan", fr: "Yinchuan" },
  IND: { en: "Indianapolis", nl: "Indianapolis", fr: "Indianapolis" },
  INL: { en: "International Falls", nl: "International Falls", fr: "International Falls" },
  INN: { en: "Innsbruck", nl: "Innsbruck", fr: "Innsbruck" },
  INV: { en: "Inverness", nl: "Inverness", fr: "Inverness" },
  IOS: { en: "Ilhéus", nl: "Ilhéus", fr: "Ilhéus" },
  IQQ: { en: "Iquique", nl: "Iquique", fr: "Iquique" },
  IQT: { en: "Iquitos", nl: "Iquitos", fr: "Iquitos" },
  ISB: { en: "Islamabad", nl: "Islamabad", fr: "Islamabad" },
  ISN: { en: "Williston", nl: "Williston", fr: "Williston" },
  IST: { en: "Istanbul", nl: "Istanbul", fr: "Istanbul" },
  ITH: { en: "Ithaca", nl: "Ithaca", fr: "Ithaca" },
  IVL: { en: "Ivalo", nl: "Ivalo", fr: "Ivalo" },
  IXC: { en: "Chandigarh", nl: "Chandigarh", fr: "Chandigarh" },
  IZO: { en: "Izumo", nl: "Izumo", fr: "Izumo" },
  JAC: { en: "Jackson, WY", nl: "Jackson, WY", fr: "Jackson, WY" },
  JAI: { en: "Jaipur", nl: "Jaipur", fr: "Jaipur" },
  JAN: { en: "Jackson, MS", nl: "Jackson, MS", fr: "Jackson, MS" },
  JAX: { en: "Jacksonville, FL", nl: "Jacksonville, FL", fr: "Jacksonville, FL" },
  JED: { en: "Jeddah", nl: "Jeddah", fr: "Djeddah" },
  JHB: { en: "Johor Bharu", nl: "Johor Bharu", fr: "Johor Bharu" },
  JIB: { en: "Djibouti", nl: "Djibouti", fr: "Djibouti" },
  JIU: { en: "Jiujiang", nl: "Jiujiang", fr: "Jiujiang" },
  JKG: { en: "Jonkoping", nl: "Jonkoping", fr: "Jonkoping" },
  JKH: { en: "Chios", nl: "Chios", fr: "Chios" },
  JKT: { en: "Jakarta", nl: "Jakarta", fr: "Jakarta" },
  JNB: { en: "Johannesburg", nl: "Johannesburg", fr: "Johannesbourg" },
  JNU: { en: "Juneau", nl: "Juneau", fr: "Juneau" },
  JOE: { en: "Joensuu", nl: "Joensuu", fr: "Joensuu" },
  JOG: { en: "Yogyakarta", nl: "Yogyakarta", fr: "Yogyakarta" },
  JPA: { en: "Joao Pessoa", nl: "Joao Pessoa", fr: "Joao Pessoa" },
  JRO: { en: "Kilimanjaro", nl: "Kilimanjaro", fr: "Kilimandjaro" },
  JTR: { en: "Thira", nl: "Thira", fr: "Thira" },
  JUB: { en: "Juba", nl: "Juba", fr: "Djouba" },
  JUL: { en: "Juliaca", nl: "Juliaca", fr: "Juliaca" },
  JZH: { en: "Jiuzhaigou", nl: "Jiuzhaigou", fr: "Jiuzhaigou" },
  KAO: { en: "Kuusamo", nl: "Kuusamo", fr: "Kuusamo" },
  KBR: { en: "Kota Bharu", nl: "Kota Bharu", fr: "Kota Bharu" },
  KBV: { en: "Krabi", nl: "Krabi", fr: "Krabi" },
  KCH: { en: "Kuching", nl: "Kuching", fr: "Kuching" },
  KCZ: { en: "Kochi", nl: "Kochi", fr: "Kochi" },
  KGD: { en: "Kaliningrad", nl: "Kaliningrad", fr: "Kaliningrad" },
  KGL: { en: "Kigali", nl: "Kigali", fr: "Kigali" },
  KGS: { en: "Kos", nl: "Kos", fr: "Kos" },
  KHH: { en: "Kaohsiung", nl: "Kaohsiung", fr: "Kaohsiung" },
  KHI: { en: "Karachi", nl: "Karachi", fr: "Karachi" },
  KHN: { en: "Nanchang", nl: "Nanchang", fr: "Nanchang" },
  KHV: { en: "Khabarovsk", nl: "Khabarovsk", fr: "Khabarovsk" },
  KIJ: { en: "Niigata", nl: "Niigata", fr: "Niigata" },
  KIN: { en: "Kingston", nl: "Kingston", fr: "Kingston" },
  KIS: { en: "Kisumu", nl: "Kisumu", fr: "Kisumu" },
  KJA: { en: "Krasnoyarsk", nl: "Krasnoyarsk", fr: "Krasnoyarsk" },
  KKE: { en: "Bay of Islands", nl: "Bay of Islands", fr: "Bay of Islands" },
  KKJ: { en: "Kita Kyushu", nl: "Kita Kyushu", fr: "Kita Kyushu" },
  KKN: { en: "Kirkenes", nl: "Kirkenes", fr: "Kirkenes" },
  KMG: { en: "Kunming", nl: "Kunming", fr: "Kunming" },
  KMI: { en: "Miyazaki", nl: "Miyazaki", fr: "Miyazaki" },
  KMJ: { en: "Kumamoto", nl: "Kumamoto", fr: "Kumamoto" },
  KMQ: { en: "Komatsu", nl: "Komatsu", fr: "Komatsu" },
  KOA: { en: "Kona", nl: "Kona", fr: "Kona" },
  KOJ: { en: "Kagoshima", nl: "Kagoshima", fr: "Kagoshima" },
  KOK: { en: "Kokkola Pietarsaa", nl: "Kokkola Pietarsaa", fr: "Kokkola Pietarsaa" },
  KPO: { en: "Pohang", nl: "Pohang", fr: "Pohang" },
  KRF: { en: "Kramfors", nl: "Kramfors", fr: "Kramfors" },
  KRK: { en: "Krakow", nl: "Krakau", fr: "Cracovie" },
  KRR: { en: "Krasnodar", nl: "Krasnodar", fr: "Krasnodar" },
  KRS: { en: "Kristiansand", nl: "Kristiansand", fr: "Kristiansand" },
  KRT: { en: "Khartoum", nl: "Khartoum", fr: "Khartoum" },
  KSC: { en: "Kosice", nl: "Kosice", fr: "Košice" },
  KSD: { en: "Karlstad", nl: "Karlstad", fr: "Karlstad" },
  KSU: { en: "Kristiansund", nl: "Kristiansund", fr: "Kristiansund" },
  KTM: { en: "Kathmandu", nl: "Kathmandu", fr: "Kathmandu" },
  KTN: { en: "Ketchikan", nl: "Ketchikan", fr: "Ketchikan" },
  KTT: { en: "Kittila", nl: "Kittila", fr: "Kittila" },
  KUA: { en: "Kuantan", nl: "Kuantan", fr: "Kuantan" },
  KUF: { en: "Samara", nl: "Samara", fr: "Samara" },
  KUH: { en: "Kushiro", nl: "Kushiro", fr: "Kushiro" },
  KUL: { en: "Kuala Lumpur", nl: "Kuala Lumpur", fr: "Kuala Lumpur" },
  KUN: { en: "Kaunas", nl: "Kaunas", fr: "Kaunas" },
  KUO: { en: "Kuopio", nl: "Kuopio", fr: "Kuopio" },
  KWE: { en: "Guiyang", nl: "Guiyang", fr: "Guiyang" },
  KWI: { en: "Kuwait", nl: "Koeweit", fr: "Koweït" },
  KWJ: { en: "Gwangju", nl: "Gwangju", fr: "Gwangju" },
  KWL: { en: "Guilin", nl: "Guilin", fr: "Guilin" },
  KYA: { en: "Konya", nl: "Konya", fr: "Konya" },
  KZN: { en: "Kazan", nl: "Kazan", fr: "Kazan" },
  KZO: { en: "Kyzylorda", nl: "Kyzylorda", fr: "Kyzylorda" },
  KZR: { en: "Kutahya", nl: "Kutahya", fr: "Kutahya" },
  LAD: { en: "Luanda", nl: "Luanda", fr: "Luanda" },
  LAI: { en: "Lannion", nl: "Lannion", fr: "Lannion" },
  LAN: { en: "Lansing", nl: "Lansing", fr: "Lansing" },
  LAP: { en: "La Paz", nl: "La Paz", fr: "La Paz" },
  LAS: { en: "Las Vegas", nl: "Las Vegas", fr: "Las Vegas" },
  LAX: { en: "Los Angeles", nl: "Los Angeles", fr: "Los Angeles" },
  LBA: { en: "Leeds", nl: "Leeds", fr: "Leeds" },
  LBU: { en: "Labuan", nl: "Labuan", fr: "Labuan" },
  LBV: { en: "Libreville", nl: "Libreville", fr: "Libreville" },
  LCA: { en: "Larnaca", nl: "Larnaca", fr: "Larnaca" },
  LCG: { en: "A Coruna", nl: "A Coruna", fr: "A Coruna" },
  LCJ: { en: "Lodz", nl: "Lodz", fr: "Lodz" },
  LDB: { en: "Londrina", nl: "Londrina", fr: "Londrina" },
  LDE: { en: "Lourdes", nl: "Lourdes", fr: "Lourdes" },
  LED: { en: "St Petersburg", nl: "St Petersburg", fr: "Saint-Pétersbourg" },
  LEX: { en: "Lexington", nl: "Lexington", fr: "Lexington" },
  LFT: { en: "Lafayette", nl: "Lafayette", fr: "Lafayette" },
  LFW: { en: "Lome", nl: "Lome", fr: "Lome" },
  LGB: { en: "Long Beach", nl: "Long Beach", fr: "Long Beach" },
  LGK: { en: "Langkawi", nl: "Langkawi", fr: "Langkawi" },
  LHE: { en: "Lahore", nl: "Lahore", fr: "Lahore" },
  LHW: { en: "Lanzhou", nl: "Lanzhou", fr: "Lanzhou" },
  LIG: { en: "Limoges", nl: "Limoges", fr: "Limoges" },
  LIH: { en: "Kauai Island", nl: "Kauai Island", fr: "Kauai Island" },
  LIL: { en: "Lille", nl: "Lille", fr: "Lille" },
  LIM: { en: "Lima", nl: "Lima", fr: "Lima" },
  LIR: { en: "Liberia", nl: "Liberia", fr: "Liberia" },
  LIS: { en: "Lisbon", nl: "Lissabon", fr: "Lisbonne" },
  LIT: { en: "Little Rock", nl: "Little Rock", fr: "Little Rock" },
  LJG: { en: "Lijiang", nl: "Lijiang", fr: "Lijiang" },
  LJU: { en: "Ljubljana", nl: "Ljubljana", fr: "Ljubljana" },
  LKO: { en: "Lucknow", nl: "Lucknow", fr: "Lucknow" },
  LLW: { en: "Lilongwe", nl: "Lilongwe", fr: "Lilongwe" },
  LME: { en: "Le Mans", nl: "Le Mans", fr: "Le Mans" },
  LMM: { en: "Los Mochis", nl: "Los Mochis", fr: "Los Mochis" },
  LNK: { en: "Lincoln", nl: "Lincoln", fr: "Lincoln" },
  LON: { en: "London", nl: "Londen", fr: "Londres" },
  LOS: { en: "Lagos", nl: "Lagos", fr: "Lagos" },
  LPA: { en: "Las Palmas", nl: "Las Palmas", fr: "Las Palmas" },
  LPB: { en: "La Paz", nl: "La Paz", fr: "La Paz" },
  LPI: { en: "Linköping", nl: "Linköping", fr: "Linköping" },
  LPQ: { en: "Luang Prabang", nl: "Luang Prabang", fr: "Luang Prabang" },
  LRT: { en: "Lorient", nl: "Lorient", fr: "Lorient" },
  LSE: { en: "La Crosse", nl: "La Crosse", fr: "La Crosse" },
  LTT: { en: "St Tropez", nl: "St Tropez", fr: "St Tropez" },
  LUN: { en: "Lusaka", nl: "Lusaka", fr: "Lusaka" },
  LUX: { en: "Luxembourg", nl: "Luxemburg", fr: "Luxembourg" },
  LVI: { en: "Livingstone", nl: "Livingstone", fr: "Livingstone" },
  LWO: { en: "Lviv", nl: "Lviv", fr: "Lviv" },
  LWS: { en: "Lewiston", nl: "Lewiston", fr: "Lewiston" },
  LXA: { en: "Lhasa", nl: "Lhasa", fr: "Lhasa" },
  LYA: { en: "Luoyang", nl: "Luoyang", fr: "Luoyang" },
  LYC: { en: "Lycksele", nl: "Lycksele", fr: "Lycksele" },
  LYG: { en: "Lianyungang", nl: "Lianyungang", fr: "Lianyungang" },
  LYI: { en: "Linyi", nl: "Linyi", fr: "Linyi" },
  LYS: { en: "Lyon", nl: "Lyon", fr: "Lyon" },
  MAA: { en: "Chennai", nl: "Chennai", fr: "Chennai/Madras" },
  MAD: { en: "Madrid", nl: "Madrid", fr: "Madrid" },
  MAH: { en: "Menorca", nl: "Menorca", fr: "Minorque" },
  MAN: { en: "Manchester", nl: "Manchester", fr: "Manchester" },
  MAO: { en: "Manaus", nl: "Manaus", fr: "Manaus" },
  MAR: { en: "Maracaibo", nl: "Maracaibo", fr: "Maracaibo" },
  MBA: { en: "Mombasa", nl: "Mombasa", fr: "Mombasa" },
  MBE: { en: "Monbetsu", nl: "Monbetsu", fr: "Monbetsu" },
  MBJ: { en: "Montego Bay", nl: "Montego Bay", fr: "Montego Bay" },
  MBS: { en: "Saginaw", nl: "Saginaw", fr: "Saginaw" },
  MCT: { en: "Muscat", nl: "Muscat", fr: "Mascate" },
  MCW: { en: "Mason City", nl: "Mason City", fr: "Mason City" },
  MCZ: { en: "Maceio", nl: "Maceio", fr: "Maceio" },
  MDE: { en: "Medellin", nl: "Medellin", fr: "Medellin" },
  MDZ: { en: "Mendoza", nl: "Mendoza", fr: "Mendoza" },
  MEI: { en: "Meridian", nl: "Meridian", fr: "Meridian" },
  MEL: { en: "Melbourne", nl: "Melbourne", fr: "Melbourne" },
  MEM: { en: "Memphis", nl: "Memphis", fr: "Memphis" },
  MES: { en: "Medan", nl: "Medan", fr: "Medan" },
  MEX: { en: "Mexico City", nl: "Mexico-Stad", fr: "Mexico" },
  MFR: { en: "Medford", nl: "Medford", fr: "Medford" },
  MGA: { en: "Managua", nl: "Managua", fr: "Managua" },
  MGF: { en: "Maringa", nl: "Maringa", fr: "Maringa" },
  MGM: { en: "Montgomery", nl: "Montgomery", fr: "Montgomery" },
  MHQ: { en: "Mariehamn", nl: "Mariehamn", fr: "Mariehamn" },
  MHT: { en: "Manchester, NH", nl: "Manchester, NH", fr: "Manchester, NH" },
  MIA: { en: "Miami", nl: "Miami", fr: "Miami" },
  MID: { en: "Merida", nl: "Merida", fr: "Mérida" },
  MIL: { en: "Milan", nl: "Milaan", fr: "Milan" },
  MJF: { en: "Mosjoen", nl: "Mosjoen", fr: "Mosjoen" },
  MJT: { en: "Mytilini", nl: "Mytilini", fr: "Mytilini" },
  MKC: { en: "Kansas City", nl: "Kansas City", fr: "Kansas City" },
  MKE: { en: "Milwaukee", nl: "Milwaukee", fr: "Milwaukee" },
  MLA: { en: "Malta", nl: "Malta", fr: "Malta" },
  MLB: { en: "Melbourne", nl: "Melbourne", fr: "Melbourne" },
  MLE: { en: "Male", nl: "Male", fr: "Male" },
  MLI: { en: "Moline", nl: "Moline", fr: "Moline" },
  MLU: { en: "Monroe", nl: "Monroe", fr: "Monroe" },
  MLW: { en: "Monrovia", nl: "Monrovia", fr: "Monrovia" },
  MLX: { en: "Malatya", nl: "Malatya", fr: "Malatya" },
  MMB: { en: "Memanbetsu", nl: "Memanbetsu", fr: "Memanbetsu" },
  MME: { en: "Durham", nl: "Durham", fr: "Durham" },
  MMK: { en: "Murmansk", nl: "Murmansk", fr: "Murmansk" },
  MNL: { en: "Manila", nl: "Manilla", fr: "Manille" },
  MOB: { en: "Mobile", nl: "Mobile", fr: "Mobile" },
  MOL: { en: "Molde", nl: "Molde", fr: "Molde" },
  MOT: { en: "Minot", nl: "Minot", fr: "Minot" },
  MOW: { en: "Moscow", nl: "Moskou", fr: "Moscou" },
  MPL: { en: "Montpellier", nl: "Montpellier", fr: "Montpellier" },
  MPM: { en: "Maputo", nl: "Maputo", fr: "Maputo" },
  MQM: { en: "Mardin", nl: "Mardin", fr: "Mardin" },
  MQN: { en: "Mo i rana", nl: "Mo i rana", fr: "Mo i rana" },
  MRS: { en: "Marseille", nl: "Marseille", fr: "Marseille" },
  MRU: { en: "Mauritius", nl: "Mauritius", fr: "Maurice" },
  MRV: { en: "Mineralnye Vody", nl: "Mineralnye Vody", fr: "Mineralnye Vody" },
  MSN: { en: "Madison", nl: "Madison", fr: "Madison" },
  MSO: { en: "Missoula", nl: "Missoula", fr: "Missoula" },
  MSP: { en: "Minneapolis", nl: "Minneapolis", fr: "Minneapolis" },
  MSQ: { en: "Minsk", nl: "Minsk", fr: "Minsk" },
  MSR: { en: "Mus", nl: "Mus", fr: "Mus" },
  MST: { en: "Maastricht", nl: "Maastricht", fr: "Maastricht" },
  MSY: { en: "New Orleans", nl: "New Orleans", fr: "Nouvelle-Orléans" },
  MTR: { en: "Montería", nl: "Montería", fr: "Montería" },
  MTY: { en: "Monterrey", nl: "Monterrey", fr: "Monterrey" },
  MUC: { en: "Munich", nl: "Munchen", fr: "Munich" },
  MUZ: { en: "Musoma", nl: "Musoma", fr: "Musoma" },
  MVD: { en: "Montevideo", nl: "Montevideo", fr: "Montevideo" },
  MWZ: { en: "Mwanza", nl: "Mwanza", fr: "Mwanza" },
  MXL: { en: "Mexicali", nl: "Mexicali", fr: "Mexicali" },
  MYD: { en: "Malindi", nl: "Malindi", fr: "Malindi" },
  MYJ: { en: "Matsuyama", nl: "Matsuyama", fr: "Matsuyama" },
  MYR: { en: "Myrtle Beach", nl: "Myrtle Beach", fr: "Myrtle Beach" },
  MYW: { en: "Mtwara", nl: "Mtwara", fr: "Mtwara" },
  MYY: { en: "Miri", nl: "Miri", fr: "Miri" },
  MZH: { en: "Amasya", nl: "Amasya", fr: "Amasya" },
  MZL: { en: "Manizales", nl: "Manizales", fr: "Manizales" },
  MZT: { en: "Mazatlan", nl: "Mazatlan", fr: "Mazatlan" },
  NAP: { en: "Naples", nl: "Napels", fr: "Naples" },
  NAS: { en: "Nassau", nl: "Nassau", fr: "Nassau" },
  NAT: { en: "Natal", nl: "Natal", fr: "Natal" },
  NAV: { en: "Nevsehir", nl: "Nevsehir", fr: "Nevsehir" },
  NBC: { en: "Nizhnekamsk", nl: "Nizhnekamsk", fr: "Nizhnekamsk" },
  NBO: { en: "Nairobi", nl: "Nairobi", fr: "Nairobi" },
  NCE: { en: "Nice", nl: "Nice", fr: "Nice" },
  NCL: { en: "Newcastle", nl: "Newcastle", fr: "Newcastle" },
  NCY: { en: "Annecy", nl: "Annecy", fr: "Annecy" },
  NDJ: { en: "Ndjamena", nl: "Ndjamena", fr: "Ndjamena" },
  NEV: { en: "Nevis", nl: "Nevis", fr: "Nevis" },
  NGB: { en: "Ningbo", nl: "Ningbo", fr: "Ningbo" },
  NGO: { en: "Nagoya", nl: "Nagoya", fr: "Nagoya" },
  NGS: { en: "Nagasaki", nl: "Nagasaki", fr: "Nagasaki" },
  NHA: { en: "Nha Trang", nl: "Nha Trang", fr: "Nha Trang" },
  NIM: { en: "Niamey", nl: "Niamey", fr: "Niamey" },
  NJC: { en: "Nizhnevartovsk", nl: "Nizhnevartovsk", fr: "Nizhnevartovsk" },
  NKC: { en: "Nouakchott", nl: "Nouakchott", fr: "Nouakchott" },
  NKG: { en: "Nanjing", nl: "Nanjing", fr: "Nanjing" },
  NLA: { en: "Ndola", nl: "Ndola", fr: "Ndola" },
  NLD: { en: "Nuevo Laredo", nl: "Nuevo Laredo", fr: "Nuevo Laredo" },
  NNG: { en: "Nanning", nl: "Nanning", fr: "Nanning" },
  NOU: { en: "Noumea", nl: "Noumea", fr: "Nouméa" },
  NPE: { en: "Napier", nl: "Napier", fr: "Napier" },
  NPL: { en: "New Plymouth", nl: "New Plymouth", fr: "New Plymouth" },
  NSN: { en: "Nelson", nl: "Nelson", fr: "Nelson" },
  NTE: { en: "Nantes", nl: "Nantes", fr: "Nantes" },
  NTG: { en: "Nantong", nl: "Nantong", fr: "Nantong" },
  NUE: { en: "Nuremberg", nl: "Neurenberg", fr: "Nuremberg" },
  NVA: { en: "Neiva", nl: "Neiva", fr: "Neiva" },
  NVT: { en: "Navegantes", nl: "Navegantes", fr: "Navegantes" },
  NWI: { en: "Norwich", nl: "Norwich", fr: "Norwich" },
  NYC: { en: "New York", nl: "New York", fr: "New York" },
  OAJ: { en: "Jacksonville, NC", nl: "Jacksonville, NC", fr: "Jacksonville, NC" },
  OAK: { en: "Oakland", nl: "Oakland", fr: "Oakland" },
  OAX: { en: "Oaxaca", nl: "Oaxaca", fr: "Oaxaca" },
  ODS: { en: "Odessa", nl: "Odessa", fr: "Odessa" },
  OER: { en: "Ornskoldsvik", nl: "Ornskoldsvik", fr: "Ornskoldsvik" },
  OGG: { en: "Maui", nl: "Maui", fr: "Maui" },
  OIT: { en: "Oita", nl: "Oita", fr: "Oita" },
  OKA: { en: "Okinawa", nl: "Okinawa", fr: "Okinawa" },
  OKC: { en: "Oklahoma City", nl: "Oklahoma City", fr: "Oklahoma City" },
  OKJ: { en: "Okayama", nl: "Okayama", fr: "Okayama" },
  OLB: { en: "Olbia", nl: "Olbia", fr: "Olbia" },
  OMA: { en: "Omaha", nl: "Omaha", fr: "Omaha" },
  OMS: { en: "Omsk", nl: "Omsk", fr: "Omsk" },
  ONT: { en: "Ontario", nl: "Ontario", fr: "Ontario" },
  OOL: { en: "Gold Coast", nl: "Gold Coast", fr: "Gold Coast" },
  OPO: { en: "Porto", nl: "Porto", fr: "Porto" },
  ORF: { en: "Norfolk", nl: "Norfolk", fr: "Norfolk" },
  ORK: { en: "Cork", nl: "Cork", fr: "Cork" },
  ORL: { en: "Orlando", nl: "Orlando", fr: "Orlando" },
  ORN: { en: "Oran", nl: "Oran", fr: "Oran" },
  OSA: { en: "Osaka", nl: "Osaka", fr: "Osaka" },
  OSL: { en: "Oslo", nl: "Oslo", fr: "Oslo" },
  OSR: { en: "Ostrava", nl: "Ostrava", fr: "Ostrava" },
  OUA: { en: "Uagadugú", nl: "Uagadugú", fr: "Ouagadougou" },
  OUL: { en: "Oulu", nl: "Oulu", fr: "Oulu" },
  OVB: { en: "Novosibirsk", nl: "Novosibirsk", fr: "Novosibirsk" },
  OVD: { en: "Oviedo", nl: "Oviedo", fr: "Oviedo" },
  OZH: { en: "Zaporizhzhia", nl: "Zaporizhzhia", fr: "Zaporizhzhia" },
  PAP: { en: "Port-au-Prince", nl: "Port-au-Prince", fr: "Port-au-Prince" },
  PAR: { en: "Paris", nl: "Parijs", fr: "Paris" },
  PAT: { en: "Patna", nl: "Patna", fr: "Patna" },
  PBD: { en: "Porbandar", nl: "Porbandar", fr: "Porbandar" },
  PBI: { en: "West Palm Beach", nl: "West Palm Beach", fr: "West Palm Beach" },
  PBM: { en: "Paramaribo", nl: "Paramaribo", fr: "Paramaribo" },
  PDG: { en: "Padang", nl: "Padang", fr: "Padang" },
  PDL: { en: "Ponta Delgada", nl: "Ponta Delgada", fr: "Ponta Delgada" },
  PDX: { en: "Portland, OR", nl: "Portland, OR", fr: "Portland, OR" },
  PEE: { en: "Perm", nl: "Perm", fr: "Perm" },
  PEI: { en: "Pereira", nl: "Pereira", fr: "Pereira" },
  PEN: { en: "Penang", nl: "Penang", fr: "Penang" },
  PER: { en: "Perth", nl: "Perth", fr: "Perth" },
  PFO: { en: "Paphos", nl: "Paphos", fr: "Paphos" },
  PGF: { en: "Perpignan", nl: "Perpignan", fr: "Perpignan" },
  PHC: { en: "Port Harcourt", nl: "Port Harcourt", fr: "Port Harcourt" },
  PHL: { en: "Philadelphia", nl: "Philadelphia", fr: "Philadelphie" },
  PHX: { en: "Phoenix", nl: "Phoenix", fr: "Phoenix" },
  PIA: { en: "Peoria", nl: "Peoria", fr: "Peoria" },
  PIR: { en: "Pierre", nl: "Pierre", fr: "Pierre" },
  PIS: { en: "Poitiers", nl: "Poitiers", fr: "Poitiers" },
  PIT: { en: "Pittsburgh", nl: "Pittsburgh", fr: "Pittsburg" },
  PKU: { en: "Pekanbaru", nl: "Pekanbaru", fr: "Pekanbaru" },
  PLM: { en: "Palembang", nl: "Palembang", fr: "Palembang" },
  PLN: { en: "Pellston", nl: "Pellston", fr: "Pellston" },
  PLQ: { en: "Palanga", nl: "Palanga", fr: "Palanga" },
  PLZ: { en: "Port Elizabeth", nl: "Port Elizabeth", fr: "Port Elizabeth" },
  PMC: { en: "Puerto Montt", nl: "Puerto Montt", fr: "Puerto Montt" },
  PMI: { en: "Palma de Mallorca", nl: "Palma Mallorca", fr: "Palma de Majorque" },
  PMO: { en: "Palermo", nl: "Palermo", fr: "Palerme" },
  PMR: { en: "Palmerston North", nl: "Palmerston North", fr: "Palmerston North" },
  PNH: { en: "Phnom Penh", nl: "Phnom Penh", fr: "Phnom Penh" },
  PNQ: { en: "Pune", nl: "Pune", fr: "Pune" },
  PNR: { en: "Pointe Noire", nl: "Pointe Noire", fr: "Pointe-Noire" },
  PNS: { en: "Pensacola", nl: "Pensacola", fr: "Pensacola" },
  POA: { en: "Porto Alegre", nl: "Porto Alegre", fr: "Porto Alegre" },
  POM: { en: "Port Moresby", nl: "Port Moresby", fr: "Port Moresby" },
  POR: { en: "Pori", nl: "Pori", fr: "Pori" },
  POS: { en: "Port of Spain", nl: "Port of Spain", fr: "Port of Spain" },
  PPN: { en: "Popayan", nl: "Popayan", fr: "Popayan" },
  PPT: { en: "Papeete", nl: "Papeete", fr: "Papeete" },
  PRG: { en: "Prague", nl: "Praag", fr: "Prague" },
  PRI: { en: "Praslin Island", nl: "Praslin Island", fr: "Praslin Island" },
  PSA: { en: "Pisa", nl: "Pisa", fr: "Pise" },
  PSC: { en: "Pasco", nl: "Pasco", fr: "Pasco" },
  PSP: { en: "Palm Springs", nl: "Palm Springs", fr: "Palm Springs" },
  PTP: { en: "Pointe a Pitre", nl: "Pointe a Pitre", fr: "Point-à-Pitre" },
  PTY: { en: "Panama City", nl: "Panama-Stad", fr: "Panama City" },
  PUF: { en: "Pau", nl: "Pau", fr: "Pau" },
  PUJ: { en: "Punta Cana", nl: "Punta Cana", fr: "Punta Cana" },
  PUQ: { en: "Punta Arenas", nl: "Punta Arenas", fr: "Punta Arenas" },
  PUS: { en: "Busan", nl: "Busan", fr: "Pusan" },
  PUW: { en: "Pullman", nl: "Pullman", fr: "Pullman" },
  PVD: { en: "Providence", nl: "Providence", fr: "Providence" },
  PVR: { en: "Puerto Vallarta", nl: "Puerto Vallarta", fr: "Puerto Vallarta" },
  PWM: { en: "Portland, ME", nl: "Portland, ME", fr: "Portland, ME" },
  QNQ: { en: "Nijmegen", nl: "Nijmegen", fr: "Nijmegen" },
  RAK: { en: "Marrakech", nl: "Marrakech", fr: "Marrakech" },
  RAO: { en: "Ribeirão Preto", nl: "Ribeirão Preto", fr: "Ribeirão Preto" },
  RAP: { en: "Rapid City", nl: "Rapid City", fr: "Rapid City" },
  RBA: { en: "Rabat", nl: "Rabat", fr: "Rabat" },
  RCB: { en: "Richards Bay", nl: "Richards Bay", fr: "Richards Bay" },
  RDM: { en: "Redmond, OR", nl: "Redmond, OR", fr: "Redmond, OR" },
  RDU: { en: "Raleigh/Durham", nl: "Raleigh/Durham", fr: "Raleigh/Durham" },
  RDZ: { en: "Rodez", nl: "Rodez", fr: "Rodez" },
  REC: { en: "Recife", nl: "Recife", fr: "Recife" },
  REK: { en: "Reykjavik", nl: "Reykjavik", fr: "Reikiavik" },
  REP: { en: "Siem Reap", nl: "Siem Reap", fr: "Siem Reap" },
  REX: { en: "Reynosa", nl: "Reynosa", fr: "Reynosa" },
  RGN: { en: "Yangon", nl: "Yangon", fr: "Rangoon" },
  RHE: { en: "Reims", nl: "Reims", fr: "Reims" },
  RHI: { en: "Rhinelander", nl: "Rhinelander", fr: "Rhinelander" },
  RHO: { en: "Rhodes", nl: "Rhodes", fr: "Rhodes" },
  RIC: { en: "Richmond", nl: "Richmond", fr: "Richmond" },
  RIO: { en: "Rio de Janeiro", nl: "Rio de Janeiro", fr: "Rio de Janeiro" },
  RIX: { en: "Riga", nl: "Riga", fr: "Riga" },
  RMQ: { en: "Taichung", nl: "Taichung", fr: "Taichung" },
  RNO: { en: "Reno", nl: "Reno", fr: "Reno" },
  RNS: { en: "Rennes", nl: "Rennes", fr: "Rennes" },
  ROA: { en: "Roanoke", nl: "Roanoke", fr: "Roanoke" },
  ROC: { en: "Rochester, NY", nl: "Rochester, NY", fr: "Rochester, NY" },
  ROM: { en: "Rome", nl: "Rome", fr: "Rome" },
  ROR: { en: "Koror", nl: "Koror", fr: "Koror" },
  ROS: { en: "Rosario", nl: "Rosario", fr: "Rosario" },
  ROT: { en: "Rotorua", nl: "Rotorua", fr: "Rotorua" },
  ROV: { en: "Rostov", nl: "Rostov", fr: "Rostov" },
  RPR: { en: "Raipur", nl: "Raipur", fr: "Raipur" },
  RST: { en: "Rochester, MN", nl: "Rochester, MN", fr: "Rochester, MN" },
  RSU: { en: "Yeosu", nl: "Yeosu", fr: "Yeosu" },
  RUH: { en: "Riyadh", nl: "Riad", fr: "Riyad" },
  RUN: { en: "Saint-Denis (Réunion)", nl: "Saint-Denis (Réunion)", fr: "Saint-Denis (Réunion)" },
  RVN: { en: "Rovaniemi", nl: "Rovaniemi", fr: "Rovaniemi" },
  SAB: { en: "Saba", nl: "Saba", fr: "Saba" },
  SAC: { en: "Sacramento", nl: "Sacramento", fr: "Sacramento" },
  SAH: { en: "Sanaa", nl: "Sanaa", fr: "Sanaa" },
  SAL: { en: "San Salvador", nl: "San Salvador", fr: "San Salvador" },
  SAN: { en: "San Diego", nl: "San Diego", fr: "San Diego" },
  SAO: { en: "Sao Paulo", nl: "Sao Paulo", fr: "São Paulo" },
  SAP: { en: "San Pedro Sula", nl: "San Pedro Sula", fr: "San Pedro Sula" },
  SAT: { en: "San Antonio", nl: "San Antonio", fr: "San Antonio" },
  SAV: { en: "Savannah", nl: "Savannah", fr: "Savannah" },
  SBH: { en: "St. Barthelemy", nl: "St. Barthelemy", fr: "St. Barthelemy" },
  SBN: { en: "South Bend", nl: "South Bend", fr: "South Bend" },
  SBW: { en: "Sibu", nl: "Sibu", fr: "Sibu" },
  SCE: { en: "State College", nl: "State College", fr: "State College" },
  SCL: { en: "Santiago de Chile", nl: "Santiago de Chile", fr: "Santiago du Chili" },
  SCO: { en: "Aktau", nl: "Aktau", fr: "Aktau" },
  SCU: { en: "Santiago de Cuba", nl: "Santiago de Cuba", fr: "Santiago de Cuba" },
  SDF: { en: "Louisville", nl: "Louisville", fr: "Louisville" },
  SDJ: { en: "Sendai", nl: "Sendai", fr: "Sendai" },
  SDK: { en: "Sandakan", nl: "Sandakan", fr: "Sandakan" },
  SDL: { en: "Sundsvall", nl: "Sundsvall", fr: "Sundsvall" },
  SDQ: { en: "Santo Domingo", nl: "Santo Domingo", fr: "Saint-Domingue" },
  SEA: { en: "Seattle", nl: "Seattle", fr: "Seattle" },
  SEL: { en: "Seoul", nl: "Seoul", fr: "Séoul" },
  SEZ: { en: "Seychelles", nl: "Seychellen", fr: "Seychelles" },
  SFO: { en: "San Francisco", nl: "San Francisco", fr: "San Francisco" },
  SFQ: { en: "Sanliurfa", nl: "Sanliurfa", fr: "Sanliurfa" },
  SGD: { en: "Sonderborg", nl: "Sonderborg", fr: "Sonderborg" },
  SGF: { en: "Springfield", nl: "Springfield", fr: "Springfield" },
  SGN: { en: "Ho Chi Minh City", nl: "Ho Chi Minh-Stad", fr: "Hô-Chi-Minh-Ville" },
  SHA: { en: "Shanghai", nl: "Shanghai", fr: "Shanghai" },
  SHB: { en: "Nakashibetsu", nl: "Nakashibetsu", fr: "Nakashibetsu" },
  SHE: { en: "Shenyang", nl: "Shenyang", fr: "Shenyang" },
  SHV: { en: "Shreveport", nl: "Shreveport", fr: "Shreveport" },
  SHY: { en: "Shinyanga", nl: "Shinyanga", fr: "Shinyanga" },
  SIA: { en: "Xi An", nl: "Xi An", fr: "Xi'an" },
  SIN: { en: "Singapore", nl: "Singapore", fr: "Singapour" },
  SIT: { en: "Sitka", nl: "Sitka", fr: "Sitka" },
  SJC: { en: "San Jose", nl: "San Jose", fr: "San José" },
  SJD: { en: "San Jose Cabo", nl: "San Jose Cabo", fr: "San Jose Cabo" },
  SJJ: { en: "Sarajevo", nl: "Sarajevo", fr: "Sarajevo" },
  SJO: { en: "San Jose", nl: "San Jose", fr: "San José" },
  SJP: { en: "Sao Jose Do Rio Preto", nl: "Sao Jose Do Rio Preto", fr: "Sao Jose Do Rio Preto" },
  SJU: { en: "San Juan", nl: "San Juan", fr: "San Juan" },
  SKB: { en: "Saint Kitts", nl: "Saint Kitts", fr: "Saint-Christophe-et-Niévès" },
  SKE: { en: "Skien", nl: "Skien", fr: "Skien" },
  SKG: { en: "Thessaloniki", nl: "Thessaloniki", fr: "Thessalonique" },
  SKP: { en: "Skopje", nl: "Skopje", fr: "Skopje" },
  SLC: { en: "Salt Lake City", nl: "Salt Lake City", fr: "Salt Lake City" },
  SLP: { en: "San Luis Potosi", nl: "San Luis Potosi", fr: "San Luis Potosi" },
  SLU: { en: "Saint Lucia", nl: "Saint Lucia", fr: "Saint Lucia" },
  SLZ: { en: "Sao Luiz", nl: "Sao Luiz", fr: "Sao Luiz" },
  SMI: { en: "Samos", nl: "Samos", fr: "Samos" },
  SMR: { en: "Santa Marta", nl: "Santa Marta", fr: "Santa Marta" },
  SNA: { en: "Orange County", nl: "Orange County", fr: "Comté d'Orange" },
  SNN: { en: "Shannon", nl: "Shannon", fr: "Shannon" },
  SOC: { en: "Solo", nl: "Solo", fr: "Surakarta" },
  SOF: { en: "Sofia", nl: "Sofia", fr: "Sofia" },
  SOU: { en: "Southampton", nl: "Southampton", fr: "Southampton" },
  SPC: { en: "Santa Cruz De La Palma", nl: "Santa Cruz De La Palma", fr: "Santa Cruz De La Palma" },
  SPK: { en: "Sapporo", nl: "Sapporo", fr: "Sapporo" },
  SPN: { en: "Saipan", nl: "Saipan", fr: "Saipan" },
  SPU: { en: "Split", nl: "Split", fr: "Split" },
  SRG: { en: "Semarang", nl: "Semarang", fr: "Semarang" },
  SRQ: { en: "Sarasota", nl: "Sarasota", fr: "Sarasota" },
  SRZ: { en: "Santa Cruz", nl: "Santa Cruz", fr: "Santa Cruz" },
  SSA: { en: "Salvador", nl: "Salvador", fr: "Salvador" },
  SSG: { en: "Malabo", nl: "Malabo", fr: "Malabo" },
  SSI: { en: "Brunswick", nl: "Brunswick", fr: "Brunswick" },
  SSJ: { en: "Sandnessjoen", nl: "Sandnessjoen", fr: "Sandnessjoen" },
  SSM: { en: "Sault Ste Marie", nl: "Sault Sainte Marie", fr: "Sault-Sainte-Marie" },
  STL: { en: "Saint Louis", nl: "Saint Louis", fr: "Saint-Louis" },
  STM: { en: "Santarem", nl: "Santarem", fr: "Santarem" },
  STO: { en: "Stockholm", nl: "Stockholm", fr: "Stockholm" },
  STR: { en: "Stuttgart", nl: "Stuttgart", fr: "Stuttgart" },
  STT: { en: "Saint Thomas Island", nl: "Saint Thomas Island", fr: "Saint Thomas Island" },
  STX: { en: "Saint Croix Island", nl: "Saint Croix Island", fr: "Saint Croix Island" },
  SUB: { en: "Surabaya", nl: "Surabaya", fr: "Surabaya" },
  SUF: { en: "Lamezia-Terme", nl: "Lamezia-Terme", fr: "Lamezia Terme" },
  SUX: { en: "Sioux City", nl: "Sioux City", fr: "Sioux City" },
  SVG: { en: "Stavanger", nl: "Stavanger", fr: "Stavanger" },
  SVQ: { en: "Sevilla", nl: "Sevilla", fr: "Séville" },
  SVX: { en: "Yekaterinburg", nl: "Yekaterinburg", fr: "Yekaterinburg" },
  SWA: { en: "Shantou", nl: "Shantou", fr: "Shantou" },
  SXB: { en: "Strasbourg", nl: "Straatsburg", fr: "Strasbourg" },
  SXM: { en: "Saint Martin", nl: "Sint Maarten", fr: "Saint Martin" },
  SYD: { en: "Sydney", nl: "Sydney", fr: "Sydney" },
  SYO: { en: "Shonai", nl: "Shonai", fr: "Shonai" },
  SYR: { en: "Syracuse", nl: "Syracuse", fr: "Syracuse" },
  SYX: { en: "Sanya", nl: "Sanya", fr: "Sanya" },
  SZF: { en: "Samsun", nl: "Samsun", fr: "Samsun" },
  SZG: { en: "Salzburg", nl: "Salzburg", fr: "Salzburg" },
  SZX: { en: "Shenzhen", nl: "Shenzhen", fr: "Shenzhen" },
  TAE: { en: "Daegu", nl: "Daegu", fr: "Daegu" },
  TAK: { en: "Takamatsu", nl: "Takamatsu", fr: "Takamatsu" },
  TAM: { en: "Tampico", nl: "Tampico", fr: "Tampico" },
  TAO: { en: "Qingdao", nl: "Qingdao", fr: "Qingdao" },
  TAP: { en: "Tapachula", nl: "Tapachula", fr: "Tapachula" },
  TAS: { en: "Tashkent", nl: "Tashkent", fr: "Tashkent" },
  TBO: { en: "Tabora", nl: "Tabora", fr: "Tabora" },
  TBS: { en: "Tbilisi", nl: "Tbilisi", fr: "Tbilisi" },
  TCI: { en: "Tenerife", nl: "Tenerife", fr: "Ténériffe" },
  TDX: { en: "Trat", nl: "Trat", fr: "Trat" },
  TER: { en: "Terceira Island", nl: "Terceira Island", fr: "Terceira Island" },
  TGD: { en: "Podgorica", nl: "Podgorica", fr: "Podgorica" },
  TGG: { en: "Kuala Terengganu", nl: "Kuala Terengganu", fr: "Kuala Terengganu" },
  TGU: { en: "Tegucigalpa", nl: "Tegucigalpa", fr: "Tegucigalpa" },
  TGZ: { en: "Tuxtla Gutierrez", nl: "Tuxtla Gutierrez", fr: "Tuxtla Gutierrez" },
  THE: { en: "Teresina", nl: "Teresina", fr: "Teresina" },
  THR: { en: "Tehran", nl: "Teheran", fr: "Téhéran" },
  THS: { en: "Sukhothai", nl: "Sukhothai", fr: "Sukhothaï" },
  TIA: { en: "Tirana", nl: "Tirana", fr: "Tirana" },
  TIJ: { en: "Tijuana", nl: "Tijuana", fr: "Tijuana" },
  TJM: { en: "Tyumen", nl: "Tyumen", fr: "Tyumen" },
  TKS: { en: "Tokushima", nl: "Tokushima", fr: "Tokushima" },
  TKU: { en: "Turku", nl: "Turku", fr: "Turku" },
  TLH: { en: "Tallahassee", nl: "Tallahassee", fr: "Tallahassee" },
  TLL: { en: "Tallinn", nl: "Tallinn", fr: "Tallinn" },
  TLN: { en: "Toulon", nl: "Toulon", fr: "Toulon" },
  TLS: { en: "Toulouse", nl: "Toulouse", fr: "Toulouse" },
  TLV: { en: "Tel Aviv", nl: "Tel Aviv", fr: "Tel Aviv-Jaffa" },
  TMP: { en: "Tampere", nl: "Tampere", fr: "Tampere" },
  TNA: { en: "Jinan", nl: "Jinan", fr: "Jinan" },
  TNR: { en: "Antananarivo", nl: "Antananarivo", fr: "Antananarivo" },
  TOL: { en: "Toledo", nl: "Toledo", fr: "Tolède" },
  TOS: { en: "Tromso", nl: "Tromso", fr: "Tromso" },
  TOV: { en: "Tortola", nl: "Tortola", fr: "Tortola" },
  TOY: { en: "Toyama", nl: "Toyama", fr: "Toyama" },
  TPA: { en: "Tampa", nl: "Tampa", fr: "Tampa" },
  TPE: { en: "Taipei", nl: "Taipei", fr: "Taipei" },
  TPP: { en: "Tarapoto", nl: "Tarapoto", fr: "Tarapoto" },
  TRC: { en: "Torreon", nl: "Torreon", fr: "Torreón" },
  TRD: { en: "Trondheim", nl: "Trondheim", fr: "Trondheim" },
  TRG: { en: "Tauranga", nl: "Tauranga", fr: "Tauranga" },
  TRI: { en: "Tri-Cities", nl: "Tri-Cities", fr: "Tri-Cities" },
  TRN: { en: "Turin", nl: "Turijn", fr: "Turin" },
  TRS: { en: "Trieste", nl: "Triëst", fr: "Trieste" },
  TRU: { en: "Trujillo", nl: "Trujillo", fr: "Trujillo" },
  TRV: { en: "Thiruvananthapuram", nl: "Thiruvananthapuram", fr: "Thiruvananthapuram" },
  TSE: { en: "Astana", nl: "Astana", fr: "Astana/Nur-Sultan" },
  TSN: { en: "Tianjin", nl: "Tianjin", fr: "Tianjin" },
  TSR: { en: "Timisoara", nl: "Timisoara", fr: "Timisoara" },
  TUF: { en: "Tours", nl: "Tours", fr: "Tours" },
  TUL: { en: "Tulsa", nl: "Tulsa", fr: "Tulsa" },
  TUN: { en: "Tunis", nl: "Tunis", fr: "Tunis" },
  TUO: { en: "Taupo", nl: "Taupo", fr: "Taupo" },
  TUS: { en: "Tucson", nl: "Tucson", fr: "Tucson" },
  TVC: { en: "Traverse City", nl: "Traverse City", fr: "Traverse City" },
  TVF: { en: "Thief River Falls", nl: "Thief River Falls", fr: "Thief River Falls" },
  TWU: { en: "Tawau", nl: "Tawau", fr: "Tawau" },
  TXN: { en: "Huangshan", nl: "Huangshan", fr: "Huangshan" },
  TYN: { en: "Taiyuan", nl: "Taiyuan", fr: "Taiyuan" },
  TYO: { en: "Tokyo", nl: "Tokio", fr: "Tokyo" },
  TYS: { en: "Knoxville", nl: "Knoxville", fr: "Knoxville" },
  TZX: { en: "Trabzon", nl: "Trabzon", fr: "Trabzon" },
  UBJ: { en: "Ube", nl: "Ube", fr: "Ube" },
  UBS: { en: "Columbus, MS", nl: "Columbus, MS", fr: "Columbus, MS" },
  UDR: { en: "Udaipur", nl: "Udaipur", fr: "Udaipur" },
  UFA: { en: "Ufa", nl: "Ufa", fr: "Ufa" },
  UIO: { en: "Quito", nl: "Quito", fr: "Quito" },
  UIP: { en: "Quimper", nl: "Quimper", fr: "Quimper" },
  UPG: { en: "Ujung Pandang", nl: "Ujung Pandang", fr: "Makassar" },
  URA: { en: "Uralsk", nl: "Uralsk", fr: "Uralsk" },
  URC: { en: "Urumqi", nl: "Urumqi", fr: "Ürümqi" },
  USH: { en: "Ushuaia", nl: "Ushuaia", fr: "Ushuaïa" },
  USM: { en: "Koh Samui", nl: "Koh Samui", fr: "Koh Samui" },
  USN: { en: "Ulsan", nl: "Ulsan", fr: "Ulsan" },
  UTH: { en: "Udon Thani", nl: "Udon Thani", fr: "Udon Thani" },
  UUS: { en: "Yuzhno Sakhalinsk", nl: "Yuzhno Sakhalinsk", fr: "Yuzhno Sakhalinsk" },
  VAA: { en: "Vaasa", nl: "Vaasa", fr: "Vaasa" },
  VAF: { en: "Valence", nl: "Valence", fr: "Valence" },
  VAN: { en: "Van", nl: "Van", fr: "Van" },
  VAS: { en: "Sivas", nl: "Sivas", fr: "Sivas" },
  VBY: { en: "Visby", nl: "Visby", fr: "Visby" },
  VCE: { en: "Venice", nl: "Venetië", fr: "Venise" },
  VER: { en: "Veracruz", nl: "Veracruz", fr: "Veracruz" },
  VFA: { en: "Victoria Falls", nl: "Victoria Falls", fr: "Victoria Falls" },
  VGO: { en: "Vigo", nl: "Vigo", fr: "Vigo" },
  VHM: { en: "Vilhelmina", nl: "Vilhelmina", fr: "Vilhelmina" },
  VIE: { en: "Vienna", nl: "Wenen", fr: "Vienne" },
  VIX: { en: "Vitoria", nl: "Vitoria", fr: "Vitória" },
  VLC: { en: "Valencia", nl: "Valencia", fr: "Valence" },
  VLD: { en: "Valdosta", nl: "Valdosta", fr: "Valdosta" },
  VLN: { en: "Valencia", nl: "Valencia", fr: "Valencia" },
  VNO: { en: "Vilnius", nl: "Vilnius", fr: "Vilnius" },
  VNS: { en: "Varanasi", nl: "Varanasi", fr: "Varanasi" },
  VOG: { en: "Volgograd", nl: "Volgograd", fr: "Volgograd" },
  VOZ: { en: "Voroznezh", nl: "Voroznezh", fr: "Voroznezh" },
  VPS: { en: "Valparaiso/Ft Walton", nl: "Valparaiso/Fort Walton", fr: "Valparaíso/Fort Walton" },
  VRN: { en: "Verona", nl: "Verona", fr: "Vérone" },
  VSA: { en: "Villahermosa", nl: "Villahermosa", fr: "Villahermosa" },
  VTE: { en: "Vientiane", nl: "Vientiane", fr: "Vientiane" },
  VVO: { en: "Vladivostok", nl: "Vladivostok", fr: "Vladivostok" },
  WAS: { en: "Washington", nl: "Washington", fr: "Washington" },
  WAW: { en: "Warsaw", nl: "Warschau", fr: "Varsovie" },
  WDH: { en: "Windhoek", nl: "Windhoek", fr: "Windhoek" },
  WEH: { en: "Weihai", nl: "Weihai", fr: "Weihai" },
  WLG: { en: "Wellington", nl: "Wellington", fr: "Wellington" },
  WLS: { en: "Wallis Island", nl: "Wallis Island", fr: "Wallis Island" },
  WNZ: { en: "Wenzhou", nl: "Wenzhou", fr: "Wenzhou" },
  WRE: { en: "Whangarei", nl: "Whangarei", fr: "Whangarei" },
  WUH: { en: "Wuhan", nl: "Wuhan", fr: "Wuhan" },
  WUS: { en: "Wuyishan", nl: "Wuyishan", fr: "Wuyishan" },
  WUX: { en: "Wuxi", nl: "Wuxi", fr: "Wuxi" },
  WVB: { en: "Walvis Bay", nl: "Walvis Bay", fr: "Walvis Bay" },
  XMN: { en: "Xiamen", nl: "Xiamen", fr: "Xiamen" },
  XNN: { en: "Xining", nl: "Xining", fr: "Xining" },
  YAM: { en: "Sault Ste Marie", nl: "Sault Ste Marie", fr: "Sault Ste Marie" },
  YAO: { en: "Yaounde", nl: "Yaoundé", fr: "Yaoundé" },
  YBR: { en: "Brandon", nl: "Brandon", fr: "Brandon" },
  YCD: { en: "Nanaimo", nl: "Nanaimo", fr: "Nanaimo" },
  YDD: { en: "Yingkou", nl: "Yingkou", fr: "Yingkou" },
  YDF: { en: "Deer Lake", nl: "Deer Lake", fr: "Deer Lake" },
  YEA: { en: "Edmonton", nl: "Edmonton", fr: "Edmonton" },
  YFC: { en: "Fredericton", nl: "Fredericton", fr: "Fredericton" },
  YGJ: { en: "Yonago", nl: "Yonago", fr: "Yonago" },
  YGK: { en: "Kingston", nl: "Kingston", fr: "Kingston" },
  YHZ: { en: "Halifax", nl: "Halifax", fr: "Halifax" },
  YIH: { en: "Yichang", nl: "Yichang", fr: "Yichang" },
  YIW: { en: "Yiwu", nl: "Yiwu", fr: "Yiwu" },
  YKA: { en: "Kamloops", nl: "Kamloops", fr: "Kamloops" },
  YKM: { en: "Yakima", nl: "Yakima", fr: "Yakima" },
  YLW: { en: "Kelowna", nl: "Kelowna", fr: "Kelowna" },
  YMM: { en: "Fort Mc Murray", nl: "Fort Mc Murray", fr: "Fort Mc Murray" },
  YMQ: { en: "Montreal", nl: "Montreal", fr: "Montréal" },
  YNJ: { en: "Yanji", nl: "Yanji", fr: "Yanji" },
  YOW: { en: "Ottawa", nl: "Ottawa", fr: "Ottawa" },
  YPR: { en: "Prince Rupert", nl: "Prince Rupert", fr: "Prince Rupert" },
  YQB: { en: "Quebec", nl: "Quebec", fr: "Québec" },
  YQG: { en: "Windsor", nl: "Windsor", fr: "Windsor" },
  YQL: { en: "Lethbridge", nl: "Lethbridge", fr: "Lethbridge" },
  YQM: { en: "Moncton", nl: "Moncton", fr: "Moncton" },
  YQQ: { en: "Comox", nl: "Comox", fr: "Comox" },
  YQR: { en: "Regina", nl: "Regina", fr: "Regina" },
  YQT: { en: "Thunder Bay", nl: "Thunder Bay", fr: "Thunder Bay" },
  YQU: { en: "Grande Prairie", nl: "Grande Prairie", fr: "Grande Prairie" },
  YQY: { en: "Sydney (Canada)", nl: "Sydney (Canada)", fr: "Sydney (Canada)" },
  YSB: { en: "Sudbury", nl: "Sudbury", fr: "Sudbury" },
  YTO: { en: "Toronto", nl: "Toronto", fr: "Toronto" },
  YTS: { en: "Timmins", nl: "Timmins", fr: "Timmins" },
  YUY: { en: "Rouyn", nl: "Rouyn", fr: "Rouyn" },
  YVA: { en: "Moroni", nl: "Moroni", fr: "Moroni" },
  YVO: { en: "Val D'or", nl: "Val D'or", fr: "Val D'or" },
  YVR: { en: "Vancouver", nl: "Vancouver", fr: "Vancouver" },
  YWG: { en: "Winnipeg", nl: "Winnipeg", fr: "Winnipeg" },
  YXC: { en: "Cranbrook", nl: "Cranbrook", fr: "Cranbrook" },
  YXE: { en: "Saskatoon", nl: "Saskatoon", fr: "Saskatoon" },
  YXJ: { en: "Fort St John", nl: "Fort St John", fr: "Fort St John" },
  YXS: { en: "Prince George", nl: "Prince George", fr: "Prince George" },
  YXT: { en: "Terrace", nl: "Terrace", fr: "Terrace" },
  YXU: { en: "London", nl: "London", fr: "Londres" },
  YXX: { en: "Abbotsford", nl: "Abbotsford", fr: "Abbotsford" },
  YXY: { en: "Whitehorse", nl: "Whitehorse", fr: "Whitehorse" },
  YYB: { en: "North Bay", nl: "North Bay", fr: "North Bay" },
  YYC: { en: "Calgary", nl: "Calgary", fr: "Calgary" },
  YYD: { en: "Smithers", nl: "Smithers", fr: "Smithers" },
  YYF: { en: "Penticton", nl: "Penticton", fr: "Penticton" },
  YYG: { en: "Charlottetown", nl: "Charlottetown", fr: "Charlottetown" },
  YYJ: { en: "Victoria", nl: "Victoria", fr: "Victoria" },
  YYT: { en: "Saint Johns", nl: "Saint Johns", fr: "Saint Johns" },
  YZF: { en: "Yellowknife", nl: "Yellowknife", fr: "Yellowknife" },
  YZR: { en: "Sarnia", nl: "Sarnia", fr: "Sarnia" },
  YZV: { en: "Sept Iles", nl: "Sept Iles", fr: "Sept Iles" },
  ZAD: { en: "Zadar", nl: "Zadar", fr: "Zadar" },
  ZAG: { en: "Zagreb", nl: "Zagreb", fr: "Zagreb" },
  ZBF: { en: "Bathurst", nl: "Bathurst", fr: "Bathurst" },
  ZIH: { en: "Ixtapa Zihuatanej", nl: "Ixtapa Zihuatanej", fr: "Ixtapa Zihuatanej" },
  ZNZ: { en: "Zanzibar", nl: "Zanzibar", fr: "Zanzibar" },
  ZQN: { en: "Queenstown", nl: "Queenstown", fr: "Queenstown" },
  ZRH: { en: "Zurich", nl: "Zurich", fr: "Zurich" },
  ZUH: { en: "Zhuhai", nl: "Zhuhai", fr: "Zhuhai" },
  ZYM: { en: "Arnhem", nl: "Arnhem", fr: "Arnhem" },
};

export const DESTINATIONS_EN = [
  ...DESTINATIONS.sort((a, b) => a.en.localeCompare(b.en)),
];
export const DESTINATIONS_NL = [
  ...DESTINATIONS.sort((a, b) => a.nl.localeCompare(b.nl)),
];
export const DESTINATIONS_FR = [
  ...DESTINATIONS.sort((a, b) => a.fr.localeCompare(b.fr)),
];
