//prettier-ignore
export const COUNTRIES = [
  { country_code: "AL", en: "Albania", nl: "Albanië", fr: "Albania" },
  { country_code: "DZ", en: "Algeria", nl: "Algerije", fr: "Algeria" },
  { country_code: "AO", en: "Angola", nl: "Angola", fr: "Angola" },
  { country_code: "AR", en: "Argentina", nl: "Argentina", fr: "Argentina" },
  { country_code: "AM", en: "Armenia", nl: "Armenië", fr: "Armenia" },
  { country_code: "AW", en: "Aruba", nl: "Aruba", fr: "Aruba" },
  { country_code: "AU", en: "Australia", nl: "Australië", fr: "Australie" },
  { country_code: "AT", en: "Austria", nl: "Oostenrijk", fr: "Autriche" },
  { country_code: "AZ", en: "Azerbaijan", nl: "Azerbeidzjan", fr: "Azerbaïdjan" },
  { country_code: "BS", en: "Bahamas", nl: "Bahamas", fr: "Bahamas" },
  { country_code: "BH", en: "Bahrain", nl: "Bahrein", fr: "Bahreïn" },
  { country_code: "BD", en: "Bangladesh", nl: "Bangladesh", fr: "Bangladesh" },
  { country_code: "BY", en: "Belarus", nl: "Belarus", fr: "Biélorussie" },
  { country_code: "BE", en: "Belgium", nl: "België", fr: "Belgique" },
  { country_code: "BZ", en: "Belize", nl: "Belize", fr: "Bélize" },
  { country_code: "BJ", en: "Benin", nl: "Benin", fr: "Benin" },
  { country_code: "BM", en: "Bermuda", nl: "Bermuda", fr: "Bermudes" },
  { country_code: "BO", en: "Bolivia", nl: "Bolivia", fr: "Bolivie" },
  { country_code: "BQ", en: "Bonaire, St Eustatius and Saba", nl: "Bonaire, St Eustatius en Saba", fr: "Bonaire, St Eustatius and Saba" },
  { country_code: "BA", en: "Bosnia and Herzegovina", nl: "Bosnië-Herzegovina", fr: "Bosnie-Herzégovine" },
  { country_code: "BW", en: "Botswana", nl: "Botswana", fr: "Botswana" },
  { country_code: "BR", en: "Brazil", nl: "Brazilië", fr: "Brésil" },
  { country_code: "VG", en: "British Virgin Islands", nl: "Britse Maagdeneilanden", fr: "Vierges (îles), Grande-Bretagne" },
  { country_code: "BN", en: "Brunei", nl: "Brunei", fr: "Brunei Darussalam" },
  { country_code: "BG", en: "Bulgaria", nl: "Bulgarije", fr: "Bulgarie" },
  { country_code: "BF", en: "Burkina Faso", nl: "Burkina Faso", fr: "Burkina Faso" },
  { country_code: "BI", en: "Burundi", nl: "Burundi", fr: "Burundi" },
  { country_code: "KH", en: "Cambodia", nl: "Cambodja", fr: "Cambodge" },
  { country_code: "CM", en: "Cameroon", nl: "Kameroen", fr: "Cameroun" },
  { country_code: "CA", en: "Canada", nl: "Canada", fr: "Canada" },
  { country_code: "CF", en: "Central African Republic", nl: "Centraal-Afrikaanse Republiek", fr: "Central African Republic" },
  { country_code: "TD", en: "Chad", nl: "Tsjaad", fr: "Chad" },
  { country_code: "CL", en: "Chile", nl: "Chili", fr: "Chile" },
  { country_code: "CN", en: "China", nl: "China", fr: "Chine" },
  { country_code: "CO", en: "Colombia", nl: "Colombia", fr: "Colombia" },
  { country_code: "KM", en: "Comoros", nl: "Comoren", fr: "Comores" },
  { country_code: "CG", en: "Congo", nl: "Congo", fr: "Congo" },
  { country_code: "CD", en: "Congo Democratic Rep.", nl: "Congo Democratische Rep.", fr: "Congo Democratic Rep." },
  { country_code: "CR", en: "Costa Rica", nl: "Costa Rica", fr: "Costa Rica" },
  { country_code: "CI", en: "Ivory Coast", nl: "Ivoorkust", fr: "Côte d'Ivoire" },
  { country_code: "HR", en: "Croatia", nl: "Kroatië", fr: "Croatia" },
  { country_code: "CU", en: "Cuba", nl: "Cuba", fr: "Cuba" },
  { country_code: "CW", en: "Curacao", nl: "Curaçao", fr: "Curaçao" },
  { country_code: "CY", en: "Cyprus", nl: "Cyprus", fr: "Chypre" },
  { country_code: "CZ", en: "Czech", nl: "Republic Tsjechië", fr: "République tchèque" },
  { country_code: "DK", en: "Denmark", nl: "Denemarken", fr: "Danemark" },
  { country_code: "DJ", en: "Djibouti", nl: "Djibouti", fr: "Djibouti" },
  { country_code: "DM", en: "Dominica", nl: "Dominica", fr: "Dominique" },
  { country_code: "DO", en: "Dominican Republic", nl: "Dominicaanse Republiek", fr: "République dominicaine" },
  { country_code: "EC", en: "Ecuador", nl: "Ecuador", fr: "Equateur" },
  { country_code: "EG", en: "Egypt", nl: "Egypte", fr: "Egypte" },
  { country_code: "SV", en: "El Salvador", nl: "El Salvador", fr: "Salvador" },
  { country_code: "GQ", en: "Equatorial Guinea", nl: "Equatoriaal-Guinea", fr: "Guinée équatoriale" },
  { country_code: "EE", en: "Estonia", nl: "Estland", fr: "Estonia" },
  { country_code: "ET", en: "Ethiopia", nl: "Ethiopië", fr: "Ethiopie" },
  { country_code: "FI", en: "Finland", nl: "Finland", fr: "Finlande" },
  { country_code: "FR", en: "France", nl: "Frankrijk", fr: "France" },
  { country_code: "GF", en: "French Guiana", nl: "Frans Guyana", fr: "Guyane" },
  { country_code: "PF", en: "French Polynesia", nl: "Frans Polynesië", fr: "French Polynesia" },
  { country_code: "GA", en: "Gabon", nl: "Gabon", fr: "Gabon" },
  { country_code: "GE", en: "Georgia", nl: "Georgia", fr: "Georgia" },
  { country_code: "DE", en: "Germany", nl: "Duitsland", fr: "Allemagne" },
  { country_code: "GH", en: "Ghana", nl: "Ghana", fr: "Ghana" },
  { country_code: "GR", en: "Greece", nl: "Griekenland", fr: "Grèce" },
  { country_code: "GP", en: "Guadeloupe", nl: "Guadeloupe", fr: "Guadeloupe" },
  { country_code: "GU", en: "Guam", nl: "Guam", fr: "Guam" },
  { country_code: "GT", en: "Guatemala", nl: "Guatemala", fr: "Guatemala" },
  { country_code: "GN", en: "Guinea", nl: "Guinea", fr: "Guinea" },
  { country_code: "HT", en: "Haiti", nl: "Haiti", fr: "Haiti" },
  { country_code: "HN", en: "Honduras", nl: "Honduras", fr: "Honduras" },
  { country_code: "HK", en: "Hong Kong", nl: "Hong Kong", fr: "Hong Kong" },
  { country_code: "HU", en: "Hungary", nl: "Hongarije", fr: "Hongrie" },
  { country_code: "IS", en: "Iceland", nl: "Ijsland", fr: "Iceland" },
  { country_code: "IN", en: "India", nl: "India", fr: "Inde" },
  { country_code: "ID", en: "Indonesia", nl: "Indonesië", fr: "Indonésie" },
  { country_code: "IR", en: "Iran", nl: "Iran", fr: "Iran" },
  { country_code: "IE", en: "Ireland", nl: "Ierland", fr: "Irlande" },
  { country_code: "IL", en: "Israel", nl: "Israel", fr: "Israël" },
  { country_code: "IT", en: "Italy", nl: "Italië", fr: "Italie" },
  { country_code: "JM", en: "Jamaica", nl: "Jamaica", fr: "Jamaica" },
  { country_code: "JP", en: "Japan", nl: "Japan", fr: "Japon" },
  { country_code: "JO", en: "Jordan", nl: "Jordanië", fr: "Jordanie" },
  { country_code: "KZ", en: "Kazakhstan", nl: "Kazachstan", fr: "Kazakhstan" },
  { country_code: "KE", en: "Kenya", nl: "Kenia", fr: "Kenya" },
  { country_code: "KR", en: "Korea, Republic Of", nl: "Korea", fr: "Corée, République de" },
  { country_code: "KW", en: "Kuwait", nl: "Koeweit", fr: "Koweït" },
  { country_code: "KG", en: "Kyrgyzstan", nl: "Kirgizië", fr: "Kirghizistan" },
  { country_code: "LA", en: "Lao, People's Democratic Republic", nl: "Democratische Volksrepubliek Laos", fr: "République populaire démocratique du Laos" },
  { country_code: "LV", en: "Latvia", nl: "Letland", fr: "Latvia" },
  { country_code: "LB", en: "Lebanon", nl: "Libanon", fr: "Liban" },
  { country_code: "LR", en: "Liberia", nl: "Liberia", fr: "Liberia" },
  { country_code: "LT", en: "Lithuania", nl: "Litouwen", fr: "Lituanie" },
  { country_code: "LU", en: "Luxembourg", nl: "Luxemburg", fr: "Luxembourg" },
  { country_code: "MG", en: "Madagascar", nl: "Madagascar", fr: "Madagascar" },
  { country_code: "MW", en: "Malawi", nl: "Malawi", fr: "Malawi" },
  { country_code: "MY", en: "Malaysia", nl: "Maleisië", fr: "Malaisie" },
  { country_code: "MV", en: "Maldives", nl: "Maldives", fr: "Maldives" },
  { country_code: "ML", en: "Mali", nl: "Mali", fr: "Mali" },
  { country_code: "MT", en: "Malta", nl: "Malta", fr: "Malta" },
  { country_code: "MQ", en: "Martinique", nl: "Martinique", fr: "Martinique" },
  { country_code: "MR", en: "Mauritania", nl: "Mauritanië", fr: "Mauritania" },
  { country_code: "MU", en: "Mauritius", nl: "Mauritius", fr: "Mauritius" },
  { country_code: "YT", en: "Mayotte", nl: "Mayotte", fr: "Mayotte" },
  { country_code: "MX", en: "Mexico", nl: "Mexico", fr: "Mexique" },
  { country_code: "ME", en: "Montenegro", nl: "Montenegro", fr: "Montenegro" },
  { country_code: "MA", en: "Morocco", nl: "Marokko", fr: "Maroc" },
  { country_code: "MZ", en: "Mozambique", nl: "Mozambique", fr: "Mozambique" },
  { country_code: "MM", en: "Myanmar", nl: "Myanmar", fr: "Myanmar (Birmanie)" },
  { country_code: "NP", en: "Nepal", nl: "Nepal", fr: "Nepal" },
  { country_code: "NL", en: "Netherlands", nl: "Nederland", fr: "Pays-Bas" },
  { country_code: "NC", en: "New Caledonia", nl: "Nieuw Caledonië", fr: "Nouvelle Calédonie" },
  { country_code: "NZ", en: "New Zealand", nl: "Nieuw Zeeland", fr: "Nouvelle-Zélande" },
  { country_code: "NI", en: "Nicaragua", nl: "Nicaragua", fr: "Nicaragua" },
  { country_code: "NE", en: "Niger", nl: "Niger", fr: "Niger" },
  { country_code: "NG", en: "Nigeria", nl: "Nigeria", fr: "Nigeria" },
  { country_code: "MP", en: "Northern Mariana Islands", nl: "Northern Mariana Islands", fr: "Northern Mariana Islands" },
  { country_code: "NO", en: "Norway", nl: "Noorwegen", fr: "Norvège" },
  { country_code: "OM", en: "Oman", nl: "Oman", fr: "Oman" },
  { country_code: "PK", en: "Pakistan", nl: "Pakistan", fr: "Pakistan" },
  { country_code: "PW", en: "Palau Islands", nl: "Palau Islands", fr: "Palau Islands" },
  { country_code: "PA", en: "Panama", nl: "Panama", fr: "Panamá" },
  { country_code: "PG", en: "Papua New Guinea", nl: "Papua Nieuw Guinea", fr: "Papouasie-Nouvelle-Guinée" },
  { country_code: "PY", en: "Paraguay", nl: "Paraguay", fr: "Paraguay" },
  { country_code: "PE", en: "Peru", nl: "Peru", fr: "Pérou" },
  { country_code: "PH", en: "Philippines", nl: "Filippijnen", fr: "Philippines" },
  { country_code: "PL", en: "Poland", nl: "Polen", fr: "Pologne" },
  { country_code: "PT", en: "Portugal", nl: "Portugal", fr: "Portugal" },
  { country_code: "PR", en: "Puerto Rico", nl: "Puerto Rico", fr: "Porto Rico" },
  { country_code: "QA", en: "Qatar", nl: "Qatar", fr: "Qatar" },
  { country_code: "MK", en: "Republic of Macedonia", nl: "Macedonië", fr: "Republic of Macedonia" },
  { country_code: "RE", en: "Reunion", nl: "Reunion", fr: "Reunion" },
  { country_code: "RO", en: "Romania", nl: "Roemenië", fr: "Roumanie" },
  { country_code: "RU", en: "Russia", nl: "Rusland", fr: "Russie" },
  { country_code: "RW", en: "Rwanda", nl: "Rwanda", fr: "Rwanda" },
  { country_code: "KN", en: "Saint Kitts and Nevis", nl: "Federatie van St. Kitts en Nevis", fr: "Saint-Kitts-et-Nevis" },
  { country_code: "LC", en: "Saint lucia", nl: "Saint Lucia", fr: "Sainte-Lucie" },
  { country_code: "SX", en: "Saint Martin", nl: "Sint Maarten", fr: "Saint-Martin" },
  { country_code: "SA", en: "Saudi Arabia", nl: "Saoedi-Arabië", fr: "Arabie Saoudite" },
  { country_code: "SN", en: "Senegal", nl: "Senegal", fr: "Senegal" },
  { country_code: "RS", en: "Serbia", nl: "Servië", fr: "Serbia" },
  { country_code: "SC", en: "Seychelles", nl: "Seychellen", fr: "Seychelles" },
  { country_code: "SL", en: "Sierra Leone", nl: "Sierra Leone", fr: "Sierra Leone" },
  { country_code: "SG", en: "Singapore", nl: "Singapore", fr: "Singapour" },
  { country_code: "SK", en: "Slovakia", nl: "Slowakije", fr: "Slovakia" },
  { country_code: "SI", en: "Slovenia", nl: "Slovenië", fr: "Slovenia" },
  { country_code: "ZA", en: "South Africa", nl: "Zuid-Afrika", fr: "Afrique du Sud" },
  { country_code: "SS", en: "South Sudan", nl: "South Sudan", fr: "Soudan du Sud" },
  { country_code: "ES", en: "Spain", nl: "Spanje", fr: "Espagne" },
  { country_code: "LK", en: "Sri Lanka", nl: "Sri Lanka", fr: "Sri Lanka" },
  { country_code: "SD", en: "Sudan", nl: "Soedan", fr: "Soudan" },
  { country_code: "SR", en: "Suriname", nl: "Suriname", fr: "Surinam" },
  { country_code: "SE", en: "Sweden", nl: "Zweden", fr: "Suède" },
  { country_code: "CH", en: "Switzerland", nl: "Zwitserland", fr: "Suisse" },
  { country_code: "TW", en: "Taiwan", nl: "Taiwan", fr: "Taiwan, China" },
  { country_code: "TZ", en: "Tanzania", nl: "Tanzania", fr: "Tanzanie" },
  { country_code: "TH", en: "Thailand", nl: "Thailand", fr: "Thaïlande" },
  { country_code: "TG", en: "Togo", nl: "Togo", fr: "Togo" },
  { country_code: "TT", en: "Trinidad and Tobago", nl: "Trinidad and Tobago", fr: "Trinité-et-Tobago" },
  { country_code: "TN", en: "Tunisia", nl: "Tunesië", fr: "Tunisie" },
  { country_code: "TR", en: "Turkey", nl: "Turkije", fr: "Turquie" },
  { country_code: "UG", en: "Uganda", nl: "Oeganda", fr: "Ouganda" },
  { country_code: "UA", en: "Ukraine", nl: "Oekraïne", fr: "Ukraine" },
  { country_code: "AE", en: "United Arab Emirates", nl: "Verenigde Arabische Emiraten", fr: "Émirats arabes unis" },
  { country_code: "GB", en: "United Kingdom", nl: "Verenigd Koninkrijk", fr: "Royaume Uni" },
  { country_code: "UY", en: "Uruguay", nl: "Uruguay", fr: "Uruguay" },
  { country_code: "VI", en: "US Virgin Islands", nl: "Amerikaanse Maagdeneilanden", fr: "Îles Vierges des États-Unis" },
  { country_code: "US", en: "USA", nl: "Verenigde Staten", fr: "USA" },
  { country_code: "UZ", en: "Uzbekistan", nl: "Oezbekistan", fr: "Ouzbékistan" },
  { country_code: "VE", en: "Venezuela", nl: "Venezuela", fr: "Vénézuela" },
  { country_code: "VN", en: "Vietnam", nl: "Vietnam", fr: "Vietnam" },
  { country_code: "WF", en: "Wallis and Futuna", nl: "Wallis en Futuna", fr: "Wallis-et-Futuna" },
  { country_code: "YE", en: "Yemen", nl: "Jemen", fr: "Yémen" },
  { country_code: "ZM", en: "Zambia", nl: "Zambia", fr: "Zambie" },
  { country_code: "ZW", en: "Zimbabwe", nl: "Zimbabwe", fr: "Zimbabwe" },
  { country_code: "NA", en: "Namibia", nl: "Namibië", fr: "Namibia" },
];

//prettier-ignore
export const COUNTRIES_OBJECT = {
  "AL": {en: "Albania", nl: "Albanië", fr: "Albania" },
  "DZ": {en: "Algeria", nl: "Algerije", fr: "Algeria" },
  "AO": {en: "Angola", nl: "Angola", fr: "Angola" },
  "AR": {en: "Argentina", nl: "Argentina", fr: "Argentina" },
  "AM": {en: "Armenia", nl: "Armenië", fr: "Armenia" },
  "AW": {en: "Aruba", nl: "Aruba", fr: "Aruba" },
  "AU": {en: "Australia", nl: "Australië", fr: "Australie" },
  "AT": {en: "Austria", nl: "Oostenrijk", fr: "Autriche" },
  "AZ": {en: "Azerbaijan", nl: "Azerbeidzjan", fr: "Azerbaïdjan" },
  "BS": {en: "Bahamas", nl: "Bahamas", fr: "Bahamas" },
  "BH": {en: "Bahrain", nl: "Bahrein", fr: "Bahreïn" },
  "BD": {en: "Bangladesh", nl: "Bangladesh", fr: "Bangladesh" },
  "BY": {en: "Belarus", nl: "Belarus", fr: "Biélorussie" },
  "BE": {en: "Belgium", nl: "België", fr: "Belgique" },
  "BZ": {en: "Belize", nl: "Belize", fr: "Bélize" },
  "BJ": {en: "Benin", nl: "Benin", fr: "Benin" },
  "BM": {en: "Bermuda", nl: "Bermuda", fr: "Bermudes" },
  "BO": {en: "Bolivia", nl: "Bolivia", fr: "Bolivie" },
  "BQ": {en: "Bonaire, St Eustatius and Saba", nl: "Bonaire, St Eustatius en Saba", fr: "Bonaire, St Eustatius and Saba" },
  "BA": {en: "Bosnia and Herzegovina", nl: "Bosnië-Herzegovina", fr: "Bosnie-Herzégovine" },
  "BW": {en: "Botswana", nl: "Botswana", fr: "Botswana" },
  "BR": {en: "Brazil", nl: "Brazilië", fr: "Brésil" },
  "VG": {en: "British Virgin Islands", nl: "Britse Maagdeneilanden", fr: "Vierges (îles), Grande-Bretagne" },
  "BN": {en: "Brunei", nl: "Brunei", fr: "Brunei Darussalam" },
  "BG": {en: "Bulgaria", nl: "Bulgarije", fr: "Bulgarie" },
  "BF": {en: "Burkina Faso", nl: "Burkina Faso", fr: "Burkina Faso" },
  "BI": {en: "Burundi", nl: "Burundi", fr: "Burundi" },
  "KH": {en: "Cambodia", nl: "Cambodja", fr: "Cambodge" },
  "CM": {en: "Cameroon", nl: "Kameroen", fr: "Cameroun" },
  "CA": {en: "Canada", nl: "Canada", fr: "Canada" },
  "CF": {en: "Central African Republic", nl: "Centraal-Afrikaanse Republiek", fr: "Central African Republic" },
  "TD": {en: "Chad", nl: "Tsjaad", fr: "Chad" },
  "CL": {en: "Chile", nl: "Chili", fr: "Chile" },
  "CN": {en: "China", nl: "China", fr: "Chine" },
  "CO": {en: "Colombia", nl: "Colombia", fr: "Colombia" },
  "KM": {en: "Comoros", nl: "Comoren", fr: "Comores" },
  "CG": {en: "Congo", nl: "Congo", fr: "Congo" },
  "CD": {en: "Congo Democratic Rep.", nl: "Congo Democratische Rep.", fr: "Congo Democratic Rep." },
  "CR": {en: "Costa Rica", nl: "Costa Rica", fr: "Costa Rica" },
  "CI": {en: "Ivory Coast", nl: "Ivoorkust", fr: "Côte d'Ivoire" },
  "HR": {en: "Croatia", nl: "Kroatië", fr: "Croatia" },
  "CU": {en: "Cuba", nl: "Cuba", fr: "Cuba" },
  "CW": {en: "Curacao", nl: "Curaçao", fr: "Curaçao" },
  "CY": {en: "Cyprus", nl: "Cyprus", fr: "Chypre" },
  "CZ": {en: "Czech", nl: "Republic Tsjechië", fr: "République tchèque" },
  "DK": {en: "Denmark", nl: "Denemarken", fr: "Danemark" },
  "DJ": {en: "Djibouti", nl: "Djibouti", fr: "Djibouti" },
  "DM": {en: "Dominica", nl: "Dominica", fr: "Dominique" },
  "DO": {en: "Dominican Republic", nl: "Dominicaanse Republiek", fr: "République dominicaine" },
  "EC": {en: "Ecuador", nl: "Ecuador", fr: "Equateur" },
  "EG": {en: "Egypt", nl: "Egypte", fr: "Egypte" },
  "SV": {en: "El Salvador", nl: "El Salvador", fr: "Salvador" },
  "GQ": {en: "Equatorial Guinea", nl: "Equatoriaal-Guinea", fr: "Guinée équatoriale" },
  "EE": {en: "Estonia", nl: "Estland", fr: "Estonia" },
  "ET": {en: "Ethiopia", nl: "Ethiopië", fr: "Ethiopie" },
  "FI": {en: "Finland", nl: "Finland", fr: "Finlande" },
  "FR": {en: "France", nl: "Frankrijk", fr: "France" },
  "GF": {en: "French Guiana", nl: "Frans Guyana", fr: "Guyane" },
  "PF": {en: "French Polynesia", nl: "Frans Polynesië", fr: "French Polynesia" },
  "GA": {en: "Gabon", nl: "Gabon", fr: "Gabon" },
  "GE": {en: "Georgia", nl: "Georgia", fr: "Georgia" },
  "DE": {en: "Germany", nl: "Duitsland", fr: "Allemagne" },
  "GH": {en: "Ghana", nl: "Ghana", fr: "Ghana" },
  "GR": {en: "Greece", nl: "Griekenland", fr: "Grèce" },
  "GP": {en: "Guadeloupe", nl: "Guadeloupe", fr: "Guadeloupe" },
  "GU": {en: "Guam", nl: "Guam", fr: "Guam" },
  "GT": {en: "Guatemala", nl: "Guatemala", fr: "Guatemala" },
  "GN": {en: "Guinea", nl: "Guinea", fr: "Guinea" },
  "HT": {en: "Haiti", nl: "Haiti", fr: "Haiti" },
  "HN": {en: "Honduras", nl: "Honduras", fr: "Honduras" },
  "HK": {en: "Hong Kong", nl: "Hong Kong", fr: "Hong Kong" },
  "HU": {en: "Hungary", nl: "Hongarije", fr: "Hongrie" },
  "IS": {en: "Iceland", nl: "Ijsland", fr: "Iceland" },
  "IN": {en: "India", nl: "India", fr: "Inde" },
  "ID": {en: "Indonesia", nl: "Indonesië", fr: "Indonésie" },
  "IR": {en: "Iran", nl: "Iran", fr: "Iran" },
  "IE": {en: "Ireland", nl: "Ierland", fr: "Irlande" },
  "IL": {en: "Israel", nl: "Israel", fr: "Israël" },
  "IT": {en: "Italy", nl: "Italië", fr: "Italie" },
  "JM": {en: "Jamaica", nl: "Jamaica", fr: "Jamaica" },
  "JP": {en: "Japan", nl: "Japan", fr: "Japon" },
  "JO": {en: "Jordan", nl: "Jordanië", fr: "Jordanie" },
  "KZ": {en: "Kazakhstan", nl: "Kazachstan", fr: "Kazakhstan" },
  "KE": {en: "Kenya", nl: "Kenia", fr: "Kenya" },
  "KR": {en: "Korea, Republic Of", nl: "Korea", fr: "Corée, République de" },
  "KW": {en: "Kuwait", nl: "Koeweit", fr: "Koweït" },
  "KG": {en: "Kyrgyzstan", nl: "Kirgizië", fr: "Kirghizistan" },
  "LA": {en: "Lao, People's Democratic Republic", nl: "Democratische Volksrepubliek Laos", fr: "République populaire démocratique du Laos" },
  "LV": {en: "Latvia", nl: "Letland", fr: "Latvia" },
  "LB": {en: "Lebanon", nl: "Libanon", fr: "Liban" },
  "LR": {en: "Liberia", nl: "Liberia", fr: "Liberia" },
  "LT": {en: "Lithuania", nl: "Litouwen", fr: "Lituanie" },
  "LU": {en: "Luxembourg", nl: "Luxemburg", fr: "Luxembourg" },
  "MG": {en: "Madagascar", nl: "Madagascar", fr: "Madagascar" },
  "MW": {en: "Malawi", nl: "Malawi", fr: "Malawi" },
  "MY": {en: "Malaysia", nl: "Maleisië", fr: "Malaisie" },
  "MV": {en: "Maldives", nl: "Maldives", fr: "Maldives" },
  "ML": {en: "Mali", nl: "Mali", fr: "Mali" },
  "MT": {en: "Malta", nl: "Malta", fr: "Malta" },
  "MQ": {en: "Martinique", nl: "Martinique", fr: "Martinique" },
  "MR": {en: "Mauritania", nl: "Mauritanië", fr: "Mauritania" },
  "MU": {en: "Mauritius", nl: "Mauritius", fr: "Mauritius" },
  "YT": {en: "Mayotte", nl: "Mayotte", fr: "Mayotte" },
  "MX": {en: "Mexico", nl: "Mexico", fr: "Mexique" },
  "ME": {en: "Montenegro", nl: "Montenegro", fr: "Montenegro" },
  "MA": {en: "Morocco", nl: "Marokko", fr: "Maroc" },
  "MZ": {en: "Mozambique", nl: "Mozambique", fr: "Mozambique" },
  "MM": {en: "Myanmar", nl: "Myanmar", fr: "Myanmar (Birmanie)" },
  "NP": {en: "Nepal", nl: "Nepal", fr: "Nepal" },
  "NL": {en: "Netherlands", nl: "Nederland", fr: "Pays-Bas" },
  "NC": {en: "New Caledonia", nl: "Nieuw Caledonië", fr: "Nouvelle Calédonie" },
  "NZ": {en: "New Zealand", nl: "Nieuw Zeeland", fr: "Nouvelle-Zélande" },
  "NI": {en: "Nicaragua", nl: "Nicaragua", fr: "Nicaragua" },
  "NE": {en: "Niger", nl: "Niger", fr: "Niger" },
  "NG": {en: "Nigeria", nl: "Nigeria", fr: "Nigeria" },
  "MP": {en: "Northern Mariana Islands", nl: "Northern Mariana Islands", fr: "Northern Mariana Islands" },
  "NO": {en: "Norway", nl: "Noorwegen", fr: "Norvège" },
  "OM": {en: "Oman", nl: "Oman", fr: "Oman" },
  "PK": {en: "Pakistan", nl: "Pakistan", fr: "Pakistan" },
  "PW": {en: "Palau Islands", nl: "Palau Islands", fr: "Palau Islands" },
  "PA": {en: "Panama", nl: "Panama", fr: "Panamá" },
  "PG": {en: "Papua New Guinea", nl: "Papua Nieuw Guinea", fr: "Papouasie-Nouvelle-Guinée" },
  "PY": {en: "Paraguay", nl: "Paraguay", fr: "Paraguay" },
  "PE": {en: "Peru", nl: "Peru", fr: "Pérou" },
  "PH": {en: "Philippines", nl: "Filippijnen", fr: "Philippines" },
  "PL": {en: "Poland", nl: "Polen", fr: "Pologne" },
  "PT": {en: "Portugal", nl: "Portugal", fr: "Portugal" },
  "PR": {en: "Puerto Rico", nl: "Puerto Rico", fr: "Porto Rico" },
  "QA": {en: "Qatar", nl: "Qatar", fr: "Qatar" },
  "MK": {en: "Republic of Macedonia", nl: "Macedonië", fr: "Republic of Macedonia" },
  "RE": {en: "Reunion", nl: "Reunion", fr: "Reunion" },
  "RO": {en: "Romania", nl: "Roemenië", fr: "Roumanie" },
  "RU": {en: "Russia", nl: "Rusland", fr: "Russie" },
  "RW": {en: "Rwanda", nl: "Rwanda", fr: "Rwanda" },
  "KN": {en: "Saint Kitts and Nevis", nl: "Federatie van St. Kitts en Nevis", fr: "Saint-Kitts-et-Nevis" },
  "LC": {en: "Saint lucia", nl: "Saint Lucia", fr: "Sainte-Lucie" },
  "SX": {en: "Saint Martin", nl: "Sint Maarten", fr: "Saint-Martin" },
  "SA": {en: "Saudi Arabia", nl: "Saoedi-Arabië", fr: "Arabie Saoudite" },
  "SN": {en: "Senegal", nl: "Senegal", fr: "Senegal" },
  "RS": {en: "Serbia", nl: "Servië", fr: "Serbia" },
  "SC": {en: "Seychelles", nl: "Seychellen", fr: "Seychelles" },
  "SL": {en: "Sierra Leone", nl: "Sierra Leone", fr: "Sierra Leone" },
  "SG": {en: "Singapore", nl: "Singapore", fr: "Singapour" },
  "SK": {en: "Slovakia", nl: "Slowakije", fr: "Slovakia" },
  "SI": {en: "Slovenia", nl: "Slovenië", fr: "Slovenia" },
  "ZA": {en: "South Africa", nl: "Zuid-Afrika", fr: "Afrique du Sud" },
  "SS": {en: "South Sudan", nl: "South Sudan", fr: "Soudan du Sud" },
  "ES": {en: "Spain", nl: "Spanje", fr: "Espagne" },
  "LK": {en: "Sri Lanka", nl: "Sri Lanka", fr: "Sri Lanka" },
  "SD": {en: "Sudan", nl: "Soedan", fr: "Soudan" },
  "SR": {en: "Suriname", nl: "Suriname", fr: "Surinam" },
  "SE": {en: "Sweden", nl: "Zweden", fr: "Suède" },
  "CH": {en: "Switzerland", nl: "Zwitserland", fr: "Suisse" },
  "TW": {en: "Taiwan", nl: "Taiwan", fr: "Taiwan, China" },
  "TZ": {en: "Tanzania", nl: "Tanzania", fr: "Tanzanie" },
  "TH": {en: "Thailand", nl: "Thailand", fr: "Thaïlande" },
  "TG": {en: "Togo", nl: "Togo", fr: "Togo" },
  "TT": {en: "Trinidad and Tobago", nl: "Trinidad and Tobago", fr: "Trinité-et-Tobago" },
  "TN": {en: "Tunisia", nl: "Tunesië", fr: "Tunisie" },
  "TR": {en: "Turkey", nl: "Turkije", fr: "Turquie" },
  "UG": {en: "Uganda", nl: "Oeganda", fr: "Ouganda" },
  "UA": {en: "Ukraine", nl: "Oekraïne", fr: "Ukraine" },
  "AE": {en: "United Arab Emirates", nl: "Verenigde Arabische Emiraten", fr: "Émirats arabes unis" },
  "GB": {en: "United Kingdom", nl: "Verenigd Koninkrijk", fr: "Royaume Uni" },
  "UY": {en: "Uruguay", nl: "Uruguay", fr: "Uruguay" },
  "VI": {en: "US Virgin Islands", nl: "Amerikaanse Maagdeneilanden", fr: "Îles Vierges des États-Unis" },
  "US": {en: "USA", nl: "Verenigde Staten", fr: "USA" },
  "UZ": {en: "Uzbekistan", nl: "Oezbekistan", fr: "Ouzbékistan" },
  "VE": {en: "Venezuela", nl: "Venezuela", fr: "Vénézuela" },
  "VN": {en: "Vietnam", nl: "Vietnam", fr: "Vietnam" },
  "WF": {en: "Wallis and Futuna", nl: "Wallis en Futuna", fr: "Wallis-et-Futuna" },
  "YE": {en: "Yemen", nl: "Jemen", fr: "Yémen" },
  "ZM": {en: "Zambia", nl: "Zambia", fr: "Zambie" },
  "ZW": {en: "Zimbabwe", nl: "Zimbabwe", fr: "Zimbabwe" },
  "NA": {en: "Namibia", nl: "Namibië", fr: "Namibia" },
}
